export const HOSPITAL_ADD_REQUEST = 'HOSPITAL_ADD_REQUEST';
export const HOSPITAL_ADD_SUCCESS = 'HOSPITAL_ADD_SUCCESS';
export const HOSPITAL_ADD_FAILURE = 'HOSPITAL_ADD_FAILURE';
export const HOSPITAL_ADD_RESET = 'HOSPITAL_ADD_RESET';

export const HOSPITAL_UPDATE_REQUEST = 'HOSPITAL_UPDATE_REQUEST';
export const HOSPITAL_UPDATE_SUCCESS = 'HOSPITAL_UPDATE_SUCCESS';
export const HOSPITAL_UPDATE_FAILURE = 'HOSPITAL_UPDATE_FAILURE';
export const HOSPITAL_UPDATE_RESET = 'HOSPITAL_UPDATE_RESET';

export const HOSPITAL_REMOVE_REQUEST = 'HOSPITAL_REMOVE_REQUEST';
export const HOSPITAL_REMOVE_SUCCESS = 'HOSPITAL_REMOVE_SUCCESS';
export const HOSPITAL_REMOVE_FAILURE = 'HOSPITAL_REMOVE_FAILURE';
export const HOSPITAL_REMOVE_RESET = 'HOSPITAL_REMOVE_RESET';

export const HOSPITAL_FETCH_HOSPITAL_DATA_REQUEST = 'HOSPITAL_FETCH_HOSPITAL_DATA_REQUEST';
export const HOSPITAL_FETCH_HOSPITAL_DATA_SUCCESS = 'HOSPITAL_FETCH_HOSPITAL_DATA_SUCCESS';
export const HOSPITAL_FETCH_HOSPITAL_DATA_FAILURE = 'HOSPITAL_FETCH_HOSPITAL_DATA_FAILURE';
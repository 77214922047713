import axios from 'axios';
import store from '../store';

import * as AuthService from './auth-service';
import { apiUrl } from '../config';
import { logout } from '../store/actions/user-actions';

export const createHttpClient = () => {
    const accessToken = AuthService.getToken();
    
    let headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Language': 'pl'
    };

    const httpClient = axios.create({
        baseURL: apiUrl,
        headers
    });

    httpClient.interceptors.response.use(response => {
        return response;
    }, (err) => {
        if (err.response.status === 401) {
            store.dispatch(logout());
        }

        return Promise.reject(err);
    });

    return httpClient;
}
import React from 'react';
import PropTypes from 'prop-types';

import Fieldset from '../../common/Fieldset';
import FormGroup from '../../common/FormGroup';
import Label from '../../common/Label';
import ControlErrors from '../../common/ControlErrors';
import HospitalAutoComplete from '../../hospital/HospitalAutoComplete';

const PatientFormHospitalFieldset = ({ values, handleChange, errors, disabled }) => {

    return (
        <Fieldset
            legend="Ośrodek"
            bodyClassName="c-patient-add-modal__hospital"
        >
            <FormGroup inline>
                <Label left className="c-patient-add-modal__label-col">Ośrodek</Label>
                <div className="c-patient-add-modal__input-col">
                    <HospitalAutoComplete
                        value={values.hospital}
                        onChange={handleChange}
                        name="hospital"
                        dropdown={true}
                        disabled={disabled}
                    />
                    <ControlErrors errors={errors['center_id']} />
                </div>
            </FormGroup>
        </Fieldset>
    )
}

PatientFormHospitalFieldset.propTypes = {
    values: PropTypes.shape({
        hospital: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string
        })
    }),
    handleChange: PropTypes.func,
    errors: PropTypes.object
}

export default PatientFormHospitalFieldset
import React from 'react';
import ReactDOM from 'react-dom';

// Import translations
import './i18n';

// Import fonts
import './fonts/fonts';

import './scss/index.scss';
import * as serviceWorker from './serviceWorker';
import Modal from 'react-modal';
import Root from './Root';

Modal.setAppElement('#root');

// eslint-disable-next-line
if (process.env.NODE_ENV !== 'development') {
	// eslint-disable-next-line 
	console.log = () => {};
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import PropTypes from 'prop-types';

const Suspense = ({ isLoading, fallback, children }) => {
    if (isLoading) {
        return fallback;
    }

    return children;
}

Suspense.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node), 
        PropTypes.node
    ]),
    isLoading: PropTypes.bool,
    fallback: PropTypes.node
}

export default Suspense;
import * as types from './types/education_subjects_types';

import * as EducationService from '../../services/education-service';

export const fetchSubjects = () => async dispatch => {
    dispatch({
        type: types.EDUCATION_SUBJECTS_FETCH_SUBJECTS_REQUEST
    });

    try {
        const response = await EducationService.getSubjects();

        dispatch({
            type: types.EDUCATION_SUBJECTS_FETCH_SUBJECTS_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.EDUCATION_SUBJECTS_FETCH_SUBJECTS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const addSubject = (values) => async dispatch => {
    dispatch({
        type: types.EDUCATION_SUBJECTS_ADD_REQUEST
    });

    try {
        const response = await EducationService.addSubject(values);

        dispatch({
            type: types.EDUCATION_SUBJECTS_ADD_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.EDUCATION_SUBJECTS_ADD_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const updateSubject = (values, subjectId) => async dispatch => {
    dispatch({
        type: types.EDUCATION_SUBJECTS_UPDATE_REQUEST
    });

    try {
        const response = await EducationService.updateSubject(values, subjectId);

        dispatch({
            type: types.EDUCATION_SUBJECTS_UPDATE_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.EDUCATION_SUBJECTS_UPDATE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const removeSubject = (subjectId) => async dispatch => {
    dispatch({
        type: types.EDUCATION_SUBJECTS_REMOVE_REQUEST
    });

    try {
        const response = await EducationService.removeSubject(subjectId);

        dispatch({
            type: types.EDUCATION_SUBJECTS_REMOVE_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.EDUCATION_SUBJECTS_REMOVE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}
import icon1 from '../images/event-icons/1.svg';
import icon2 from '../images/event-icons/2.svg';
import icon3 from '../images/event-icons/3.svg';
import icon4 from '../images/event-icons/4.svg';
import icon5 from '../images/event-icons/5.svg';
import icon6 from '../images/event-icons/21.svg';
import icon7 from '../images/event-icons/7.svg';
import icon8 from '../images/event-icons/8.svg';
import icon9 from '../images/event-icons/9.svg';
import icon10 from '../images/event-icons/10.svg';
import icon11 from '../images/event-icons/11.svg';
import icon12 from '../images/event-icons/12.svg';
import icon13 from '../images/event-icons/13.svg';
import icon14 from '../images/event-icons/14.svg';
import icon15 from '../images/event-icons/15.svg';
import icon16 from '../images/event-icons/16.svg';
import icon17 from '../images/event-icons/17.svg';
import icon18 from '../images/event-icons/18.svg';
import icon19 from '../images/event-icons/19.svg';
import icon20 from '../images/event-icons/20.svg';


export default [
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    icon7,
    icon8,
    icon9,
    icon10,
    icon11,
    icon12,
    icon13,
    icon14,
    icon15,
    icon16,
    icon17,
    icon18,
    icon19,
    icon20
].map((icon, i) => ({
    key: `${i + 1}`,
    icon
}))
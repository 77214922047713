import moment from 'moment';

export const createAppParamsOptions = (data) => {
    const options = data.map((item) => ({
		value: item.id,
		label: item.name
    }));
    
    return options;
}

export const createTypeOptions = (types) => {
    const typeOptions = types.map((item) => ({
		value: item.id,
		label: item.name
    }));
    
    return typeOptions;
}

export const createEventOptionsByType = (events, type_id) => {
    const eventOptions = events.reduce((accumulator, currentValue) => {
        if (currentValue.type_id === type_id) {
            return [...accumulator, {
                value: currentValue.id,
                label: currentValue.name
            }]
        }

        return accumulator;
    }, [])
    
    return eventOptions;
}

export const getEventsByDay = (events, date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD hh:mm');

    const eventsByDay = events
        .filter(({ start }) => moment(start).format('YYYY-MM-DD hh:mm') === formattedDate)
        .sort((a, b) =>
            moment(a.start).format('YYYY-MM-DD hh:mm') < moment(b.start).format('YYYY-MM-DD hh:mm') ? -1 : 1)

    return eventsByDay;
}

export const getSettingsEventsByType = (events, typeId) =>
    events.filter((event) => event.type_id === typeId);

export const createSettingsEventsOptions = (events) => 
    events.map((event) => ({
        id: event.id,
        label: event.name
    }));
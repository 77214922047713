import React from 'react';
import PropTypes from 'prop-types';
import { 
    ProgressSpinner as PrimeProgressSpinner 
} from 'primereact/progressspinner';

export default class ProgressSpinner extends React.Component {

    getClassName() {
        const classNames = ['o-progress-spinner'];
        
        if (this.props.forViewport) {
            classNames.push('o-progress-spinner--for-viewport');
        }

        if (this.props.forContent) {
            classNames.push('o-progress-spinner--for-content')
        }
        
        return classNames.join(' ');
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <PrimeProgressSpinner />
            </div>

        )
    }
}

ProgressSpinner.propTypes = {
    forViewport: PropTypes.bool
}
// Import translation
import i18n from 'i18next';

import { isValidPesel } from 'helpers';

export default ({
    pesel
}) => {
    const errors = {};

    if (pesel.length > 0) {
        if (pesel.length !== 11) {
            errors.pesel = [i18n.t('patient:validation.peselLength')];
        } else if (!isValidPesel(pesel)) {
            errors.pesel = [i18n.t('patient:validation.pesel')];
        }
    }

    return errors;
}
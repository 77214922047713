import * as types from './types/app_params_types';

import * as AppParamsService from '../../services/app-params-service';

export const fetchAllEvents = (params) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_FETCH_ALL_EVENTS_REQUEST
    });

    try {
        const response = await AppParamsService.getEvents({
            page: 1,
            perPage: 100000,
            searchQuery: params ? params.searchQuery : ''
        });
        
        dispatch({
            type: types.APP_PARAMS_FETCH_ALL_EVENTS_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_FETCH_ALL_EVENTS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const addEvent = (values) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_ADD_EVENT_REQUEST
    });

    try {
        const response = await AppParamsService.addEvent(values);
        
        dispatch({
            type: types.APP_PARAMS_ADD_EVENT_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_ADD_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const updateEvent = (values, eventId) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_UPDATE_EVENT_REQUEST
    });

    try {
        const response = await AppParamsService.updateEvent(values, eventId);
        
        dispatch({
            type: types.APP_PARAMS_UPDATE_EVENT_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_UPDATE_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const removeEvent = (eventId) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_REMOVE_EVENT_REQUEST
    });

    try {
        const response = await AppParamsService.removeEvent(eventId);
        
        dispatch({
            type: types.APP_PARAMS_REMOVE_EVENT_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_REMOVE_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const setEventsSearchQuery = (query) => {
    return {
        type: types.APP_PARAMS_SET_EVENTS_SEARCH_QUERY,
        payload: query
    }
}

export const setEventForm = (type, event) => {
    return {
        type: types.APP_PARAMS_SET_EVENT_FORM,
        payload: {
            type,
            event
        }
    }
}

export const fetchTreatmentProgram = () => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_FETCH_TREATMENT_PROGRAM_REQUEST
    });

    try {
        const response = await AppParamsService.getTreatmentProgram();
        
        dispatch({
            type: types.APP_PARAMS_FETCH_TREATMENT_PROGRAM_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_FETCH_TREATMENT_PROGRAM_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}


export const updateTreatmentProgram = (values) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_UPDATE_TREATMENT_PROGRAM_REQUEST
    });

    try {
        const response = await AppParamsService.updateTreatmentProgram(values);
        
        dispatch({
            type: types.APP_PARAMS_UPDATE_TREATMENT_PROGRAM_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_UPDATE_TREATMENT_PROGRAM_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const fetchStages = (params, callback) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_FETCH_STAGES_REQUEST
    });

    try {
        const response = await AppParamsService.getStages({
            page: 1,
            perPage: 100000,
            searchQuery: params ? params.searchQuery : ''
        });
        
        dispatch({
            type: types.APP_PARAMS_FETCH_STAGES_SUCCESS,
            payload: response.data.data
        })

        callback && callback();
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_FETCH_STAGES_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const addStage = (values, formActions) => async dispatch => {
    dispatch({
        type: types.STAGE_ADD_REQUEST
    });

    try {
        const response = await AppParamsService.addStage(values);

        dispatch({
            type: types.STAGE_ADD_SUCCESS,
            payload: response.data
        })

        formActions.resetForm();
    } catch (err) {
        dispatch({
            type: types.STAGE_ADD_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetAddStageRequest = () => {
    return {
        type: types.STAGE_ADD_RESET
    }
}

export const updateStage = (values, stageId, formActions, onUpdate) => async dispatch => {
    dispatch({
        type: types.STAGE_UPDATE_REQUEST
    });

    try {
        const response = await AppParamsService.updateStage(values, stageId);

        dispatch({
            type: types.STAGE_UPDATE_SUCCESS,
            payload: response.data
        })

        formActions.resetForm();

        onUpdate();
    } catch (err) {
        dispatch({
            type: types.STAGE_UPDATE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
};

export const resetUpdateStageRequest = () => {
    return {
        type: types.STAGE_UPDATE_RESET
    }
}

export const fetchCenterTypes = (params, callback) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_FETCH_CENTER_TYPES_REQUEST
    });

    try {
        const response = await AppParamsService.getCenterTypes({
            page: 1,
            perPage: 100000,
            searchQuery: params ? params.searchQuery : ''
        });
        
        dispatch({
            type: types.APP_PARAMS_FETCH_CENTER_TYPES_SUCCESS,
            payload: response.data.data
        })

        callback && callback();
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_FETCH_CENTER_TYPES_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const addCenterType = (values, formActions) => async dispatch => {
    dispatch({
        type: types.CENTER_TYPE_ADD_REQUEST
    });

    try {
        const response = await AppParamsService.addCenterType(values);

        dispatch({
            type: types.CENTER_TYPE_ADD_SUCCESS,
            payload: response.data
        })

        formActions.resetForm();
    } catch (err) {
        dispatch({
            type: types.CENTER_TYPE_ADD_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetAddCenterTypeRequest = () => {
    return {
        type: types.CENTER_TYPE_ADD_RESET
    }
}

export const updateCenterType = (values, centerTypeId, formActions, onUpdate) => async dispatch => {
    dispatch({
        type: types.CENTER_TYPE_UPDATE_REQUEST
    });

    try {
        const response = await AppParamsService.updateCenterType(values, centerTypeId);

        dispatch({
            type: types.CENTER_TYPE_UPDATE_SUCCESS,
            payload: response.data
        })

        formActions.resetForm();

        onUpdate();
    } catch (err) {
        dispatch({
            type: types.CENTER_TYPE_UPDATE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
};

export const resetUpdateCenterTypeRequest = () => {
    return {
        type: types.CENTER_TYPE_UPDATE_RESET
    }
}

export const fetchInterventions = () => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_FETCH_INTERVENTIONS_REQUEST
    });

    try {
        const response = await AppParamsService.getInterventions();
        
        dispatch({
            type: types.APP_PARAMS_FETCH_INTERVENTIONS_SUCCESS,
            payload: response.data.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_FETCH_INTERVENTIONS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const addIntervention = (data) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_ADD_INTERVENTION_REQUEST
    });

    try {
        const response = await AppParamsService.addIntervention(data);
        
        dispatch({
            type: types.APP_PARAMS_ADD_INTERVENTION_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_ADD_INTERVENTION_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const updateIntervention = (data, interventionId) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_UPDATE_INTERVENTION_REQUEST
    });

    try {
        const response = await AppParamsService.updateIntervention(data, interventionId);
        
        dispatch({
            type: types.APP_PARAMS_UPDATE_INTERVENTION_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_UPDATE_INTERVENTION_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const removeIntervention = (interventionId) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_REMOVE_INTERVENTION_REQUEST
    });

    try {
        const response = await AppParamsService.removeIntervention(interventionId);
        
        dispatch({
            type: types.APP_PARAMS_REMOVE_INTERVENTION_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_REMOVE_INTERVENTION_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetAllInterventionRequests = () => dispatch => {
    dispatch({
        type: types.APP_PARAMS_RESET_ALL_INTERVENTION_REQUESTS
    })
}

export const fetchTypes = () => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_FETCH_TYPES_REQUEST
    });

    try {
        const response = await AppParamsService.getTypes();
        
        dispatch({
            type: types.APP_PARAMS_FETCH_TYPES_SUCCESS,
            payload: response.data.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_FETCH_TYPES_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const addType = (data) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_ADD_TYPE_REQUEST
    });

    try {
        const response = await AppParamsService.addType(data);
        
        dispatch({
            type: types.APP_PARAMS_ADD_TYPE_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_ADD_TYPE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const updateType = (data, typeId) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_UPDATE_TYPE_REQUEST
    });

    try {
        const response = await AppParamsService.updateType(data, typeId);
        
        dispatch({
            type: types.APP_PARAMS_UPDATE_TYPE_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_UPDATE_TYPE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const removeType = (typeId) => async dispatch => {
    dispatch({
        type: types.APP_PARAMS_REMOVE_TYPE_REQUEST
    });

    try {
        const response = await AppParamsService.removeType(typeId);
        
        dispatch({
            type: types.APP_PARAMS_REMOVE_TYPE_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.APP_PARAMS_REMOVE_TYPE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetAllTypeRequests = () => dispatch => {
    dispatch({
        type: types.APP_PARAMS_RESET_ALL_TYPE_REQUESTS
    })
}
import { useState, useEffect } from 'react';

const useScrollbarWidth = (elementRef) => {
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    const updateWidth = () => {
        const element = elementRef.current;

        let newScrollbarWidth = 0;

        if (element) {
            newScrollbarWidth = element.offsetWidth - element.clientWidth;
        }

        if (newScrollbarWidth !== scrollbarWidth) {
            setScrollbarWidth(newScrollbarWidth);
        }
    }

    const handleResize = () => {
        updateWidth();
    };

    useEffect(() => {
        updateWidth();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });

    return scrollbarWidth;
};

export default useScrollbarWidth;
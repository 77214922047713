import * as types from '../actions/types/chat_types';

import chatInitialState from "./helpers/chat-initial-state";

const initialState = chatInitialState;

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHAT_SEND_MESSAGE_REQUEST: {
            const {
                sendMessageRequest
            } = state;

            return {
                ...state,
                sendMessageRequest: {
                    ...sendMessageRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_SEND_MESSAGE_SUCCESS: {
            const {
                sendMessageRequest,
                conversationData
            } = state;


            return {
                ...state,
                sendMessageRequest: {
                    ...sendMessageRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                conversationData: [
                    ...action.payload.data,
                    ...conversationData
                ]

            }
        }
        case types.CHAT_SEND_MESSAGE_FAILURE: {
            const {
                sendMessageRequest
            } = state;

            return {
                ...state,
                sendMessageRequest: {
                    ...sendMessageRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.CHAT_FETCH_CONTACTS_REQUEST: {
            const {
                fetchContactsRequest
            } = state;

            return {
                ...state,
                fetchContactsRequest: {
                    ...fetchContactsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_CONTACTS_SUCCESS: {
            const {
                fetchContactsRequest
            } = state;

            return {
                ...state,
                fetchContactsRequest: {
                    ...fetchContactsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                contacts: action.payload
            }
        }
        case types.CHAT_FETCH_CONTACTS_FAILURE: {
            const {
                fetchContactsRequest
            } = state;

            return {
                ...state,
                fetchContactsRequest: {
                    ...fetchContactsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
                contacts: []
            }
        }
        case types.CHAT_SELECT_CONTACT: {
            return {
                ...state,
                selectedContactId: action.payload
            }
        }
        case types.CHAT_FETCH_AVAILABLE_HOSPITALS_REQUEST: {
            const {
                fetchAvailableHospitalsRequest
            } = state;

            return {
                ...state,
                fetchAvailableHospitalsRequest: {
                    ...fetchAvailableHospitalsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_AVAILABLE_HOSPITALS_SUCCESS: {
            const {
                fetchAvailableHospitalsRequest
            } = state;

            return {
                ...state,
                fetchAvailableHospitalsRequest: {
                    ...fetchAvailableHospitalsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                availableHospitals: action.payload.data
            }
        }
        case types.CHAT_FETCH_AVAILABLE_HOSPITALS_FAILURE: {
            const {
                fetchAvailableHospitalsRequest
            } = state;

            return {
                ...state,
                fetchAvailableHospitalsRequest: {
                    ...fetchAvailableHospitalsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
                availableHospitals: []
            }
        }
        case types.CHAT_SELECT_HOSPITAL: {
            return {
                ...state,
                selectedHospitalId: action.payload
            }
        }
        case types.CHAT_FETCH_CONVERSATION_REQUEST: {
            const {
                fetchConversationRequest
            } = state;

            return {
                ...state,
                fetchConversationRequest: {
                    ...fetchConversationRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_CONVERSATION_SUCCESS: {
            const {
                fetchConversationRequest
            } = state;

            return {
                ...state,
                fetchConversationRequest: {
                    ...fetchConversationRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                conversation: action.payload,
                conversationData: action.payload.data
            }
        }
        case types.CHAT_FETCH_CONVERSATION_FAILURE: {
            const {
                fetchConversationRequest
            } = state;

            return {
                ...state,
                fetchConversationRequest: {
                    ...fetchConversationRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
                conversation: null,
                conversationData: []
            }
        }
        case types.CHAT_FETCH_CONVERSATION_NEXT_PAGE_REQUEST: {
            const {
                fetchConversationNextPageRequest
            } = state;

            return {
                ...state,
                fetchConversationNextPageRequest: {
                    ...fetchConversationNextPageRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_CONVERSATION_NEXT_PAGE_SUCCESS: {
            const {
                fetchConversationNextPageRequest,
                conversationData
            } = state;

            return {
                ...state,
                fetchConversationNextPageRequest: {
                    ...fetchConversationNextPageRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                conversation: action.payload,
                conversationData: [
                    ...conversationData,
                    ...action.payload.data.filter(item => {
                        return conversationData.find(_item => _item.id === item.id) ? false : true;
                    })
                ]
            }
        }
        case types.CHAT_FETCH_CONVERSATION_NEXT_PAGE_FAILURE: {
            const {
                fetchConversationNextPageRequest
            } = state;

            return {
                ...state,
                fetchConversationNextPageRequest: {
                    ...fetchConversationNextPageRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.CHAT_CONTACTS_SET_SEARCH_QUERY: {
            return {
                ...state,
                contactsSearchQuery: action.payload
            }
        }
        case types.CHAT_FETCH_OBSERVATIONS_REQUEST: {
            const {
                fetchObservationsRequest
            } = state;

            return {
                ...state,
                fetchObservationsRequest: {
                    ...fetchObservationsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_OBSERVATIONS_SUCCESS: {
            const {
                fetchObservationsRequest
            } = state;

            return {
                ...state,
                observations: action.payload.data,
                fetchObservationsRequest: {
                    ...fetchObservationsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_OBSERVATIONS_FAILURE: {
            const {
                fetchObservationsRequest
            } = state;

            return {
                ...state,
                observations: [],
                fetchObservationsRequest: {
                    ...fetchObservationsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.CHAT_FETCH_UNREADED_MESSAGES_REQUEST: {
            const {
                fetchUnreadedMessagesRequest
            } = state;

            return {
                ...state,
                fetchUnreadedMessagesRequest: {
                    ...fetchUnreadedMessagesRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_UNREADED_MESSAGES_SUCCESS: {
            const {
                fetchUnreadedMessagesRequest
            } = state;

            return {
                ...state,
                unreadedMessages: action.payload,
                fetchUnreadedMessagesRequest: {
                    ...fetchUnreadedMessagesRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload.data,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_UNREADED_MESSAGES_FAILURE: {
            const {
                fetchUnreadedMessagesRequest
            } = state;

            return {
                ...state,
                unreadedMessages: 0,
                fetchUnreadedMessagesRequest: {
                    ...fetchUnreadedMessagesRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_REQUEST: {
            const {
                fetchUnreadedMessagesOfPatientRequest
            } = state;

            return {
                ...state,
                fetchUnreadedMessagesOfPatientRequest: {
                    ...fetchUnreadedMessagesOfPatientRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_SUCCESS: {
            const {
                contacts,
                fetchUnreadedMessagesOfPatientRequest
            } = state;

            const prevSelected = contacts.find((item) => item.data.id === action.payload.patientId);
            const PrevSelectedIndex = contacts.findIndex((item) => item.data.id === action.payload.patientId)

            prevSelected.newMessages = action.payload.data;

            const contactsToState = [...contacts];
            contactsToState[PrevSelectedIndex] = prevSelected;

            return {
                ...state,
                contacts: contactsToState,
                fetchUnreadedMessagesOfPatientRequest: {
                    ...fetchUnreadedMessagesOfPatientRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_FAILURE: {
            const {
                fetchUnreadedMessagesOfPatientRequest
            } = state;

            return {
                ...state,
                fetchUnreadedMessagesOfPatientRequest: {
                    ...fetchUnreadedMessagesOfPatientRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.CHAT_CLEAR_CONVERSATION_DATA: {
            return {
                ...state,
                conversationData: []
            }
        }
        case types.CHAT_RESET: {
            return {
                ...initialState,
                unreadedMessages: state.unreadedMessages
            }
        }
        default:
            return state;
    }
}

export default chatReducer;
export const RECOMMENDATION_FETCH_ALL_REQUEST = 'RECOMMENDATION_FETCH_ALL_REQUEST';
export const RECOMMENDATION_FETCH_ALL_SUCCESS = 'RECOMMENDATION_FETCH_ALL_SUCCESS';
export const RECOMMENDATION_FETCH_ALL_FAILURE = 'RECOMMENDATION_FETCH_ALL_FAILURE';

export const RECOMMENDATION_ADD_REQUEST = 'RECOMMENDATION_ADD_REQUEST';
export const RECOMMENDATION_ADD_SUCCESS = 'RECOMMENDATION_ADD_SUCCESS';
export const RECOMMENDATION_ADD_FAILURE = 'RECOMMENDATION_ADD_FAILURE';
export const RECOMMENDATION_ADD_RESET = 'RECOMMENDATION_ADD_RESET';

export const RECOMMENDATION_UPDATE_REQUEST = 'RECOMMENDATION_UPDATE_REQUEST';
export const RECOMMENDATION_UPDATE_SUCCESS = 'RECOMMENDATION_UPDATE_SUCCESS';
export const RECOMMENDATION_UPDATE_FAILURE = 'RECOMMENDATION_UPDATE_FAILURE';
export const RECOMMENDATION_UPDATE_RESET = 'RECOMMENDATION_UPDATE_RESET';

export const RECOMMENDATION_REMOVE_REQUEST = 'RECOMMENDATION_REMOVE_REQUEST';
export const RECOMMENDATION_REMOVE_SUCCESS = 'RECOMMENDATION_REMOVE_SUCCESS';
export const RECOMMENDATION_REMOVE_FAILURE = 'RECOMMENDATION_REMOVE_FAILURE';
export const RECOMMENDATION_REMOVE_RESET = 'RECOMMENDATION_REMOVE_RESET';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';

const ProtectedRouteContent = (props) => {
    const user = useSelector(store => store.user);

    if (!user.isAuthenticated) {
        return <Redirect to="/login" />
    }

    if (props.allowedRoles) {
        const hasAccess = user.data && props.allowedRoles.some(role => user.data.role === role);

        if (!hasAccess) {

            return <Redirect to="/" />
        }
    }

    return props.children;
}

ProtectedRouteContent.propTypes = {
    children: PropTypes.node,
    allowedRoles: PropTypes.arrayOf(PropTypes.string)
}

const ProtectedRoute = (props) => {


    return (
        <Route
            path={props.path}
            exact={props.exact}
        >
            <ProtectedRouteContent
                allowedRoles={props.allowedRoles}
            >
                {props.children}
            </ProtectedRouteContent>
        </Route>
    )
};

ProtectedRoute.propTypes = {
    children: PropTypes.node,
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string
}

export default ProtectedRoute;


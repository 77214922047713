import moment from 'moment';

import * as types from '../actions/types/patient_measurements_types';

const date = moment().format('YYYY-MM-DD');
const from = moment().subtract(5, 'days').format('YYYY-MM-DD');
const to = moment().add(5, 'days').format('YYYY-MM-DD');

const initialState = {
    measurements: [],
    fetchMeasurementsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    measurementsType: '',
    graphDateView: {
        'workout_steps': {
            date,
            from,
            to,
            view: 'week'
        },
        'blood_pressure': {
            date,
            from,
            to,
            view: 'week'
        },
        'heart_rate': {
            date,
            from,
            to,
            view: 'week'
        },
        'body_mass': {
            date,
            from,
            to,
            view: 'week'
        },
        'waist_length': {
            date,
            from,
            to,
            view: 'week'
        },
        'fat_percent': {
            date,
            from,
            to,
            view: 'week'
        },
        'saturation': {
            date,
            from,
            to,
            view: 'week'
        },
        'insulin_got': {
            date,
            from,
            to,
            view: 'week'
        },
        'glycemic_measurement': {
            date,
            from,
            to,
            view: 'week'
        },
        'fevi': {
            date,
            from,
            to,
            view: 'week'
        },
        'fvc': {
            date,
            from,
            to,
            view: 'week'
        },
        'pef': {
            date,
            from,
            to,
            view: 'week'
        }
    }
};

const patientMeasurementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_REQUEST: {
            const {
                fetchMeasurementsRequest
            } = state;

            return {
                ...state,
                fetchMeasurementsRequest: {
                    ...fetchMeasurementsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_SUCCESS: {
            const {
                fetchMeasurementsRequest
            } = state;

            return {
                ...state,
                measurements: action.payload,
                fetchMeasurementsRequest: {
                    ...fetchMeasurementsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_FAILURE: {
            const {
                fetchMeasurementsRequest
            } = state;

            return {
                ...state,
                measurements: [],
                fetchMeasurementsRequest: {
                    ...fetchMeasurementsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.PATIENT_MEASUREMENTS_SET_MEASUREMENTS_TYPE: {
            return {
                ...state,
                measurementsType: action.payload
            }
        }
        case types.PATIENT_MEASUREMENTS_SET_GRAPH_VIEW: {
            return {
                ...state,
                graphDateView: {
                    ...state.graphDateView,
                    [action.payload.type]: {
                        ...state.graphDateView[action.payload.type],
                        view: action.payload.view
                    }
                }
            }
        }
        case types.PATIENT_MEASUREMENTS_SET_GRAPH_DATE: {
            return {
                ...state,
                graphDateView: {
                    ...state.graphDateView,
                    [action.payload.type]: {
                        ...state.graphDateView[action.payload.type],
                        date: action.payload.date
                    }
                }
            }
        }
        case types.PATIENT_MEASUREMENTS_SET_GRAPH_PERIOD: {
            return {
                ...state,
                graphDateView: {
                    ...state.graphDateView,
                    [action.payload.type]: {
                        ...state.graphDateView[action.payload.type],
                        from: action.payload.from,
                        to: action.payload.to
                    }
                }
            }
        }
        default:
            return state;
    }
}

export default patientMeasurementsReducer;
export const CHAT_SEND_MESSAGE_REQUEST = 'CHAT_SEND_MESSAGE_REQUEST';
export const CHAT_SEND_MESSAGE_SUCCESS = 'CHAT_SEND_MESSAGE_SUCCESS';
export const CHAT_SEND_MESSAGE_FAILURE = 'CHAT_SEND_MESSAGE_FAILURE';

export const CHAT_FETCH_AVAILABLE_HOSPITALS_REQUEST = 'CHAT_FETCH_AVAILABLE_HOSPITALS_REQUEST';
export const CHAT_FETCH_AVAILABLE_HOSPITALS_SUCCESS = 'CHAT_FETCH_AVAILABLE_HOSPITALS_SUCCESS';
export const CHAT_FETCH_AVAILABLE_HOSPITALS_FAILURE = 'CHAT_FETCH_AVAILABLE_HOSPITALS_FAILURE';

export const CHAT_SELECT_HOSPITAL = 'CHAT_SELECT_HOSPITAL';

export const CHAT_FETCH_CONTACTS_REQUEST = 'CHAT_FETCH_CONTACTS_REQUEST';
export const CHAT_FETCH_CONTACTS_SUCCESS = 'CHAT_FETCH_CONTACTS_SUCCESS';
export const CHAT_FETCH_CONTACTS_FAILURE = 'CHAT_FETCH_CONTACTS_FAILURE';

export const CHAT_SELECT_CONTACT = 'CHAT_SELECT_CONTACT';

export const CHAT_FETCH_OBSERVATIONS_REQUEST = 'CHAT_FETCH_OBSERVATIONS_REQUEST';
export const CHAT_FETCH_OBSERVATIONS_SUCCESS = 'CHAT_FETCH_OBSERVATIONS_SUCESS';
export const CHAT_FETCH_OBSERVATIONS_FAILURE = 'CHAT_FETCH_OBSERVATIONS_FAILURE';

export const CHAT_FETCH_UNREADED_MESSAGES_REQUEST = 'CHAT_FETCH_UNREADED_MESSAGES_REQUEST';
export const CHAT_FETCH_UNREADED_MESSAGES_SUCCESS = 'CHAT_FETCH_UNREADED_MESSAGES_SUCCESS';
export const CHAT_FETCH_UNREADED_MESSAGES_FAILURE = 'CHAT_FETCH_UNREADED_MESSAGES_FAILURE';

export const CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_REQUEST =
    'CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_REQUEST';
export const CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_SUCCESS =
    'CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_SUCCESS';
export const CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_FAILURE =
    'CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_FAILURE';

export const CHAT_FETCH_CONVERSATION_REQUEST = 'CHAT_FETCH_CONVERSATION_REQUEST';
export const CHAT_FETCH_CONVERSATION_SUCCESS = 'CHAT_FETCH_CONVERSATION_SUCCESS';
export const CHAT_FETCH_CONVERSATION_FAILURE = 'CHAT_FETCH_CONVERSATION_FAILURE';

export const CHAT_FETCH_CONVERSATION_NEXT_PAGE_REQUEST = 'CHAT_FETCH_CONVERSATION_NEXT_PAGE_REQUEST';
export const CHAT_FETCH_CONVERSATION_NEXT_PAGE_SUCCESS = 'CHAT_FETCH_CONVERSATION_NEXT_PAGE_SUCCESS';
export const CHAT_FETCH_CONVERSATION_NEXT_PAGE_FAILURE = 'CHAT_FETCH_CONVERSATION_NEXT_PAGE_FAILURE';

export const CHAT_CONTACTS_SET_SEARCH_QUERY = 'CHAT_CONTACTS_SET_SEARCH_QUERY';

export const CHAT_CLEAR_CONVERSATION_DATA = 'CHAT_CLEAR_CONVERSATION_DATA';

export const CHAT_RESET = 'CHAT_RESET';
import React from 'react';
import PropTypes from 'prop-types';

export default class Button extends React.Component {
    
    createClassName() {
        let classNames = ['o-btn'];

        if (this.props.variant) {
            classNames.push(`o-btn--${this.props.variant}`)
        }

        if (this.props.size) {
            classNames.push(`o-btn--size-${this.props.size}`)
        }

        if (this.props.rounded) {
            classNames.push(`o-btn--rounded`);
        }

        if (this.props.disabled) {
            classNames.push('is-disabled');
        }

        if (this.props.className) {
            classNames = [
                ...classNames, 
                ...this.props.className.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    render() {
        return (
            <button
                type={this.props.type || 'button' }
                disabled={this.props.disabled}
                className={this.createClassName()}
                onClick={this.props.onClick}
            >
                {this.props.icon && 
                    <img 
                        src={this.props.icon} 
                        alt={this.props.title} 
                        className="o-btn__icon" 
                    />
                }
                <span className="o-btn__title">{this.props.title}</span>
            </button>
        );

    }
}

Button.propTypes = {
    type: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'info', 'light']),
    size: PropTypes.oneOf(['xs', 'sm']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    rounded: PropTypes.bool,
    icon: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
}
import * as types from '../actions/types/medicine_taking_types';

const initialState = {
    medicinesTakingDate: new Date(),
    medicinesTaking: [],
    fetchRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

const medicineReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MEDICINE_TAKING_FETCH_REQUEST: {
            const {
                fetchRequest
            } = state;

            return {
                ...state,
                fetchRequest: {
                    ...fetchRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.MEDICINE_TAKING_FETCH_SUCCESS: {
            const {
                fetchRequest
            } = state;

            return {
                ...state,
                fetchRequest: {
                    ...fetchRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                medicinesTaking: action.payload.data
            }
        }
        case types.MEDICINE_TAKING_FETCH_FAILURE: {
            const {
                fetchRequest
            } = state;

            return {
                ...state,
                fetchRequest: {
                    ...fetchRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
                medicinesTaking: []
            }
        }
        case types.MEDICINE_TAKING_SET_DATE: {
            return {
                ...state,
                medicinesTakingDate: action.payload
            }
        }
        default:
            return state;
    }
}

export default medicineReducer;
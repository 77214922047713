import * as types from '../actions/types/diseases_types';
import diseasesInitialState from './helpers/diseases-initial-state';

const initialState = diseasesInitialState;

const diseasesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DISEASES_FETCH_ALL_REQUEST: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.DISEASES_FETCH_ALL_SUCCESS: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                diseases: action.payload.data,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.DISEASES_FETCH_ALL_FAILURE: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                diseases: [],
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.DISEASES_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.DISEASES_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.DISEASES_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.DISEASES_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.DISEASES_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.DISEASES_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.DISEASES_ORDER_REQUEST: {
            const {
                orderRequest
            } = state;

            return {
                ...state,
                orderRequest: {
                    ...orderRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.DISEASES_ORDER_SUCCESS: {
            const {
                orderRequest
            } = state;

            return {
                ...state,
                orderRequest: {
                    ...orderRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.DISEASES_ORDER_FAILURE: {
            const {
                orderRequest
            } = state;

            return {
                ...state,
                orderRequest: {
                    ...orderRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.DISEASES_ORDER_RESET: {
            return {
                ...state,
                orderRequest: initialState.orderRequest
            }
        }
        case types.DISEASES_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.DISEASES_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.DISEASES_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        default:
            return state;
    }
}

export default diseasesReducer;
import axios from 'axios';

import { createHttpClient } from './api-service';
import { getPatients } from './patient-service';

export const sendMessage = (message, contactId, role) => {
    const api = createHttpClient();

    let url;
    if (role === 'admin' || role === 'coordinator') {
        url = `/dashboard/chat/message/${contactId}`;
    } else {
        // all doctors
        url = `/dashboard/chat/private-message/${contactId}`
    }

    const data = {
        center_id: message.hospitalId,
        type: message.media ? 'image' : 'text',
        body: message.media ? message.media.full_url : message.body
    };

    return api.post(url, data);
}

export const sendSms = (contactId) => {
    const api = createHttpClient();

    const url = `/dashboard/chat/sms/${contactId}`;

    return api.get(url);
}

export const getAvailableHospitals = async () => {
    const api = createHttpClient();

    const url = `/dashboard/chat/get-available-centers`;

    return api.get(url);
}

export const getConversation = (patientId, hospitalId, page, role) => {
    const api = createHttpClient();

    const data = {
        center_id: hospitalId,
        per_page: 30,
        page: page,
        order_dir: "desc"
    }

    let url;
    if (role === 'admin' || role === 'coordinator') {
        url = `/dashboard/chat/get-by-patient/${patientId}`;
    } else {
        // all doctors
        url = `/dashboard/chat/get-conversation-by-patient/${patientId}`
    }

    return api.post(url, data);
};

// not needded, maybe
export const getHospitalConversations = (params) => {
    const api = createHttpClient();

    const url = `/dashboard/chat/get-by-center/${params.hospitalId}`;

    const data = {
        center_id: params.hospitalId,
        per_page: params.perPage,
        page: params.page,
        order_dir: "desc"
    };

    return api.post(url, data);
};

export const getConversations = (params) => {
    const api = createHttpClient();

    const data = {
        per_page: params.perPage,
        page: params.page,
        order_dir: "desc"
    };

    let url;
    if (params.role === 'admin' || params.role === 'coordinator') {
        url = `/dashboard/chat/get-by-center/${params.hospitalId}`;
        data.center_id = params.hospitalId;
    } else {
        // all doctors
        url = `/dashboard/chat/get-conversations`
    }

    return api.post(url, data);
};

export const getNumberOfUnreadMessages = (patientId, role) => {
    const api = createHttpClient();

    let url;
    if (role === 'admin' || role === 'coordinator') {
        url = `/dashboard/chat/unreaded-patient-messages/${patientId}`;
    } else {
        // all doctors
        url = `/dashboard/chat/unreaded-private-messages/${patientId}`
    }

    return api.get(url);
}

export const getContacts = async (hospitalId, params) => {

    const options = {
        page: 1,
        perPage: 999999,
        hospitalId,
    };

    const conversationsOptions = {
        role: params.role,
        ...options
    }

    const patientsOptions = {
        ...options,
        filters: {
            query: params.query
        }
    }

    try {
        const [
            conversationsResponse,
            patientsResponse
        ] = await axios.all([
            //getHospitalConversations(conversationsOptions),
            getConversations(conversationsOptions),
            getPatients(patientsOptions)
        ]);

        const patients = patientsResponse.data.data;

        const conversations = conversationsResponse.data.data;

        // const contacts = patients.map(patient => {
        //     const patientConversation = conversations.find(({ conversation }) => {
        //         return conversation.participants.find(participant => participant.messageable_id === patient.id ? true : false)
        //     });

        //     return {
        //         data: patient,
        //         conversation: patientConversation
        //     }
        // });;

        const contactsWithChat = [];
        const contactsWithoutChat = [];

        await Promise.all(patients.map(async (patient) => {
            const patientConversation = conversations.find(({ conversation }) => {
                return conversation.participants.find(participant => participant.messageable_id === patient.id ? true : false)
            });

            if (patientConversation) {
                let unreadMessage

                if (!!patientConversation.conversation.last_message &&
                    patientConversation.conversation.last_message.sender.id === patient.id &&
                    patientConversation.conversation.last_message.is_seen === 0) {
                    unreadMessage = await getNumberOfUnreadMessages(patient.id, params.role);
                }

                contactsWithChat.push({
                    data: patient,
                    conversation: patientConversation,
                    newMessages: !!unreadMessage && unreadMessage.data
                })
            } else {
                contactsWithoutChat.push({
                    data: patient,
                    conversation: null,
                    newMessages: 0
                })
            }
        }));

        contactsWithChat.sort((a, b) => {
            if (a.conversation.updated_at > b.conversation.updated_at) {
                return -1;
            } else {
                return 1;
            }
        });

        contactsWithoutChat.sort((a, b) => {
            if (a.data.first_name.toUpperCase() < b.data.first_name.toUpperCase()) {
                return -1;
            } else {
                return 1;
            }
        })

        return [...contactsWithChat, ...contactsWithoutChat];

    } catch (err) {
        throw new Error(err);
    }
};

export const getUnreadedMessages = () => {
    const api = createHttpClient();

    const url = `/dashboard/chat/unreaded-messages`;

    return api.get(url);
}

export const flattenHospitals = (hospitals) => {
    let result = [];

    hospitals.forEach(hospital => {
        result.push(hospital);
    });

    return result;
}


export const getMediaType = () => {
    return 'image';
}

//Mark all chat messages with the patient as read
export const markAllMessagesReaded = (centerId, patientId, role) => {
    const api = createHttpClient();

    let url;
    if (role === 'admin' || role === 'coordinator') {
        url = `/dashboard/chat/read-conversation-by-patient/${centerId}/${patientId}`;
    } else {
        url = `dashboard/chat/read-conversation/${patientId}`;
    }

    return api.get(url);
}
import React from "react";

import Select from "./Select";

const options = [
    {
        label: "+48",
        value: "+48",
    },
];

export default class PhoneNumberCodeSelect extends React.Component {
    render() {
        return <Select options={options} {...this.props} />;
    }
}

PhoneNumberCodeSelect.propTypes = {
    ...Select.propTypes,
};

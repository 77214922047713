export const initialFilters = {
    "q": "",
    "from": new Date(),
    "to": new Date()
}

export const columns = [
    {
        field: "type_name",
        header: "Wydarzenie"
    },
    {
        field: "place_name",
        header: "Gdzie"
    },
    {
        field: "start_date",
        header: "Godzina",
        render: true,
        style: {width: '100px'}
    },
    {
        field: "last_name",
        header: "Nazwisko",
        render: true,
        isLink: true
    },
    {
        field: "first_name",
        header: "Imie",
        render: true,
        isLink: true
    },
    {
        field: "gender",
        header: "Płeć",
        render: true
    },
    {
        field: "treatment_program",
        header: "Program leczenia (KOS) / pozostało dni",
        render: true,
        style: { width: '330px' }
    },
    {
        field: "birthdate",
        header: "Data ur. (wiek)",
        render: true
    },
    {
        field: "status",
        header: "Status",
        render: true
    }
]

export const columnsModal = [
    {
        field: "type_name",
        header: "Wydarzenie"
    },
    {
        field: "place_name",
        header: "Gdzie",
        style: {width: '250px'}
    },
    {
        field: "start_date",
        header: "Godzina",
        render: true,
        style: {width: '100px'}
    },
    {
        field: "last_name",
        header: "Nazwisko",
        render: true
    },
    {
        field: "first_name",
        header: "Imie",
        render: true
    },
    {
        field: "birthdate",
        header: "Data ur. (wiek)",
        render: true
    },
    {
        field: "status",
        header: "Status",
        render: true
    }
]
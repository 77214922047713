import React from 'react';
import { Calendar } from 'primereact/calendar';
import PropTypes from 'prop-types';

const locales = {
    pl: {
        firstDayOfWeek: 1,
        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        dayNamesShort: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'],
        dayNamesMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'],
        monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
        today: 'Dzisiaj',
        clear: 'Wyczyść',
        dateFormat: 'dd.mm.yy',
        weekHeader: 'Sm'
    }
};

const DateInput = React.forwardRef((props, ref) => {

    const currentYear = new Date().getFullYear();

    const createClassName = () => {
        const classNames = [];

        if (props.size) {
            classNames.push(`p-calendar--size-${props.size}`);
        }

        if (props.variant) {
            classNames.push(`p-calendar-variant-${props.variant}`)
        }

        return classNames.join(' ');
    }

    const handleBlur = (e) => {
        if (props.value instanceof Date === false) {
            if (props.setFieldValue) {
                props.setFieldValue(props.name, null);
            }
        }

        if (props.onBlur) {
            props.onBlur(e);
        }
    }


    return (
        <Calendar
            dateFormat="dd.mm.yy"
            locale={locales.pl}
            showIcon={true}
            appendTo={document.body}
            yearRange={`1900:${currentYear + 10}`}
            monthNavigator={true}
            yearNavigator={true}
            ref={ref}
            className={createClassName()}
            showOnFocus={false}
            placeholder="dd.mm.rrrr"
            {...props}
            onBlur={handleBlur}
        />
    )
})

DateInput.propTypes = {
    size: PropTypes.oneOf(['sm']),
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func,
    onBlur: PropTypes.func
}

export default DateInput;
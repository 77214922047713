export const gridBreakpoints = [
    '',
    'xs',
    'sm',
    'md',
    'lg',
    'xlg',
    '3lg',
    '4lg',
    '5lg'
];

export const apiUrl = process.env.REACT_APP_API_URL;

export const apiDateFormat = 'YYYY-MM-DD';

export const apiDateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
import {
    MEDICINE_ADD_REQUEST,
    MEDICINE_ADD_SUCCESS,
    MEDICINE_ADD_FAILURE,
    MEDICINE_ADD_RESET,
    MEDICINE_UPDATE_REQUEST,
    MEDICINE_UPDATE_SUCCESS,
    MEDICINE_UPDATE_FAILURE,
    MEDICINE_UPDATE_RESET,
    MEDICINE_FETCH_MEDICINES_REQUEST,
    MEDICINE_FETCH_MEDICINES_SUCCESS,
    MEDICINE_FETCH_MEDICINES_FAILURE,
    MEDICINE_SELECT_MEDICINE,
    MEDICINE_FETCH_SELECTED_MEDICINE_REQUEST,
    MEDICINE_FETCH_SELECTED_MEDICINE_SUCCESS,
    MEDICINE_FETCH_SELECTED_MEDICINE_FAILURE,
    MEDICINE_REMOVE_REQUEST,
    MEDICINE_REMOVE_SUCCESS,
    MEDICINE_REMOVE_FAILURE
} from "../actions/types/medicine_types";

const initialState = {
    fetchMedicinesRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    addRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    fetchSelectedMedicineRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    medicines: [],
    selectedMedicineId: null,
    selectedMedicine: null
};

const medicineReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEDICINE_FETCH_MEDICINES_REQUEST: {
            const {
                fetchMedicinesRequest
            } = state;

            return {
                ...state,
                fetchMedicinesRequest: {
                    ...fetchMedicinesRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case MEDICINE_FETCH_MEDICINES_SUCCESS: {
            const {
                fetchMedicinesRequest
            } = state;

            return {
                ...state,
                fetchMedicinesRequest: {
                    ...fetchMedicinesRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                medicines: action.payload.data
            }
        }
        case MEDICINE_FETCH_MEDICINES_FAILURE: {
            const {
                fetchMedicinesRequest
            } = state;

            return {
                ...state,
                fetchMedicinesRequest: {
                    ...fetchMedicinesRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
                medicines: []
            }
        }
        case MEDICINE_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case MEDICINE_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case MEDICINE_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case MEDICINE_ADD_RESET: {
            return {
                ...state,
                addRequest: initialState.addRequest
            }
        }
        case MEDICINE_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case MEDICINE_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case MEDICINE_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case MEDICINE_UPDATE_RESET: {
            return {
                ...state,
                updateRequest: initialState.updateRequest
            }
        }
        case MEDICINE_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case MEDICINE_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                selectedMedicineId: null,
                selectedMedicine: null,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case MEDICINE_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case MEDICINE_SELECT_MEDICINE: {
            return {
                ...state,
                selectedMedicineId: action.payload.medicineId
            }
        }
        case MEDICINE_FETCH_SELECTED_MEDICINE_REQUEST: {
            const {
                fetchSelectedMedicineRequest
            } = state;

            return {
                ...state,
                fetchSelectedMedicineRequest: {
                    ...fetchSelectedMedicineRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case MEDICINE_FETCH_SELECTED_MEDICINE_SUCCESS: {
            const {
                fetchSelectedMedicineRequest
            } = state;

            return {
                ...state,
                fetchSelectedMedicineRequest: {
                    ...fetchSelectedMedicineRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                selectedMedicine: action.payload
            }

        }
        case MEDICINE_FETCH_SELECTED_MEDICINE_FAILURE: {
            const {
                fetchSelectedMedicineRequest
            } = state;

            return {
                ...state,
                fetchSelectedMedicineRequest: {
                    ...fetchSelectedMedicineRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
                selectedMedicine: null
            }
        }
        default:
            return state;
    }
}

export default medicineReducer;
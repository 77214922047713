import { createHttpClient } from './api-service';

export const login = ({ email, password }) => {
    const api = createHttpClient();

    const url = '/login-dashboard';

    return api.post(url, { email, password });
};

export function getToken() {
    return localStorage.getItem('token');
};

export const saveToken = (token) => {
    localStorage.setItem('token', token);
};

export const removeToken = () => {
    localStorage.removeItem('token');
}

export const getSelf = () => {
    const api = createHttpClient();

    const url = '/self';

    return api.get(url);
}

export const sendPasswordResetRequest = ({ email }) => {
    const api = createHttpClient();

    const url = '/password/email-dashboard';

    const data = {
        email
    };

    return api.post(url, data);
}

export const resetPassword = ({
    password,
    passwordConfirmation,
    userToken,
    resetToken
}) => {
    const api = createHttpClient();

    const url = '/password/reset';

    const data = {
        user_token: userToken,
        reset_token: resetToken,
        password_confirmation: passwordConfirmation,
        password
    };

    return api.post(url, data);
}
import * as types from '../actions/types/hospital_events_types';
import { getStartEndOfWeek } from '../../helpers';

const { startWeek, endWeek } = getStartEndOfWeek(new Date());

const initialState = {
    events: [],
    calendarStartDate: startWeek,
    calendarEndDate: endWeek,
    selectedDateHour: null,
    closestEvent: null,
    fetchEventsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
}

const hospitalEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_HOSPITAL_EVENTS_STATE: {
            return { ...initialState }
        }
        case types.HOSPITAL_EVENTS_FETCH_EVENTS_REQUEST: {
            const {
                fetchEventsRequest
            } = state;

            return {
                ...state,
                fetchEventsRequest: {
                    ...fetchEventsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.HOSPITAL_EVENTS_FETCH_EVENTS_SUCCESS: {
            const {
                fetchEventsRequest
            } = state;

            return {
                ...state,
                events: action.payload.data.map(event => ({
                    start: event.start_date,
                    end: event.event_end_date,
                    duration: event.duration,
                    data: event
                })),
                fetchEventsRequest: {
                    ...fetchEventsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.HOSPITAL_EVENTS_FETCH_EVENTS_FAILURE: {
            const {
                fetchEventsRequest
            } = state;

            return {
                ...state,
                events: [],
                fetchEventsRequest: {
                    ...fetchEventsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.HOSPITAL_EVENTS_SET_CALENDAR_DATE: {
            return {
                ...state,
                events: [],
                calendarStartDate: action.payload.startWeek,
                calendarEndDate: action.payload.endWeek
            }
        }
        case types.HOSPITAL_EVENTS_SET_SELECTED_DATE_HOUR: {
            return {
                ...state,
                selectedDateHour: action.payload
            }
        }
        case types.HOSPITAL_EVENTS_SET_CLOSEST_EVENT: {
            return {
                ...state,
                closestEvent: action.payload
            }
        }
        default:
            return state;
    }
}

export default hospitalEventsReducer;
export default {
    firstName: '',
    lastName: '',
    gender: '',
    pesel: '',
    birthDate: null,
    email: '',
    emailConfirmation: '',
    phoneNumberCode: '+48',
    phoneNumber: '',
    status: 'none',
    kosStartDate: null,
    kosEndDate: null,
    hospital: null,
    education: {},
}
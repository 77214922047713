import React from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends React.Component {

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    createClassName() {
        const classNames = ['o-checkbox'];

        if (this.props.variant) {
            classNames.push(`o-checkbox--${this.props.variant}`);
        }

        return classNames.join(' ');
    }

    render() {
        return (
            <label className={this.createClassName()}
                onClick={(ev) => this.props.readOnly && ev.preventDefault()}
                onKeyDown={(ev) => this.props.readOnly && ev.preventDefault()}
            >
                <input
                    ref={this.inputRef}
                    type="checkbox"
                    className="o-checkbox__input"
                    checked={this.props.checked}
                    onChange={this.props.onChange || (() => { })}
                    disabled={this.props.disabled}
                    readOnly={this.props.readOnly}
                    value={this.props.value}
                    name={this.props.name}
                    id={this.props.id}
                    tabIndex="-1"
                />


                <div className="o-checkbox__wrapper">
                    <div className="o-checkbox__check"></div>
                </div>
            </label>
        )
    }
}

Checkbox.propTypes = {
    variant: PropTypes.oneOf(['success']),
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func
}
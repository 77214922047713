import React from 'react';
import PropTypes from 'prop-types';

export default class Label extends React.Component {

    createClassName() {
        let classNames = ['o-label'];

        const flags = [
            'left',
            'right',
            'center',
            'top-center'
        ];

        flags.forEach(flag => {
            if (this.props[flag]) {
                classNames.push(`o-label--${flag}`);
            }
        });

        if (this.props.className) {
            classNames = [
                ...classNames, 
                ...this.props.className.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    render() {
        return (
            <label className={this.createClassName()}>
                {this.props.children}
            </label>
        );
    }
}

Label.propTypes = {
    left: PropTypes.bool,
    right: PropTypes.bool,
    center: PropTypes.bool,
    'top-center': PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
}
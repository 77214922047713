export const PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_REQUEST = 'PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_REQUEST';
export const PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_SUCCESS = 'PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_SUCCESS';
export const PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_FAILURE = 'PATIENT_MEASUREMENTS_FETCH_MEASUREMENTS_FAILURE';

export const PATIENT_MEASUREMENTS_SET_MEASUREMENTS_TYPE = 'PATIENT_MEASUREMENTS_SET_MEASUREMENTS_TYPE';

export const PATIENT_MEASUREMENTS_SET_GRAPH_VIEW = 'PATIENT_MEASUREMENTS_SET_GRAPH_VIEW';

export const PATIENT_MEASUREMENTS_SET_GRAPH_DATE = 'PATIENT_MEASUREMENTS_SET_GRAPH_DATE';

export const PATIENT_MEASUREMENTS_SET_GRAPH_PERIOD = 'PATIENT_MEASUREMENTS_SET_GRAPH_PERIOD';

import React, { useState } from 'react';

import AutoComplete from '../common/AutoComplete';
import * as HospitalService from '../../services/hospital-service';

const HospitalAutoComplete = (props) => {

    const [suggestions, setSuggestions] = useState([]);

    const getSuggestions = async (query) => {
        let hospitals = await HospitalService.getHospitalSuggestions({
            perPage: 100000,
            query
        });

        // remove edited hospital from autoComplit hospitals list,
        // because hospital can't be a parent to itself
        if (props.hospitalId && props.name === 'parent') {
            hospitals = hospitals.filter(({ value }) => value !== props.hospitalId)
        }

        // remove hospitals with parent
        // we must to show only first level hospitals
        if (props.name === 'parent') {
            hospitals = hospitals.filter(({ parent }) => !parent);
        }

        if (props.insertBefore) {
            hospitals = [
                props.insertBefore,
                ...hospitals
            ]
        }

        return hospitals;
    };

    const loadSuggestions = async ({ query }) => {
        try {
            const suggestions = await getSuggestions(query);

            setSuggestions(suggestions);
        } catch (err) {
            setSuggestions([]);
        }

    };

    return (
        <AutoComplete
            field="label"
            suggestions={suggestions}
            completeMethod={loadSuggestions}
            {...props}
        />
    )
};

HospitalAutoComplete.defaultProps = {
    dropdown: false
}

export default HospitalAutoComplete;
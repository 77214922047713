export const APP_PARAMS_FETCH_ALL_EVENTS_REQUEST = 'APP_PARAMS_FETCH_ALL_EVENTS_REQUEST';
export const APP_PARAMS_FETCH_ALL_EVENTS_SUCCESS = 'APP_PARAMS_FETCH_ALL_EVENTS_SUCCESS';
export const APP_PARAMS_FETCH_ALL_EVENTS_FAILURE = 'APP_PARAMS_FETCH_ALL_EVENTS_FAILURE';

export const APP_PARAMS_ADD_EVENT_REQUEST = 'APP_PARAMS_ADD_EVENT_REQUEST';
export const APP_PARAMS_ADD_EVENT_SUCCESS = 'APP_PARAMS_ADD_EVENT_SUCCESS';
export const APP_PARAMS_ADD_EVENT_FAILURE = 'APP_PARAMS_ADD_EVENT_FAILURE';

export const APP_PARAMS_UPDATE_EVENT_REQUEST = 'APP_PARAMS_UPDATE_EVENT_REQUEST';
export const APP_PARAMS_UPDATE_EVENT_SUCCESS = 'APP_PARAMS_UPDATE_EVENT_SUCCESS';
export const APP_PARAMS_UPDATE_EVENT_FAILURE = 'APP_PARAMS_UPDATE_EVENT_FAILURE';

export const APP_PARAMS_REMOVE_EVENT_REQUEST = 'APP_PARAMS_REMOVE_EVENT_REQUEST';
export const APP_PARAMS_REMOVE_EVENT_SUCCESS = 'APP_PARAMS_REMOVE_EVENT_SUCCESS';
export const APP_PARAMS_REMOVE_EVENT_FAILURE = 'APP_PARAMS_REMOVE_EVENT_FAILURE';

export const APP_PARAMS_SET_EVENTS_SEARCH_QUERY = 'APP_PARAMS_SET_EVENTS_SEARCH_QUERY';

export const APP_PARAMS_SET_EVENT_FORM = 'APP_PARAMS_SET_FORM';

export const APP_PARAMS_FETCH_TREATMENT_PROGRAM_REQUEST = 'APP_PARAMS_FETCH_TREATMENT_PROGRAM_REQUEST';
export const APP_PARAMS_FETCH_TREATMENT_PROGRAM_SUCCESS = 'APP_PARAMS_FETCH_TREATMENT_PROGRAM_SUCCESS';
export const APP_PARAMS_FETCH_TREATMENT_PROGRAM_FAILURE = 'APP_PARAMS_FETCH_TREATMENT_PROGRAM_FAILURE';

export const APP_PARAMS_UPDATE_TREATMENT_PROGRAM_REQUEST = 'APP_PARAMS_UPDATE_TREATMENT_PROGRAM_REQUEST';
export const APP_PARAMS_UPDATE_TREATMENT_PROGRAM_SUCCESS = 'APP_PARAMS_UPDATE_TREATMENT_PROGRAM_SUCCESS';
export const APP_PARAMS_UPDATE_TREATMENT_PROGRAM_FAILURE = 'APP_PARAMS_UPDATE_TREATMENT_PROGRAM_FAILURE';

// stages
export const APP_PARAMS_FETCH_STAGES_REQUEST = 'APP_PARAMS_FETCH_STAGES_REQUEST';
export const APP_PARAMS_FETCH_STAGES_SUCCESS = 'APP_PARAMS_FETCH_STAGES_SUCCESS';
export const APP_PARAMS_FETCH_STAGES_FAILURE = 'APP_PARAMS_FETCH_STAGES_FAILURE';

export const STAGE_ADD_REQUEST = 'STAGE_ADD_REQUEST';
export const STAGE_ADD_SUCCESS = 'STAGE_ADD_SUCCESS';
export const STAGE_ADD_FAILURE = 'STAGE_ADD_FAILURE';
export const STAGE_ADD_RESET = 'STAGE_ADD_RESET';

export const STAGE_UPDATE_REQUEST = 'STAGE_UPDATE_REQUEST';
export const STAGE_UPDATE_SUCCESS = 'STAGE_UPDATE_SUCCESS';
export const STAGE_UPDATE_FAILURE = 'STAGE_UPDATE_FAILURE';
export const STAGE_UPDATE_RESET = 'STAGE_UPDATE_RESET';

// center types
export const APP_PARAMS_FETCH_CENTER_TYPES_REQUEST = 'APP_PARAMS_FETCH_CENTER_TYPES_REQUEST';
export const APP_PARAMS_FETCH_CENTER_TYPES_SUCCESS = 'APP_PARAMS_FETCH_CENTER_TYPES_SUCCESS';
export const APP_PARAMS_FETCH_CENTER_TYPES_FAILURE = 'APP_PARAMS_FETCH_CENTER_TYPES_FAILURE';

export const CENTER_TYPE_ADD_REQUEST = 'CENTER_TYPE_ADD_REQUEST';
export const CENTER_TYPE_ADD_SUCCESS = 'CENTER_TYPE_ADD_SUCCESS';
export const CENTER_TYPE_ADD_FAILURE = 'CENTER_TYPE_ADD_FAILURE';
export const CENTER_TYPE_ADD_RESET = 'CENTER_TYPE_ADD_RESET';

export const CENTER_TYPE_UPDATE_REQUEST = 'CENTER_TYPE_UPDATE_REQUEST';
export const CENTER_TYPE_UPDATE_SUCCESS = 'CENTER_TYPE_UPDATE_SUCCESS';
export const CENTER_TYPE_UPDATE_FAILURE = 'CENTER_TYPE_UPDATE_FAILURE';
export const CENTER_TYPE_UPDATE_RESET = 'CENTER_TYPE_UPDATE_RESET';

// interventions
export const APP_PARAMS_FETCH_INTERVENTIONS_REQUEST = 'APP_PARAMS_FETCH_INTERVENTIONS_REQUEST';
export const APP_PARAMS_FETCH_INTERVENTIONS_SUCCESS = 'APP_PARAMS_FETCH_INTERVENTIONS_SUCCESS';
export const APP_PARAMS_FETCH_INTERVENTIONS_FAILURE = 'APP_PARAMS_FETCH_INTERVENTIONS_FAILURE';

export const APP_PARAMS_ADD_INTERVENTION_REQUEST = 'APP_PARAMS_ADD_INTERVENTION_REQUEST';
export const APP_PARAMS_ADD_INTERVENTION_SUCCESS = 'APP_PARAMS_ADD_INTERVENTION_SUCCESS';
export const APP_PARAMS_ADD_INTERVENTION_FAILURE = 'APP_PARAMS_ADD_INTERVENTION_FAILURE';

export const APP_PARAMS_UPDATE_INTERVENTION_REQUEST = 'APP_PARAMS_UPDATE_INTERVENTION_REQUEST';
export const APP_PARAMS_UPDATE_INTERVENTION_SUCCESS = 'APP_PARAMS_UPDATE_INTERVENTION_SUCCESS';
export const APP_PARAMS_UPDATE_INTERVENTION_FAILURE = 'APP_PARAMS_UPDATE_INTERVENTION_FAILURE';

export const APP_PARAMS_REMOVE_INTERVENTION_REQUEST = 'APP_PARAMS_REMOVE_INTERVENTION_REQUEST';
export const APP_PARAMS_REMOVE_INTERVENTION_SUCCESS = 'APP_PARAMS_REMOVE_INTERVENTION_SUCCESS';
export const APP_PARAMS_REMOVE_INTERVENTION_FAILURE = 'APP_PARAMS_REMOVE_INTERVENTION_FAILURE';

export const APP_PARAMS_RESET_ALL_INTERVENTION_REQUESTS = 'APP_PARAMS_RESET_ALL_INTERVENTION_REQUESTS';

// types
export const APP_PARAMS_FETCH_TYPES_REQUEST = 'APP_PARAMS_FETCH_TYPES_REQUEST';
export const APP_PARAMS_FETCH_TYPES_SUCCESS = 'APP_PARAMS_FETCH_TYPES_SUCCESS';
export const APP_PARAMS_FETCH_TYPES_FAILURE = 'APP_PARAMS_FETCH_TYPES_FAILURE';

export const APP_PARAMS_ADD_TYPE_REQUEST = 'APP_PARAMS_ADD_TYPE_REQUEST';
export const APP_PARAMS_ADD_TYPE_SUCCESS = 'APP_PARAMS_ADD_TYPE_SUCCESS';
export const APP_PARAMS_ADD_TYPE_FAILURE = 'APP_PARAMS_ADD_TYPE_FAILURE';

export const APP_PARAMS_UPDATE_TYPE_REQUEST = 'APP_PARAMS_UPDATE_TYPE_REQUEST';
export const APP_PARAMS_UPDATE_TYPE_SUCCESS = 'APP_PARAMS_UPDATE_TYPE_SUCCESS';
export const APP_PARAMS_UPDATE_TYPE_FAILURE = 'APP_PARAMS_UPDATE_TYPE_FAILURE';

export const APP_PARAMS_REMOVE_TYPE_REQUEST = 'APP_PARAMS_REMOVE_TYPE_REQUEST';
export const APP_PARAMS_REMOVE_TYPE_SUCCESS = 'APP_PARAMS_REMOVE_TYPE_SUCCESS';
export const APP_PARAMS_REMOVE_TYPE_FAILURE = 'APP_PARAMS_REMOVE_TYPE_FAILURE';

export const APP_PARAMS_RESET_ALL_TYPE_REQUESTS = 'APP_PARAMS_RESET_ALL_TYPE_REQUESTS';
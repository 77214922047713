import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_FETCH_SELF_REQUEST,
    USER_FETCH_SELF_SUCCESS,
    USER_FETCH_SELF_FAILURE,
    USER_LOGOUT
} from '../actions/types';
import * as AuthService from '../../services/auth-service';

const initialToken = AuthService.getToken();

const initialState = {
    isAuthenticated: !!initialToken,
    isLoginRequestActive: false,
    accessToken: initialToken,
    data: null,
    isFetchSelfRequestActive: true,
    shouldRedirect: false,
    loginRequestError: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                isLoginRequestActive: true,
            }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoginRequestActive: false,
                isAuthenticated: true,
                accessToken: action.payload.user.access_token,
                data: action.payload.user,
                loginRequestError: null
            }
        case USER_LOGIN_FAILURE:
            return {
                ...state,
                isLoginRequestActive: false,
                isAuthenticated: false,
                accessToken: '',
                data: null,
                loginRequestError: action.payload
            }
        case USER_FETCH_SELF_REQUEST:
            return {
                ...state,
                isFetchSelfRequestActive: true
            }
        case USER_FETCH_SELF_SUCCESS:
            return {
                ...state,
                isFetchSelfRequestActive: false,
                isAuthenticated: true,
                accessToken: action.payload.user.access_token,
                data: action.payload.user
            }
        case USER_FETCH_SELF_FAILURE:
            return {
                ...state,
                isFetchSelfRequestActive: false,
                isAuthenticated: false,
                accessToken: '',
                data: null
            }
        case USER_LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: '',
                data: null
            }
        default:
            return state;
    }
}

export default userReducer;
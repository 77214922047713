export const EDUCATION_ADD_CHAPTER_REQUEST = 'EDUCATION_ADD_CHAPTER_REQUEST';
export const EDUCATION_ADD_CHAPTER_SUCCESS = 'EDUCATION_ADD_CHAPTER_SUCCESS';
export const EDUCATION_ADD_CHAPTER_FAILURE = 'EDUCATION_ADD_CHAPTER_FAILURE';

export const EDUCATION_UPDATE_CHAPTER_REQUEST = 'EDUCATION_UPDATE_CHAPTER_REQUEST';
export const EDUCATION_UPDATE_CHAPTER_SUCCESS = 'EDUCATION_UPDATE_CHAPTER_SUCCESS';
export const EDUCATION_UPDATE_CHAPTER_FAILURE = 'EDUCATION_UPDATE_CHAPTER_FAILURE';

export const EDUCATION_REMOVE_CHAPTER_REQUEST = 'EDUCATION_REMOVE_CHAPTER_REQUEST';
export const EDUCATION_REMOVE_CHAPTER_SUCCESS = 'EDUCATION_REMOVE_CHAPTER_SUCCESS';
export const EDUCATION_REMOVE_CHAPTER_FAILURE = 'EDUCATION_REMOVE_CHAPTER_FAILURE';

export const EDUCATION_DUPLICATE_CHAPTER_REQUEST = 'EDUCATION_DUPLICATE_CHAPTER_REQUEST';
export const EDUCATION_DUPLICATE_CHAPTER_SUCCESS = 'EDUCATION_DUPLICATE_CHAPTER_SUCCESS';
export const EDUCATION_DUPLICATE_CHAPTER_FAILURE = 'EDUCATION_DUPLICATE_CHAPTER_FAILURE';
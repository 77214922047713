export default {
    isEditFormVisible: false,
    recommendationTemplates: [],
    fetchAllRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    addRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
};
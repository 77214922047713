import {
    RECOMMENDATION_ADD_REQUEST,
    RECOMMENDATION_ADD_SUCCESS,
    RECOMMENDATION_ADD_FAILURE,
    RECOMMENDATION_ADD_RESET,
    RECOMMENDATION_UPDATE_REQUEST,
    RECOMMENDATION_UPDATE_SUCCESS,
    RECOMMENDATION_UPDATE_FAILURE,
    RECOMMENDATION_UPDATE_RESET,
    RECOMMENDATION_REMOVE_REQUEST,
    RECOMMENDATION_REMOVE_SUCCESS,
    RECOMMENDATION_REMOVE_FAILURE,
    RECOMMENDATION_REMOVE_RESET,
    RECOMMENDATION_FETCH_ALL_REQUEST,
    RECOMMENDATION_FETCH_ALL_SUCCESS,
    RECOMMENDATION_FETCH_ALL_FAILURE
} from '../actions/types/recommendation_types';

const initialState = {
    recommendations: [],
    fetchAllRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    addRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
};

const recommendationReducer = (state = initialState, action) => {
    switch(action.type) {
        case RECOMMENDATION_FETCH_ALL_REQUEST: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case RECOMMENDATION_FETCH_ALL_SUCCESS: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                recommendations: action.payload.data,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case RECOMMENDATION_FETCH_ALL_FAILURE: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                recommendations: [],
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case RECOMMENDATION_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case RECOMMENDATION_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case RECOMMENDATION_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case RECOMMENDATION_ADD_RESET: {
            return {
                ...state,
                addRequest: initialState.addRequest
            }
        }
        case RECOMMENDATION_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case RECOMMENDATION_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case RECOMMENDATION_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case RECOMMENDATION_UPDATE_RESET: {
            return {
                ...state,
                updateRequest: initialState.updateRequest
            }
        }
        case RECOMMENDATION_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case RECOMMENDATION_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case RECOMMENDATION_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case RECOMMENDATION_REMOVE_RESET: {
            return {
                ...state,
                removeRequest: initialState.removeRequest
            }
        }
        default: 
            return state;
    }
}

export default recommendationReducer;
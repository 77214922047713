
const toggleSortOrder = sortOrder => sortOrder === 'asc' ? 'desc' : 'asc';

const useSorting = ({
    currentSortField,
    currentSortOrder,
    onSort
}) => {
    const handleSort = ({ sortField }) => {
        let sortOrder = '';
        
        if (currentSortField === sortField) {
            sortOrder = toggleSortOrder(currentSortOrder);
        } else {
            sortOrder = 'asc';
        }

        onSort({
            sortOrder,
            sortField
        });
    };

    return {
        handleSort
    }
}

export default useSorting;
export const DISEASES_FETCH_ALL_REQUEST = 'DISEASES_FETCH_ALL_REQUEST';
export const DISEASES_FETCH_ALL_SUCCESS = 'DISEASES_FETCH_ALL_SUCCESS';
export const DISEASES_FETCH_ALL_FAILURE = 'DISEASES_FETCH_ALL_FAILURE';

export const DISEASES_ADD_REQUEST = 'DISEASES_ADD_REQUEST';
export const DISEASES_ADD_SUCCESS = 'DISEASES_ADD_SUCCESS';
export const DISEASES_ADD_FAILURE = 'DISEASES_ADD_FAILURE';

export const DISEASES_UPDATE_REQUEST = 'DISEASES_UPDATE_REQUEST';
export const DISEASES_UPDATE_SUCCESS = 'DISEASES_UPDATE_SUCCESS';
export const DISEASES_UPDATE_FAILURE = 'DISEASES_UPDATE_FAILURE';

export const DISEASES_ORDER_REQUEST = 'DISEASES_ORDER_REQUEST';
export const DISEASES_ORDER_SUCCESS = 'DISEASES_ORDER_SUCCESS';
export const DISEASES_ORDER_FAILURE = 'DISEASES_ORDER_FAILURE';
export const DISEASES_ORDER_RESET = 'DISEASES_ORDER_RESET';

export const DISEASES_REMOVE_REQUEST = 'DISEASES_REMOVE_REQUEST';
export const DISEASES_REMOVE_SUCCESS = 'DISEASES_REMOVE_SUCCESS';
export const DISEASES_REMOVE_FAILURE = 'DISEASES_REMOVE_FAILURE';

import React from 'react';
import PropTypes from 'prop-types';

export default class FormGroup extends React.Component {

    createClassName() {
        let classNames = ['o-form-group'];

        const flags = [
            'inline',
            'top-sm'
        ];

        flags.forEach(flag => {
            if (this.props[flag]) {
                classNames.push(`o-form-group--${flag}`);
            }
        });

        if (this.props.className) {
            classNames.push(this.props.className);
        }

        if (this.props.gap) {
            classNames.push(`o-form-group--gap-${this.props.gap}`);
        }

        return classNames.join(' ');
    }

    render() {
        return (
            <div
                className={this.createClassName()}
            >
                {this.props.children}
            </div>
        )
    }
}

FormGroup.propTypes = {
    inline: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
}
import * as types from '../actions/types/education_subchapter_types';
import educationSubchapterInitialState from "./helpers/education-subchapter-initial-state";

const initialState = educationSubchapterInitialState;

const educationSubchapterReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.EDUCATION_SUBCHAPTER_FETCH_REQUEST: {
            const {
                fetchSubchapterRequest
            } = state;

            return {
                ...state,
                fetchSubchapterRequest: {
                    ...fetchSubchapterRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_FETCH_SUCCESS: {
            const {
                fetchSubchapterRequest
            } = state;

            return {
                ...state,
                subchapter: action.payload,
                fetchSubchapterRequest: {
                    ...fetchSubchapterRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_FETCH_FAILURE: {
            const {
                fetchSubchapterRequest
            } = state;

            return {
                ...state,
                subchater: null,
                fetchSubchapterRequest: {
                    ...fetchSubchapterRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_SELECT_LESSON: {
            return {
                ...state,
                selectedLessonId: action.payload.lessonId
            }
        }
        case types.EDUCATION_SUBCHAPTER_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_ADD_LESSON_REQUEST: {
            const {
                addLessonRequest
            } = state;

            return {
                ...state,
                addLessonRequest: {
                    ...addLessonRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_ADD_LESSON_SUCCESS: {
            const {
                addLessonRequest
            } = state;

            return {
                ...state,
                addLessonRequest: {
                    ...addLessonRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_ADD_LESSON_FAILURE: {
            const {
                addLessonRequest
            } = state;

            return {
                ...state,
                addLessonRequest: {
                    ...addLessonRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_UPDATE_LESSON_REQUEST: {
            const {
                updateLessonRequest
            } = state;

            return {
                ...state,
                updateLessonRequest: {
                    ...updateLessonRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_UPDATE_LESSON_SUCCESS: {
            const {
                updateLessonRequest
            } = state;

            return {
                ...state,
                updateLessonRequest: {
                    ...updateLessonRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_UPDATE_LESSON_FAILURE: {
            const {
                updateLessonRequest
            } = state;

            return {
                ...state,
                updateLessonRequest: {
                    ...updateLessonRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_ORDER_LESSON_REQUEST: {
            const {
                orderLessonRequest
            } = state;

            return {
                ...state,
                orderLessonRequest: {
                    ...orderLessonRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_ORDER_LESSON_SUCCESS: {
            const {
                orderLessonRequest
            } = state;

            return {
                ...state,
                orderLessonRequest: {
                    ...orderLessonRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_ORDER_LESSON_FAILURE: {
            const {
                orderLessonRequest
            } = state;

            return {
                ...state,
                orderLessonRequest: {
                    ...orderLessonRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_ORDER_LESSON_RESET: {
            return {
                ...state,
                orderLessonRequest: initialState.orderLessonRequest
            }
        }
        case types.EDUCATION_SUBCHAPTER_REMOVE_LESSON_REQUEST: {
            const {
                removeLessonRequest
            } = state;

            return {
                ...state,
                removeLessonRequest: {
                    ...removeLessonRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_REMOVE_LESSON_SUCCESS: {
            const {
                removeLessonRequest
            } = state;

            return {
                ...state,
                removeLessonRequest: {
                    ...removeLessonRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBCHAPTER_REMOVE_LESSON_FAILURE: {
            const {
                removeLessonRequest
            } = state;

            return {
                ...state,
                removeLessonRequest: {
                    ...removeLessonRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        default:
            return state;
    }
}

export default educationSubchapterReducer;
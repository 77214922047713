import React from 'react';
import PropTypes from 'prop-types';

import DataTable from '../tables/DataTable';
import TableColumn from '../tables/TableColumn';
import useClosestEventsTableCore from './use-closest-events-table-core';


const ClosestEventsTable = (props) => {

    const {
        events,
        renderFunctions
    } = useClosestEventsTableCore(props);

    const createClassName = () => {
        const classNames = ['c-closest-events-table'];

        props.className && classNames.push(props.className);

        return classNames.join(' ');
    }

    return (
        <DataTable
            rows={events}
            scrollHeight={null}
            className={createClassName()}
        >
            {props.columns.map((item) => 
                <TableColumn
                    key={item.field}
                    field={item.field}
                    header={item.header}
                    renderBody={item.render ? (rowData) => renderFunctions[item.field](rowData, item.isLink) : null}
                    style={item.style || null}
                />    
            )}
            

        </DataTable>
    )
};

ClosestEventsTable.propTypes = {
    filters: PropTypes.shape({
        q: PropTypes.string,
    })
}

export default ClosestEventsTable;
import {
    PATIENT_ADD_MODAL_SHOW,
    PATIENT_ADD_MODAL_HIDE,
    PATIENT_EDIT_MODAL_SHOW,
    PATIENT_EDIT_MODAL_HIDE,
    PATIENT_ADD_REQUEST,
    PATIENT_ADD_SUCCESS,
    PATIENT_ADD_FAILURE,
    PATIENT_ADD_RESET,
    PATIENT_UPDATE_REQUEST,
    PATIENT_UPDATE_SUCCESS,
    PATIENT_UPDATE_FAILURE,
    PATIENT_UPDATE_RESET,
    PATIENT_REMOVE_REQUEST,
    PATIENT_REMOVE_SUCCESS,
    PATIENT_REMOVE_FAILURE,
    PATIENT_REMOVE_RESET,
    PATIENT_UPDATE_SETTINGS_REQUEST,
    PATIENT_UPDATE_SETTINGS_SUCCESS,
    PATIENT_UPDATE_SETTINGS_FAILURE
} from "./types";

import * as PatientService from '../../services/patient-service';

export const showPatientAddModal = () => {
    return {
        type: PATIENT_ADD_MODAL_SHOW
    }
}

export const hidePatientAddModal = () => {
    return {
        type: PATIENT_ADD_MODAL_HIDE
    }
}

export const showPatientEditModal = () => {
    return {
        type: PATIENT_EDIT_MODAL_SHOW
    }
}

export const hidePatientEditModal = () => {
    return {
        type: PATIENT_EDIT_MODAL_HIDE
    }
}

export const addPatient = (values, formActions) => async dispatch => {
    dispatch({
        type: PATIENT_ADD_REQUEST
    });

    try {
        const response = await PatientService.addPatient(values);

        dispatch({
            type: PATIENT_ADD_SUCCESS,
            payload: response.data
        })

        formActions.resetForm();
    } catch (err) {
        dispatch({
            type: PATIENT_ADD_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
};

export const resetAddPatientRequest = () => {
    return {
        type: PATIENT_ADD_RESET
    }
}

export const updatePatient = (values, patientId, formActions, onUpdate) => async dispatch => {
    dispatch({
        type: PATIENT_UPDATE_REQUEST
    });

    try {
        const response = await PatientService.updatePatient(values, patientId);

        dispatch({
            type: PATIENT_UPDATE_SUCCESS,
            payload: response.data
        })

        formActions.resetForm();

        onUpdate();
    } catch (err) {
        dispatch({
            type: PATIENT_UPDATE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
};

export const resetUpdatePatientRequest = () => {
    return {
        type: PATIENT_UPDATE_RESET
    }
}

export const removePatient = (patientId) => async dispatch => {
    dispatch({
        type: PATIENT_REMOVE_REQUEST
    })

    try {
        const response = await PatientService.removePatient(patientId);

        dispatch({
            type: PATIENT_REMOVE_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: PATIENT_REMOVE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetRemovePatientRequest = () => {
    return {
        type: PATIENT_REMOVE_RESET
    }
}


export const updateSettings = (patientId, settings) => async dispatch => {
    dispatch({
        type: PATIENT_UPDATE_SETTINGS_REQUEST
    });

    try {
        const response = await PatientService.updateSettings(settings, patientId);

        dispatch({
            type: PATIENT_UPDATE_SETTINGS_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: PATIENT_UPDATE_SETTINGS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}
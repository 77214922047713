import React from 'react';
import PropTypes from 'prop-types';

import { getDateFromPesel, isValidPesel } from 'helpers';

import Fieldset from '../../common/Fieldset';
import FormGroup from '../../common/FormGroup';
import Input from '../../common/Input';
import Label from '../../common/Label';
import DateInput from '../../common/DateInput';
import Grid from '../../common/Grid';
import GridItem from '../../common/GridItem';
import Select from '../../common/Select';
import PhoneNumberCodeSelect from '../../common/PhoneNumberCodeSelect';
import ControlErrors from '../../common/ControlErrors';
import genders from '../../../constants/genders';

const PatientFormPersonalDataFieldset = ({
	values,
	handleChange,
	handleBlur,
	setFieldValue,
	errors,
	frontErrors,
	touched,
	disabled,
}) => {
	const phoneErrors = [];

	errors['phone'] && phoneErrors.push(...errors['phone']);
	errors['phone_prefix'] && phoneErrors.push(...errors['phone_prefix']);

	const handlePeselChange = (ev) => {
		if (!errors['pesel'] && isValidPesel(ev.target.value)) {
			const date = getDateFromPesel(ev.target.value);

			setFieldValue('birthDate', date);
		} else {
			setFieldValue('birthDate', null);
		}
	};

	return (
		<Fieldset
			legend="Dane osobowe"
			bodyClassName="c-patient-add-modal__personal-data"
		>
			<FormGroup inline>
				<Label left className="c-patient-add-modal__label-col">
					Imię
				</Label>
				<div className="c-patient-add-modal__input-col">
					<Input
						variant="primary"
						value={values.firstName}
						onChange={handleChange}
						name="firstName"
						disabled={disabled}
					/>
					<ControlErrors errors={errors['first_name']} />
				</div>
			</FormGroup>

			<FormGroup inline>
				<Label left className="c-patient-add-modal__label-col">
					Nazwisko
				</Label>
				<div className="c-patient-add-modal__input-col">
					<Input
						variant="primary"
						value={values.lastName}
						onChange={handleChange}
						name="lastName"
						disabled={disabled}
					/>
					<ControlErrors errors={errors['last_name']} />
				</div>
			</FormGroup>

			<FormGroup inline>
				<Label left className="c-patient-add-modal__label-col">
					Płeć
				</Label>
				<div className="c-patient-add-modal__input-col">
					<Select
						value={values.gender}
						options={genders}
						onChange={handleChange}
						name="gender"
						disabled={disabled}
					/>
					<ControlErrors errors={errors['sex']} />
				</div>
			</FormGroup>

			<FormGroup inline>
				<Label left className="c-patient-add-modal__label-col">
					Pesel
				</Label>

				<div className="c-patient-add-modal__input-col">
					<Grid no-wrap>
						<GridItem col="12">
							<Input
								variant="primary"
								value={values.pesel}
								onChange={(e) => {
									handlePeselChange(e);
									handleChange(e);
								}}
								onBlur={handleBlur}
								name="pesel"
								disabled={disabled}
							/>
							{touched['pesel'] && (frontErrors['pesel'] || errors['pesel']) ? (
								<ControlErrors
									errors={frontErrors['pesel'] || errors['pesel']}
								></ControlErrors>
							) : null}
						</GridItem>
						<GridItem col="auto" className="u-flex-shrink-0 u-flex-center-y">
							<label className="o-label o-label--center">Data urodzenia</label>
						</GridItem>
						<GridItem col="12">
							<DateInput
								value={values.birthDate}
								onChange={handleChange}
								setFieldValue={setFieldValue}
								name="birthDate"
								disabled={disabled}
							/>
							<ControlErrors errors={errors['birth_date']} />
						</GridItem>
					</Grid>
				</div>
			</FormGroup>

			<FormGroup inline>
				<Label left className="c-patient-add-modal__label-col">
					E-mail
				</Label>
				<div className="c-patient-add-modal__input-col">
					<Input
						variant="primary"
						value={values.email}
						onChange={handleChange}
						name="email"
						disabled={disabled}
					/>
					<ControlErrors errors={errors['email']} />
				</div>
			</FormGroup>

			<FormGroup inline>
				<Label left className="c-patient-add-modal__label-col">
					Powtórz e-mail
				</Label>
				<div className="c-patient-add-modal__input-col">
					<Input
						variant="primary"
						value={values.emailConfirmation}
						onChange={handleChange}
						name="emailConfirmation"
						disabled={disabled}
					/>
					<ControlErrors errors={errors['email_confirmation']} />
				</div>
			</FormGroup>

			<FormGroup inline>
				<Label left className="c-patient-add-modal__label-col">
					Nr telefonu
				</Label>
				<div className="c-patient-add-modal__input-col">
					<Grid gap="8">
						<GridItem col="7">
							<PhoneNumberCodeSelect
								value={values.phoneNumberCode}
								onChange={handleChange}
								name="phoneNumberCode"
								disabled={disabled}
							/>
						</GridItem>
						<GridItem col="17">
							<Input
								variant="primary"
								value={values.phoneNumber}
								onChange={handleChange}
								name="phoneNumber"
								disabled={disabled}
							/>
						</GridItem>
					</Grid>
					<ControlErrors errors={phoneErrors} />
				</div>
			</FormGroup>
		</Fieldset>
	);
};

PatientFormPersonalDataFieldset.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		gender: PropTypes.oneOf(['', 'm', 'f']),
		pesel: PropTypes.string,
		birthDate: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.string,
		]),
		email: PropTypes.string,
		phoneNumberCode: PropTypes.string,
		phoneNumber: PropTypes.string,
	}),
	frontErrors: PropTypes.shape({
		firstName: PropTypes.array,
		lastName: PropTypes.array,
		gender: PropTypes.array,
		pesel: PropTypes.array,
		birthDate: PropTypes.array,
		email: PropTypes.array,
		phoneNumberCode: PropTypes.array,
		phoneNumber: PropTypes.array,
	}),
	touched: PropTypes.shape({
		firstName: PropTypes.bool,
		lastName: PropTypes.bool,
		gender: PropTypes.bool,
		pesel: PropTypes.bool,
		birthDate: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		email: PropTypes.bool,
		phoneNumberCode: PropTypes.bool,
		phoneNumber: PropTypes.bool,
	}),
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	errors: PropTypes.object,
};

export default PatientFormPersonalDataFieldset;

import * as types from '../actions/types/patient_surveys_types';

const initialState = {
    surveys: [],
    selectedSurveyId: null,
    selectedSurvey: null,
    fetchSurveysRequest: {
        isActive: false,
        isSuccess: false,
        error: null
    },
    fetchSelectedSurveyRequest: {
        isActive: false,
        isSuccess: false,
        error: null
    }
}

const patientSurveysReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.PATIENT_SURVEYS_FETCH_SURVEYS_REQUEST: {
            const {
                fetchSurveysRequest
            } = state;

            return {
                ...state,
                fetchSurveysRequest: {
                    ...fetchSurveysRequest,
                    isActive: true,
                    isSuccess: false,
                    error: null
                }
            }
        }
        case types.PATIENT_SURVEYS_FETCH_SURVEYS_SUCCESS: {
            const {
                fetchSurveysRequest
            } = state;

            return {
                ...state,
                surveys: action.payload.data,
                fetchSurveysRequest: {
                    ...fetchSurveysRequest,
                    isActive: false,
                    isSuccess: true,
                    error: null
                }
            }
        }
        case types.PATIENT_SURVEYS_FETCH_SURVEYS_FAILURE: {
            const {
                fetchSurveysRequest
            } = state;

            return {
                ...state,
                surveys: [],
                fetchSurveysRequest: {
                    ...fetchSurveysRequest,
                    isActive: false,
                    isSuccess: false,
                    error: action.payload
                }
            }
        }
        case types.PATIENT_SURVEYS_SELECT_SURVEY: {
            return {
                ...state,
                selectedSurveyId: action.payload
            }
        }
        default:
            return state;
    }
}

export default patientSurveysReducer;
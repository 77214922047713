import React from 'react';
import PropTypes from 'prop-types';

export default class Badge extends React.Component {
    
    createClassName() {
        const classNames = ['o-badge'];

        if (this.props.variant) {
            classNames.push(`o-badge--${this.props.variant}`);
        }

        if (this.props.size) {
            classNames.push(`o-badge--size-${this.props.size}`);
        }
        
        const flags = [
            'rounded'
        ];

        flags.forEach(flag => {
            if (this.props[flag]) {
                classNames.push(`o-badge--${flag}`);
            }
        });

        return classNames.join(' ');
    }

    render() {
        return (
            <span className={this.createClassName()}>
                <span>{this.props.text}</span>
            </span>
        )
    }
}

Badge.propTypes = {
    variant: PropTypes.oneOf(['success', 'info', 'secondary']),
    size: PropTypes.oneOf(['md']),
    rounded: PropTypes.bool
}
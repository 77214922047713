export const INTERVENTIONS_FETCH_ALL_REQUEST = 'INTERVENTIONS_FETCH_ALL_REQUEST';
export const INTERVENTIONS_FETCH_ALL_SUCCESS = 'INTERVENTIONS_FETCH_ALL_SUCCESS';
export const INTERVENTIONS_FETCH_ALL_FAILURE = 'INTERVENTIONS_FETCH_ALL_FAILURE';

export const INTERVENTIONS_ADD_REQUEST = 'INTERVENTIONS_ADD_REQUEST';
export const INTERVENTIONS_ADD_SUCCESS = 'INTERVENTIONS_ADD_SUCCESS';
export const INTERVENTIONS_ADD_FAILURE = 'INTERVENTIONS_ADD_FAILURE';

export const INTERVENTIONS_UPDATE_REQUEST = 'INTERVENTIONS_UPDATE_REQUEST';
export const INTERVENTIONS_UPDATE_SUCCESS = 'INTERVENTIONS_UPDATE_SUCCESS';
export const INTERVENTIONS_UPDATE_FAILURE = 'INTERVENTIONS_UPDATE_FAILURE';

export const INTERVENTIONS_ORDER_REQUEST = 'INTERVENTIONS_ORDER_REQUEST';
export const INTERVENTIONS_ORDER_SUCCESS = 'INTERVENTIONS_ORDER_SUCCESS';
export const INTERVENTIONS_ORDER_FAILURE = 'INTERVENTIONS_ORDER_FAILURE';
export const INTERVENTIONS_ORDER_RESET = 'INTERVENTIONS_ORDER_RESET';

export const INTERVENTIONS_REMOVE_REQUEST = 'INTERVENTIONS_REMOVE_REQUEST';
export const INTERVENTIONS_REMOVE_SUCCESS = 'INTERVENTIONS_REMOVE_SUCCESS';
export const INTERVENTIONS_REMOVE_FAILURE = 'INTERVENTIONS_REMOVE_FAILURE';
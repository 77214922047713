
const useInfiniteScrolling = ({
    scrollable,
    canLazyLoad,
    onLazyLoad
}) => {
    const handleInfiniteScroll = () => {
        if (scrollable && canLazyLoad()) {
            onLazyLoad();
        }
    };

    return {
        handleInfiniteScroll
    }
}


export default useInfiniteScrolling;
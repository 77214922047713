import * as types from '../actions/types/app_params_types';

import appParamsInitialState from './helpers/app-params-initial-state';

const initialState = appParamsInitialState

const appParamsReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.APP_PARAMS_SET_EVENTS_SEARCH_QUERY: {
            return {
                ...state,
                eventsSearchQuery: action.payload
            }
        }
        case types.APP_PARAMS_SET_EVENT_FORM: {
            return {
                ...state,
                eventForm: action.payload
            }
        }
        case types.APP_PARAMS_FETCH_ALL_EVENTS_REQUEST: {
            const {
                fetchAllEventsRequest
            } = state;

            return {
                ...state,
                fetchAllEventsRequest: {
                    ...fetchAllEventsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.APP_PARAMS_FETCH_ALL_EVENTS_SUCCESS: {
            const {
                fetchAllEventsRequest
            } = state;


            return {
                ...state,
                fetchAllEventsRequest: {
                    ...fetchAllEventsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                events: action.payload.data

            }
        }
        case types.APP_PARAMS_FETCH_ALL_EVENTS_FAILURE: {
            const {
                fetchAllEventsRequest
            } = state;

            return {
                ...state,
                fetchAllEventsRequest: {
                    ...fetchAllEventsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
                events: []
            }
        }
        case types.APP_PARAMS_ADD_EVENT_REQUEST: {
            const {
                addEventRequest
            } = state;

            return {
                ...state,
                addEventRequest: {
                    ...addEventRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.APP_PARAMS_ADD_EVENT_SUCCESS: {
            const {
                addEventRequest
            } = state;


            return {
                ...state,
                addEventRequest: {
                    ...addEventRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                },

            }
        }
        case types.APP_PARAMS_ADD_EVENT_FAILURE: {
            const {
                addEventRequest
            } = state;

            return {
                ...state,
                addEventRequest: {
                    ...addEventRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                },
            }
        }
        case types.APP_PARAMS_UPDATE_EVENT_REQUEST: {
            const {
                updateEventRequest
            } = state;

            return {
                ...state,
                updateEventRequest: {
                    ...updateEventRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.APP_PARAMS_UPDATE_EVENT_SUCCESS: {
            const {
                updateEventRequest
            } = state;


            return {
                ...state,
                updateEventRequest: {
                    ...updateEventRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                },

            }
        }
        case types.APP_PARAMS_UPDATE_EVENT_FAILURE: {
            const {
                updateEventRequest
            } = state;

            return {
                ...state,
                updateEventRequest: {
                    ...updateEventRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                },
            }
        }
        case types.APP_PARAMS_REMOVE_EVENT_REQUEST: {
            const {
                removeEventRequest
            } = state;

            return {
                ...state,
                removeEventRequest: {
                    ...removeEventRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.APP_PARAMS_REMOVE_EVENT_SUCCESS: {
            const {
                removeEventRequest
            } = state;


            return {
                ...state,
                removeEventRequest: {
                    ...removeEventRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },

            }
        }
        case types.APP_PARAMS_REMOVE_EVENT_FAILURE: {
            const {
                removeEventRequest
            } = state;

            return {
                ...state,
                removeEventRequest: {
                    ...removeEventRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                },
            }
        }
        case types.APP_PARAMS_FETCH_TREATMENT_PROGRAM_REQUEST: {
            const {
                fetchTreatmentProgramRequest
            } = state;

            return {
                ...state,
                fetchTreatmentProgramRequest: {
                    ...fetchTreatmentProgramRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.APP_PARAMS_FETCH_TREATMENT_PROGRAM_SUCCESS: {
            const {
                fetchTreatmentProgramRequest
            } = state;


            return {
                ...state,
                fetchTreatmentProgramRequest: {
                    ...fetchTreatmentProgramRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                },
                treatmentProgram: action.payload

            }
        }
        case types.APP_PARAMS_FETCH_TREATMENT_PROGRAM_FAILURE: {
            const {
                fetchTreatmentProgramRequest
            } = state;

            return {
                ...state,
                fetchTreatmentProgramRequest: {
                    ...fetchTreatmentProgramRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                },
                treatmentProgram: null
            }
        }
        case types.APP_PARAMS_UPDATE_TREATMENT_PROGRAM_REQUEST: {
            const {
                updateTreatmentProgramRequest
            } = state;

            return {
                ...state,
                updateTreatmentProgramRequest: {
                    ...updateTreatmentProgramRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.APP_PARAMS_UPDATE_TREATMENT_PROGRAM_SUCCESS: {
            const {
                updateTreatmentProgramRequest
            } = state;


            return {
                ...state,
                updateTreatmentProgramRequest: {
                    ...updateTreatmentProgramRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                },
                treatmentProgram: action.payload
            }
        }
        case types.APP_PARAMS_UPDATE_TREATMENT_PROGRAM_FAILURE: {
            const {
                updateTreatmentProgramRequest
            } = state;

            return {
                ...state,
                updateTreatmentProgramRequest: {
                    ...updateTreatmentProgramRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.APP_PARAMS_FETCH_STAGES_REQUEST: {
            const {
                stages: {
                    fetchRequest
                }
            } = state;

            return {
                ...state,
                stages: {
                    ...state.stages,
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_FETCH_STAGES_SUCCESS: {
            const {
                stages: {
                    fetchRequest
                }
            } = state;


            return {
                ...state,
                stages: {
                    ...state.stages,
                    data: action.payload,
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: false,
                        isSuccess: true,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_FETCH_STAGES_FAILURE: {
            const {
                stages: {
                    fetchRequest
                }
            } = state;

            return {
                ...state,
                stages: {
                    ...state.stages,
                    data: [],
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    },
                }
            }
        }
        case types.STAGE_ADD_REQUEST: {
            const {
                stages: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                stages: {
                    ...state.stages,
                    addRequest: {
                        ...addRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.STAGE_ADD_SUCCESS: {
            const {
                stages: {
                    addRequest
                }
            } = state;


            return {
                ...state,
                stages: {
                    ...state.stages,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: true,
                        data: action.payload,
                        error: null
                    },
                }
            }
        }
        case types.STAGE_ADD_FAILURE: {
            const {
                stages: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                stages: {
                    ...state.stages,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    },
                }
            }
        }
        case types.STAGE_ADD_RESET: {
            return {
                ...state,
                stages: {
                    ...state.stages,
                    addRequest: initialState.stages.addRequest
                }
            }
        }
        case types.STAGE_UPDATE_REQUEST: {
            const {
                stages: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                stages: {
                    ...state.stages,
                    updateRequest: {
                        ...updateRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.STAGE_UPDATE_SUCCESS: {
            const {
                stages: {
                    updateRequest
                }
            } = state;


            return {
                ...state,
                stages: {
                    ...state.stages,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: true,
                        data: action.payload,
                        error: null
                    }
                }
            }
        }
        case types.STAGE_UPDATE_FAILURE: {
            const {
                stages: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                stages: {
                    ...state.stages,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    }
                }
            }
        }
        case types.STAGE_UPDATE_RESET: {
            return {
                ...state,
                stages: {
                    ...state.stages,
                    updateRequest: initialState.stages.updateRequest
                }
            }
        }
        case types.APP_PARAMS_FETCH_CENTER_TYPES_REQUEST: {
            const {
                centerTypes: {
                    fetchRequest
                }
            } = state;

            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_FETCH_CENTER_TYPES_SUCCESS: {
            const {
                centerTypes: {
                    fetchRequest
                }
            } = state;


            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    data: action.payload,
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: false,
                        isSuccess: true,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_FETCH_CENTER_TYPES_FAILURE: {
            const {
                centerTypes: {
                    fetchRequest
                }
            } = state;

            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    data: [],
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    },
                }
            }
        }
        case types.CENTER_TYPE_ADD_REQUEST: {
            const {
                centerTypes: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    addRequest: {
                        ...addRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.CENTER_TYPE_ADD_SUCCESS: {
            const {
                centerTypes: {
                    addRequest
                }
            } = state;


            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: true,
                        data: action.payload,
                        error: null
                    },
                }
            }
        }
        case types.CENTER_TYPE_ADD_FAILURE: {
            const {
                centerTypes: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    },
                }
            }
        }
        case types.CENTER_TYPE_ADD_RESET: {
            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    addRequest: initialState.centerTypes.addRequest
                }
            }
        }
        case types.CENTER_TYPE_UPDATE_REQUEST: {
            const {
                centerTypes: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    updateRequest: {
                        ...updateRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.CENTER_TYPE_UPDATE_SUCCESS: {
            const {
                centerTypes: {
                    updateRequest
                }
            } = state;


            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: true,
                        data: action.payload,
                        error: null
                    }
                }
            }
        }
        case types.CENTER_TYPE_UPDATE_FAILURE: {
            const {
                centerTypes: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    }
                }
            }
        }
        case types.CENTER_TYPE_UPDATE_RESET: {
            return {
                ...state,
                centerTypes: {
                    ...state.centerTypes,
                    updateRequest: initialState.centerTypes.updateRequest
                }
            }
        }
        case types.APP_PARAMS_ADD_INTERVENTION_REQUEST: {
            const {
                interventions: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    addRequest: {
                        ...addRequest,
                        isActive: true,
                        isSuccess: false,
                        isError: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_ADD_INTERVENTION_SUCCESS: {
            const {
                interventions: {
                    addRequest
                }
            } = state;


            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: true,
                        isError: false,
                        data: action.payload,
                        error: null
                    },
                }
            }
        }
        case types.APP_PARAMS_ADD_INTERVENTION_FAILURE: {
            const {
                interventions: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: false,
                        isError: true,
                        data: null,
                        error: action.payload
                    },
                }
            }
        }
        case types.APP_PARAMS_UPDATE_INTERVENTION_REQUEST: {
            const {
                interventions: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    updateRequest: {
                        ...updateRequest,
                        isActive: true,
                        isSuccess: false,
                        isError: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_UPDATE_INTERVENTION_SUCCESS: {
            const {
                interventions: {
                    updateRequest
                }
            } = state;


            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: true,
                        isError: false,
                        data: action.payload,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_UPDATE_INTERVENTION_FAILURE: {
            const {
                interventions: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: false,
                        isError: true,
                        data: null,
                        error: action.payload
                    }
                }
            }
        }
        case types.APP_PARAMS_REMOVE_INTERVENTION_REQUEST: {
            const {
                interventions: {
                    removeRequest
                }
            } = state;

            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    removeRequest: {
                        ...removeRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_REMOVE_INTERVENTION_SUCCESS: {
            const {
                interventions: {
                    removeRequest
                }
            } = state;


            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    removeRequest: {
                        ...removeRequest,
                        isActive: false,
                        isSuccess: true,
                        data: action.payload,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_REMOVE_INTERVENTION_FAILURE: {
            const {
                interventions: {
                    removeRequest
                }
            } = state;

            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    removeRequest: {
                        ...removeRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    }
                }
            }
        }
        case types.APP_PARAMS_RESET_ALL_INTERVENTION_REQUESTS: {
            const {
                interventions: {
                    addRequest,
                    updateRequest,
                    removeRequest
                }
            } = initialState;

            return {
                ...state,
                interventions: {
                    ...state.interventions,
                    addRequest,
                    updateRequest,
                    removeRequest
                }
            }
        }

        // types
        case types.APP_PARAMS_FETCH_TYPES_REQUEST: {
            const {
                types: {
                    fetchRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    types: {
                        ...fetchRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_FETCH_TYPES_SUCCESS: {
            const {
                types: {
                    fetchRequest
                }
            } = state;


            return {
                ...state,
                types: {
                    ...state.types,
                    data: action.payload,
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: false,
                        isSuccess: true,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_FETCH_TYPES_FAILURE: {
            const {
                types: {
                    fetchRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    data: null,
                    fetchRequest: {
                        ...fetchRequest,
                        isActive: false,
                        isSuccess: false,
                        isError: true,
                        data: null,
                        error: action.payload
                    },
                }
            }
        }
        case types.APP_PARAMS_ADD_TYPE_REQUEST: {
            const {
                types: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    addRequest: {
                        ...addRequest,
                        isActive: true,
                        isSuccess: false,
                        isError: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_ADD_TYPE_SUCCESS: {
            const {
                types: {
                    addRequest
                }
            } = state;


            return {
                ...state,
                types: {
                    ...state.types,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: true,
                        isError: false,
                        data: action.payload,
                        error: null
                    },
                }
            }
        }
        case types.APP_PARAMS_ADD_TYPE_FAILURE: {
            const {
                types: {
                    addRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    addRequest: {
                        ...addRequest,
                        isActive: false,
                        isSuccess: false,
                        isError: true,
                        data: null,
                        error: action.payload
                    },
                }
            }
        }
        case types.APP_PARAMS_UPDATE_TYPE_REQUEST: {
            const {
                types: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    updateRequest: {
                        ...updateRequest,
                        isActive: true,
                        isSuccess: false,
                        isError: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_UPDATE_TYPE_SUCCESS: {
            const {
                types: {
                    updateRequest
                }
            } = state;


            return {
                ...state,
                types: {
                    ...state.types,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: true,
                        isError: false,
                        data: action.payload,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_UPDATE_TYPE_FAILURE: {
            const {
                types: {
                    updateRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    updateRequest: {
                        ...updateRequest,
                        isActive: false,
                        isSuccess: false,
                        isError: true,
                        data: null,
                        error: action.payload
                    }
                }
            }
        }
        case types.APP_PARAMS_REMOVE_TYPE_REQUEST: {
            const {
                types: {
                    removeRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    removeRequest: {
                        ...removeRequest,
                        isActive: true,
                        isSuccess: false,
                        data: null,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_REMOVE_TYPE_SUCCESS: {
            const {
                types: {
                    removeRequest
                }
            } = state;


            return {
                ...state,
                types: {
                    ...state.types,
                    removeRequest: {
                        ...removeRequest,
                        isActive: false,
                        isSuccess: true,
                        data: action.payload,
                        error: null
                    }
                }
            }
        }
        case types.APP_PARAMS_REMOVE_TYPE_FAILURE: {
            const {
                types: {
                    removeRequest
                }
            } = state;

            return {
                ...state,
                types: {
                    ...state.types,
                    removeRequest: {
                        ...removeRequest,
                        isActive: false,
                        isSuccess: false,
                        data: null,
                        error: action.payload
                    }
                }
            }
        }
        case types.APP_PARAMS_RESET_ALL_TYPE_REQUESTS: {
            const {
                types: {
                    addRequest,
                    updateRequest,
                    removeRequest
                }
            } = initialState;

            return {
                ...state,
                types: {
                    ...state.types,
                    addRequest,
                    updateRequest,
                    removeRequest
                }
            }
        }
                
          
        
        default: 
            return state;
    }
}

export default appParamsReducer
import React from 'react';
import PropTypes from 'prop-types';

import { gridBreakpoints } from '../../config';

export default class GridItem extends React.Component {

    createClassName() {
        let classNames = ['o-grid__item'];

        gridBreakpoints.forEach(bp => {
            const propName = bp ? `col-${bp}` : 'col';
            const propValue = this.props[propName];

            if (propValue) {
                classNames.push(
                    `o-grid__item--${propValue}${bp ? `@${bp}` : ''}`
                );
            }
        });

        if (this.props.className) {
            classNames = [
                ...classNames, 
                ...this.props.className.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    render() {
        return (
            <div className={this.createClassName()}>{this.props.children}</div>
        );
    }
}

GridItem.propTypes = {
    'col': PropTypes.string,
    'col-xs': PropTypes.string,
    'col-sm': PropTypes.string,
    'col-md': PropTypes.string,
    'col-lg': PropTypes.string,
    'col-xlg': PropTypes.string,
    'className': PropTypes.string,
    'children': PropTypes.node
}
import * as types from "../actions/types/hospital_types";

const initialState = {
    addRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

const hospitalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.HOSPITAL_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.HOSPITAL_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.HOSPITAL_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.HOSPITAL_ADD_RESET: {
            return {
                ...state,
                addRequest: initialState.addRequest
            }
        }
        case types.HOSPITAL_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.HOSPITAL_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.HOSPITAL_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.HOSPITAL_UPDATE_RESET: {
            return {
                ...state,
                updateRequest: initialState.updateRequest
            }
        }

        case types.HOSPITAL_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.HOSPITAL_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.HOSPITAL_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.HOSPITAL_REMOVE_RESET: {
            return {
                ...state,
                removeRequest: initialState.removeRequest
            }
        }
        default:
            return state;
    }
}

export default hospitalReducer;
export const EDUCATION_SUBJECTS_FETCH_SUBJECTS_REQUEST = 'EDUCATION_SUBJECTS_FETCH_SUBJECTS_REQUEST';
export const EDUCATION_SUBJECTS_FETCH_SUBJECTS_SUCCESS = 'EDUCATION_SUBJECTS_FETCH_SUBJECTS_SUCCESS';
export const EDUCATION_SUBJECTS_FETCH_SUBJECTS_FAILURE = 'EDUCATION_SUBJECTS_FETCH_SUBJECTS_FAILURE';

export const EDUCATION_SUBJECTS_ADD_REQUEST = 'EDUCATION_SUBJECTS_ADD_REQUEST';
export const EDUCATION_SUBJECTS_ADD_SUCCESS = 'EDUCATION_SUBJECTS_ADD_SUCCESS';
export const EDUCATION_SUBJECTS_ADD_FAILURE = 'EDUCATION_SUBJECTS_ADD_FAILURE';

export const EDUCATION_SUBJECTS_UPDATE_REQUEST = 'EDUCATION_SUBJECTS_UPDATE_REQUEST';
export const EDUCATION_SUBJECTS_UPDATE_SUCCESS = 'EDUCATION_SUBJECTS_UPDATE_SUCCESS';
export const EDUCATION_SUBJECTS_UPDATE_FAILURE = 'EDUCATION_SUBJECTS_UPDATE_FAILURE';

export const EDUCATION_SUBJECTS_REMOVE_REQUEST = 'EDUCATION_SUBJECTS_REMOVE_REQUEST';
export const EDUCATION_SUBJECTS_REMOVE_SUCCESS = 'EDUCATION_SUBJECTS_REMOVE_SUCCESS';
export const EDUCATION_SUBJECTS_REMOVE_FAILURE = 'EDUCATION_SUBJECTS_REMOVE_FAILURE';
export default {
    interventions: [],
    fetchAllRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
    addRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
    orderRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
    removeRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
}
export const CLEAR_PATIENT_EVENTS_STATE = 'CLEAR_PATIENT_EVENTS_STATE';

export const PATIENT_EVENTS_FETCH_EVENTS_REQUEST = 'PATIENT_EVENTS_FETCH_EVENTS_REQUEST';
export const PATIENT_EVENTS_FETCH_EVENTS_SUCCESS = 'PATIENT_EVENTS_FETCH_EVENTS_SUCCESS';
export const PATIENT_EVENTS_FETCH_EVENTS_FAILURE = 'PATIENT_EVENTS_FETCH_EVENTS_FAILURE';

export const PATIENT_EVENTS_SET_SELECTED_DATE = 'PATIENT_EVENTS_SET_SELECTED_DATE';

export const PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_REQUEST = 'PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_REQUEST';
export const PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_SUCCESS = 'PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_SUCCESS';
export const PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_FAILURE = 'PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_FAILURE';

export const PATIENT_EVENTS_SET_SELECTED_EVENT = 'PATIENT_EVENTS_SET_SELECTED_EVENT';

export const PATIENT_EVENTS_SET_MANAGEMENT_MODE = 'PATIENT_EVENTS_SET_MANAGEMENT_MODE';

export const PATIENT_EVENTS_SET_CALENDAR_MODE = 'PATIENT_EVENTS_SET_CALENDAR_MODE';

export const PATIENT_EVENTS_ADD_EVENT_REQUEST = 'PATIENT_EVENTS_ADD_EVENT_REQUEST';
export const PATIENT_EVENTS_ADD_EVENT_SUCCESS = 'PATIENT_EVENTS_ADD_EVENT_SUCCESS';
export const PATIENT_EVENTS_ADD_EVENT_FAILURE = 'PATIENT_EVENTS_ADD_EVENT_FAILURE';
export const PATIENT_EVENTS_ADD_EVENT_RESET = 'PATIENT_EVENTS_ADD_EVENT_RESET';

export const PATIENT_EVENTS_REMOVE_EVENT_REQUEST = 'PATIENT_EVENTS_REMOVE_EVENT_REQUEST';
export const PATIENT_EVENTS_REMOVE_EVENT_SUCCESS = 'PATIENT_EVENTS_REMOVE_EVENT_SUCCESS';
export const PATIENT_EVENTS_REMOVE_EVENT_FAILURE = 'PATIENT_EVENTS_REMOVE_EVENT_FAILURE';
export const PATIENT_EVENTS_REMOVE_EVENT_RESET = 'PATIENT_EVENTS_REMOVE_EVENT_RESET';

export const PATIENT_EVENTS_UPDATE_EVENT_REQUEST = 'PATIENT_EVENTS_UPDATE_EVENT_REQUEST';
export const PATIENT_EVENTS_UPDATE_EVENT_SUCCESS = 'PATIENT_EVENTS_UPDATE_EVENT_SUCCESS';
export const PATIENT_EVENTS_UPDATE_EVENT_FAILURE = 'PATIENT_EVENTS_UPDATE_EVENT_FAILURE';
export const PATIENT_EVENTS_UPDATE_EVENT_RESET = 'PATIENT_EVENTS_UPDATE_EVENT_RESET';

export const PATIENT_EVENTS_ADD_EVENT_OBSERVATION_REQUEST = 'PATIENT_EVENTS_ADD_EVENT_OBSERVATION_REQUEST';
export const PATIENT_EVENTS_ADD_EVENT_OBSERVATION_SUCCESS = 'PATIENT_EVENTS_ADD_EVENT_OBSERVATION_SUCCESS';
export const PATIENT_EVENTS_ADD_EVENT_OBSERVATION_FAILURE = 'PATIENT_EVENTS_ADD_EVENT_OBSERVATION_FAILURE';
export const PATIENT_EVENTS_ADD_EVENT_OBSERVATION_RESET = 'PATIENT_EVENTS_ADD_EVENT_OBSERVATION_RESET';

export const EVENT_REMOVE_MODAL_SHOW = 'EVENT_REMOVE_MODAL_SHOW';
export const EVENT_REMOVE_MODAL_HIDE = 'EVENT_REMOVE_MODAL_HIDE';

export const PATIENT_EVENTS_SET_CALENDAR_DATE = 'PATIENT_EVENTS_SET_CALENDAR_DATE';

export const PATIENT_EVENTS_SET_EVENT_CREATE_DATE = 'PATIENT_EVENTS_SET_EVENT_CREATE_DATE';

export const PATIENT_EVENTS_REJECT_PRESENCE_EVENT_REQUEST = 'PATIENT_EVENTS_REJECT_PRESENCE_EVENT_REQUEST';
export const PATIENT_EVENTS_REJECT_PRESENCE_EVENT_SUCCESS = 'PATIENT_EVENTS_REJECT_PRESENCE_EVENT_SUCCESS';
export const PATIENT_EVENTS_REJECT_PRESENCE_EVENT_FAILURE = 'PATIENT_EVENTS_REJECT_PRESENCE_EVENT_FAILURE';
export const PATIENT_EVENTS_REJECT_PRESENCE_EVENT_RESET = 'PATIENT_EVENTS_REJECT_PRESENCE_EVENT_RESET';

export const PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_REQUEST = 'PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_REQUEST';
export const PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_SUCCESS = 'PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_SUCCESS';
export const PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_FAILURE = 'PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_FAILURE';
export const PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_RESET = 'PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_RESET';
import { createHttpClient } from './api-service';
import Axios from 'axios';

export const uploadMedia = (media) => {
    const api = createHttpClient();

    const url = `/media/upload`;

    const data = new FormData();

    data.append('media', media);

    return api.post(url, data);
}

export const downloadImage = async (src) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.get(src, {
                responseType: 'blob'
            });
            
            resolve(response);
        } catch (err) {
            reject(err);
        }
    });
}

export const convertImage = (imageBlob, format, fileName) => {
    return new Promise((resolve, reject) => {
        const img = document.createElement('img');

        img.onload = () => {
            const canvas = document.createElement('canvas');
            
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            
            ctx.drawImage(img, 0, 0);

            return canvas.toBlob((blob) => {
                resolve(blob);
            }, format, 1);
        };

        img.onerror = (err) => {
            reject(err);
        }
        
        img.src = URL.createObjectURL(imageBlob);
    });
} 


export const downloadApiImageDataUrl = async (src) => {
    const api = createHttpClient();

    return new Promise(async (resolve, reject) => {

        try {
            const response = await api.get(src, {
                responseType: 'blob'
            });
            
            const reader = new FileReader();
        
            reader.onload = (ev) => {
                const result = ev.target.result;
            
                resolve(result);
            };
        
            reader.onerror = (err) => {
                reject(err);
            };
        
            reader.readAsDataURL(response.data);
        } catch (err) {
            reject(err);
        }
    });
}
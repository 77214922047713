export const PATIENT_ADD_MODAL_SHOW = 'PATIENT_ADD_MODAL_SHOW';
export const PATIENT_ADD_MODAL_HIDE = 'PATIENT_ADD_MODAL_HIDE';

export const PATIENT_EDIT_MODAL_SHOW = 'PATIENT_EDIT_MODAL_SHOW';
export const PATIENT_EDIT_MODAL_HIDE = 'PATIENT_EDIT_MODAL_HIDE';

export const PATIENT_ADD_REQUEST = 'PATIENT_ADD_REQUEST';
export const PATIENT_ADD_SUCCESS = 'PATIENT_ADD_SUCCESS';
export const PATIENT_ADD_FAILURE = 'PATIENT_ADD_FAILURE';
export const PATIENT_ADD_RESET = 'PATIENT_ADD_RESET';

export const PATIENT_UPDATE_REQUEST = 'PATIENT_UPDATE_REQUEST';
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS';
export const PATIENT_UPDATE_FAILURE = 'PATIENT_UPDATE_FAILURE';
export const PATIENT_UPDATE_RESET = 'PATIENT_UPDATE_RESET';

export const PATIENT_REMOVE_REQUEST = 'PATIENT_REMOVE_REQUEST';
export const PATIENT_REMOVE_SUCCESS = 'PATIENT_REMOVE_SUCCESS';
export const PATIENT_REMOVE_FAILURE = 'PATIENT_REMOVE_FAILURE';
export const PATIENT_REMOVE_RESET = 'PATIENT_REMOVE_RESET';

export const PATIENT_UPDATE_SETTINGS_REQUEST = 'PATIENT_UPDATE_SETTINGS_REQUEST';
export const PATIENT_UPDATE_SETTINGS_SUCCESS = 'PATIENT_UPDATE_SETTINGS_SUCCESS';
export const PATIENT_UPDATE_SETTINGS_FAILURE = 'PATIENT_UPDATE_SETTINGS_FAILURE';
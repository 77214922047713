import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { initialFilters, columnsModal } from './helpers';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Grid from '../../common/Grid';
import GridItem from '../../common/GridItem';
import ClosestEventsTable from '../../closest-events/ClosestEventsTable';

const ClosestEventsModal = () => {
    const location = useLocation();

    const user = useSelector((store) => store.user);
    const isAuthenticated = user.isAuthenticated;

    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false)
    }

    useEffect(() => {
		if (isAuthenticated) {
            if (
                user.data.role === 'doctor'
                || user.data.role === 'physiotherapist'
            ) {
                const closestEventsShowed = Cookies.get(`closestEventsShowed${user.data.id}`);

                if (!closestEventsShowed) {
                    setIsOpen(true);
                    
                    const date = new Date();
                    date.setHours(23, 59, 59);
                    
                    Cookies.set(`closestEventsShowed${user.data.id}`, true, { expires: date });
                }
            }
        }
        // eslint-disable-next-line
	}, [location])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <div className="o-modal c-closest-events-modal">
                <div className="o-modal__header">Dzisiejsze wydarzenia</div>
                <div className="o-modal__body">
                    <ClosestEventsTable
                        filters={initialFilters}
                        columns={columnsModal}
                        className="c-closest-events-table--in-modal"
                    />
                </div>
                <div className="o-modal__footer c-patient-add-modal__footer">
                    <Grid justify-end gap="8">
                        <GridItem col="auto">
                            <Button
                                variant="light"
                                title="Zamknij"
                                onClick={handleClose}
                            />
                        </GridItem>
                    </Grid>
                </div>
            </div>
        </Modal>
    );
};

export default ClosestEventsModal;
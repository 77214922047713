export const EDUCATION_MESSAGES_ADD_REQUEST = 'EDUCATIONS_MESSAGES_ADD_REQUEST';
export const EDUCATION_MESSAGES_ADD_SUCCESS = 'EDUCATIONS_MESSAGES_ADD_SUCCESS';
export const EDUCATION_MESSAGES_ADD_FAILURE = 'EDUCATIONS_MESSAGES_ADD_FAILURE';

export const EDUCATION_MESSAGES_UPDATE_REQUEST = 'EDUCATIONS_MESSAGES_UPDATE_REQUEST';
export const EDUCATION_MESSAGES_UPDATE_SUCCESS = 'EDUCATIONS_MESSAGES_UPDATE_SUCCESS';
export const EDUCATION_MESSAGES_UPDATE_FAILURE = 'EDUCATIONS_MESSAGES_UPDATE_FAILURE';

export const EDUCATION_MESSAGES_REMOVE_REQUEST = 'EDUCATIONS_MESSAGES_REMOVE_REQUEST';
export const EDUCATION_MESSAGES_REMOVE_SUCCESS = 'EDUCATIONS_MESSAGES_REMOVE_SUCCESS';
export const EDUCATION_MESSAGES_REMOVE_FAILURE = 'EDUCATIONS_MESSAGES_REMOVE_FAILURE';

export const EDUCATION_MESSAGES_SEND_REQUEST = 'EDUCATIONS_MESSAGES_SEND_REQUEST';
export const EDUCATION_MESSAGES_SEND_SUCCESS = 'EDUCATIONS_MESSAGES_SEND_SUCCESS';
export const EDUCATION_MESSAGES_SEND_FAILURE = 'EDUCATIONS_MESSAGES_SEND_FAILURE';

export const EDUCATION_MESSAGES_SET_ADD_FORM = 'EDUCATION_MESSAGES_SET_ADD_FORM';

export const EDUCATION_MESSAGES_SET_EDIT_FORM = 'EDUCATION_MESSAGES_SET_EDIT_FORM';
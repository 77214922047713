import React from 'react';
import PropTypes from 'prop-types';

const DataTableBodyCell = ({ row, col }) => {
    return (
        <th 
            className="o-datatable__cell" 
            key={col.props.field}
            style={col.props.style}
        >
            {col.props.renderBody 
                ? col.props.renderBody(row)
                : row[col.props.field]
            }
        </th>
    )
}

DataTableBodyCell.propTypes = {
    row: PropTypes.object,
    col: PropTypes.node
}

export default DataTableBodyCell;
import PropTypes from 'prop-types';

const TableColumn = () => {};

TableColumn.propTypes = {
    field: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    renderBody: PropTypes.func,
    style: PropTypes.object
};

export default TableColumn;
export const PATIENT_CARD_FETCH_PATIENT_DATA_REQUEST = 'PATIENT_CARD_FETCH_PATIENT_DATA_REQUEST';
export const PATIENT_CARD_FETCH_PATIENT_DATA_SUCCESS = 'PATIENT_CARD_FETCH_PATIENT_DATA_SUCCESS';
export const PATIENT_CARD_FETCH_PATIENT_DATA_FAILURE = 'PATIENT_CARD_FETCH_PATIENT_DATA_FAILURE';

export const PATIENT_CARD_FETCH_PATIENT_BONUS_REQUEST = 'PATIENT_CARD_FETCH_PATIENT_BONUS_REQUEST';
export const PATIENT_CARD_FETCH_PATIENT_BONUS_SUCCESS = 'PATIENT_CARD_FETCH_PATIENT_BONUS_SUCCESS';
export const PATIENT_CARD_FETCH_PATIENT_BONUS_FAILURE = 'PATIENT_CARD_FETCH_PATIENT_BONUS_FAILURE';

export const PATIENT_CARD_UPDATE_PERSONAL_DATA_REQUEST = 'PATIENT_CARD_UPDATE_PERSONAL_DATA_REQUEST';
export const PATIENT_CARD_UPDATE_PERSONAL_DATA_SUCCESS = 'PATIENT_CARD_UPDATE_PERSONAL_DATA_SUCCESS';
export const PATIENT_CARD_UPDATE_PERSONAL_DATA_FAILURE = 'PATIENT_CARD_UPDATE_PERSONAL_DATA_FAILURE';
export const PATIENT_CARD_UPDATE_PERSONAL_DATA_RESET = 'PATIENT_CARD_UPDATE_PERSONAL_DATA_RESET';

export const PATIENT_CARD_UPDATE_STAGE_DATA_REQUEST = 'PATIENT_CARD_UPDATE_STAGE_DATA_REQUEST';
export const PATIENT_CARD_UPDATE_STAGE_DATA_SUCCESS = 'PATIENT_CARD_UPDATE_STAGE_DATA_SUCCESS';
export const PATIENT_CARD_UPDATE_STAGE_DATA_FAILURE = 'PATIENT_CARD_UPDATE_STAGE_DATA_FAILURE';
export const PATIENT_CARD_UPDATE_STAGE_DATA_RESET = 'PATIENT_CARD_UPDATE_STAGE_DATA_RESET';

export const PATIENT_CARD_UPDATE_CONTACT_DATA_REQUEST = 'PATIENT_CARD_UPDATE_CONTACT_DATA_REQUEST';
export const PATIENT_CARD_UPDATE_CONTACT_DATA_SUCCESS = 'PATIENT_CARD_UPDATE_CONTACT_DATA_SUCCESS';
export const PATIENT_CARD_UPDATE_CONTACT_DATA_FAILURE = 'PATIENT_CARD_UPDATE_CONTACT_DATA_FAILURE';
export const PATIENT_CARD_UPDATE_CONTACT_DATA_RESET = 'PATIENT_CARD_UPDATE_CONTACT_DATA_RESET';

export const PATIENT_CARD_UPDATE_HOSPITAL_DATA_REQUEST = 'PATIENT_CARD_UPDATE_HOSPITAL_DATA_REQUEST';
export const PATIENT_CARD_UPDATE_HOSPITAL_DATA_SUCCESS = 'PATIENT_CARD_UPDATE_HOSPITAL_DATA_SUCCESS';
export const PATIENT_CARD_UPDATE_HOSPITAL_DATA_FAILURE = 'PATIENT_CARD_UPDATE_HOSPITAL_DATA_FAILURE';
export const PATIENT_CARD_UPDATE_HOSPITAL_DATA_RESET = 'PATIENT_CARD_UPDATE_HOSPITAL_DATA_RESET';

export const PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_REQUEST = 'PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_REQUEST';
export const PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_SUCCESS = 'PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_SUCCESS';
export const PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_FAILURE = 'PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_FAILURE';
export const PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_RESET = 'PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_RESET';

export const PATIENT_CARD_SET_HIDE = 'PATIENT_CARD_SET_HIDE';

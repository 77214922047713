export * from './events';
export * from './date';

// round the value to the number of digits
export const round = (value, decimals) => {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

// Check if element or his parents has given class
export const hasSomeParentTheClass = (element, classname) => {
    if (element.classList && element.classList.contains(classname)) return true;
    return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
}
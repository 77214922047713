import React from 'react';
import PropTypes from 'prop-types';

export default class Fieldset extends React.Component {

    createClassName() {
        let classNames = ['o-fieldset'];

        if (this.props.className) {
            classNames = [
                ...classNames, 
                ...this.props.className.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    createBodyClassName() {
        let classNames = ['o-fieldset__body'];

        if (this.props.bodyClassName) {
            classNames = [
                ...classNames, 
                ...this.props.bodyClassName.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    handleClearBtnClick() {
        this.props.onRequestClear();
    }

    render() {
        return (
            <fieldset className={this.createClassName()}>
                {(this.props.legend || this.props.clearable) &&
                    <legend className="o-fieldset__legend">
                        <div className="o-fieldset__legend-wrapper">
                            <span>{this.props.legend}</span>
    
                            {this.props.clearable && 
                                <span
                                    role="button"
                                    className="o-fieldset__clear-btn" 
                                    onClick={this.handleClearBtnClick.bind(this)}
                                >
                                    Wyczyść
                                </span>
                            }
                        </div>             
                    </legend>
                }
      
                
                <div className={this.createBodyClassName()}>
                    {this.props.children}
                </div>
            </fieldset>
        )
    }
}

Fieldset.propTypes = {
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    onRequestClear: PropTypes.func,
    legend: PropTypes.string,
    clearable: PropTypes.bool,
    children: PropTypes.node
}
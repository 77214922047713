import { 
    RECOMMENDATIONS_AND_MEDICINES_SET_MANAGEMENT_MODE 
} from "../actions/types/recommendations_and_medicines_types";


const initialState = {
   managementMode: 'medicine_add'
};

const recommendationsAndMedicinesReducer = (state = initialState, action) => {
    switch(action.type) {
        case RECOMMENDATIONS_AND_MEDICINES_SET_MANAGEMENT_MODE: {
            return {
                ...state,
                managementMode: action.payload.mode
            }
        }
        default:
            return state;
    }
}

export default recommendationsAndMedicinesReducer;
const useLazyLoading = ({
    lazy,
    rows,
    totalRows
}) => {
    const getTotalRows = () => lazy ? totalRows : rows.length;

    const canLoadMore = () => getTotalRows() > rows.length;

    const canLazyLoad = () => lazy && canLoadMore();

    return {
        canLazyLoad
    }
}

export default useLazyLoading;
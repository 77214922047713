export default {
    addForm: {
        isVisible: false 
    },
    editForm: {
        isVisible: false,
        messageId: null 
    },
    addRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    sendRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
}
import moment from 'moment';

import * as types from './types/chat_types';

import * as ChatService from '../../services/chat-service';
import * as PatientService from '../../services/patient-service';

export const sendMessage = (message, contactId, formActions) => async (dispatch, getState) => {
    dispatch({
        type: types.CHAT_SEND_MESSAGE_REQUEST
    });

    formActions.resetForm();

    try {
        const {
            data: { role }
        } = getState().user;

        const response = await ChatService.sendMessage(message, contactId, role);

        dispatch({
            type: types.CHAT_SEND_MESSAGE_SUCCESS,
            payload: response.data
        })

    } catch (err) {
        dispatch({
            type: types.CHAT_SEND_MESSAGE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const fetchContacts = (hospitalId) => async (dispatch, getState) => {
    dispatch({
        type: types.CHAT_FETCH_CONTACTS_REQUEST
    });

    try {
        const {
            contactsSearchQuery
        } = getState().chat;

        const {
            data: { role }
        } = getState().user;

        const contacts = await ChatService.getContacts(hospitalId, {
            query: contactsSearchQuery,
            role
        });

        dispatch({
            type: types.CHAT_FETCH_CONTACTS_SUCCESS,
            payload: contacts
        })
    } catch (err) {
        dispatch({
            type: types.CHAT_FETCH_CONTACTS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const fetchAvailableHospitals = () => async (dispatch) => {
    dispatch({
        type: types.CHAT_FETCH_AVAILABLE_HOSPITALS_REQUEST
    })

    try {
        const response = await ChatService.getAvailableHospitals();

        dispatch({
            type: types.CHAT_FETCH_AVAILABLE_HOSPITALS_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.CHAT_FETCH_AVAILABLE_HOSPITALS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const selectHospital = (hospitalId) => async (dispatch) => {
    dispatch({
        type: types.CHAT_SELECT_HOSPITAL,
        payload: hospitalId
    })
}

export const fetchConversation = (patientId, hospitalId) => async (dispatch, getState) => {
    dispatch({
        type: types.CHAT_FETCH_CONVERSATION_REQUEST
    })

    try {
        const {
            data: { role }
        } = getState().user;

        const response = await ChatService.getConversation(patientId, hospitalId, 1, role);

        dispatch({
            type: types.CHAT_FETCH_CONVERSATION_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.CHAT_FETCH_CONVERSATION_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const fetchConversationNextPage = (patientId, hospitalId) => async (dispatch, getState) => {
    dispatch({
        type: types.CHAT_FETCH_CONVERSATION_NEXT_PAGE_REQUEST,
    })

    const {
        conversation
    } = getState().chat;

    try {

        const response = await ChatService.getConversation(patientId, hospitalId, conversation.current_page + 1);

        dispatch({
            type: types.CHAT_FETCH_CONVERSATION_NEXT_PAGE_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.CHAT_FETCH_CONVERSATION_NEXT_PAGE_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}
export const selectContact = (contactId) => async (dispatch) => {
    dispatch({
        type: types.CHAT_SELECT_CONTACT,
        payload: contactId
    })
}

export const setContactsSearchQuery = (query) => async (dispatch) => {
    dispatch({
        type: types.CHAT_CONTACTS_SET_SEARCH_QUERY,
        payload: query
    })
}

export const fetchObservations = (patientId, month) => async dispatch => {
    dispatch({
        type: types.CHAT_FETCH_OBSERVATIONS_REQUEST
    })

    try {

        const year = moment().format('YYYY');

        let _month = '';

        if (month !== '') {
            _month = moment().set('month', month).format('MM');
        }

        const response = await PatientService.getAllObservations(patientId, _month, year)

        dispatch({
            type: types.CHAT_FETCH_OBSERVATIONS_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.CHAT_FETCH_OBSERVATIONS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const fetchUnreadedMessages = () => async dispatch => {
    dispatch({
        type: types.CHAT_FETCH_UNREADED_MESSAGES_REQUEST
    })

    try {

        const response = await ChatService.getUnreadedMessages();

        dispatch({
            type: types.CHAT_FETCH_UNREADED_MESSAGES_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.CHAT_FETCH_UNREADED_MESSAGES_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const fetchPatientNumberOfUnreadedMessages = (patientId, role) => async (dispatch) => {
    dispatch({
        type: types.CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_REQUEST
    })

    try {

        const response = await ChatService.getNumberOfUnreadMessages(patientId, role);

        dispatch({
            type: types.CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_SUCCESS,
            payload: { patientId, data: response.data }
        })
    } catch (err) {
        dispatch({
            type: types.CHAT_FETCH_UNREADED_MESSAGES_OF_PATIENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const clearConversationData = () => (dispatch) => {
    dispatch({
        type: types.CHAT_CLEAR_CONVERSATION_DATA
    })
}

export const resetChat = () => (dispatch) => {
    dispatch({
        type: types.CHAT_RESET
    })
}
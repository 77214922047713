import React from 'react';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';


const Select = (props) => {
    const createClassName = () => {
        const classNames = [];

        if (props.size) {
            classNames.push(`p-dropdown-size-${props.size}`);
        }

        if (props.variant) {
            classNames.push(`p-dropdown-variant-${props.variant}`)
        }

        return classNames.join(' ');
    }

    return (
        <PrimeDropdown
            className={createClassName()}
            {...props}
        />
    );
}

Select.propTypes = {
    size: PropTypes.oneOf(['sm']),
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.array,
    optionLabel: PropTypes.string,
    itemTemplate: PropTypes.func,
    className: PropTypes.string,
    filter: PropTypes.bool,
    filterPlaceholder: PropTypes.string,
    placeholder: PropTypes.string,
    dataKey: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool
}

export default Select;
import React from 'react';
import PropTypes from 'prop-types';


const Input = React.forwardRef((props, ref) => {
    const createClassName = () => {
        let classNames = ['o-input'];

        if (props.variant) {
            classNames.push(`o-input--${props.variant}`)
        }

        if (props.size) {
            classNames.push(`o-input--size-${props.size}`)
        }

        if (props.className) {
            classNames = [
                ...classNames,
                ...props.className.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    return (
        <input
            {...props}
            ref={ref}
            className={createClassName()}
        />
    )
});

Input.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    className: PropTypes.string
}

export default Input;
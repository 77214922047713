import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import TableHeader from './private/components/TableHeader';
import DataTableBody from './private/components/datatable/DataTableBody';
import useScrollbarWidth from './private/hooks/use-scrollbar-width';
import useLazyLoading from './private/hooks/use-lazy-loading';
import useInfiniteScrolling from './private/hooks/use-infinite-scrolling';
import useSorting from './private/hooks/use-sorting';
import { filterTableColumns } from './private/utils';

const DataTable = (props) => {
    const bodyRef = useRef();

    const bodyScrollbarWidth = useScrollbarWidth(bodyRef);

    const {
        canLazyLoad
    } = useLazyLoading({
        lazy: props.lazy,
        rows: props.rows,
        totalRows: props.totalRows,
        onLazyLoad: props.onLazyLoad
    });

    const {
        handleInfiniteScroll
    } = useInfiniteScrolling({
        canLazyLoad,
        scrollable: props.scrollable,
        onLazyLoad: props.onLazyLoad
    });

    const {
        handleSort
    } = useSorting({
        currentSortField: props.sortField,
        currentSortOrder: props.sortOrder,
        onSort: props.onSort
    });

    const createClassName = () => {
        const classNames = ['o-datatable'];

        if (props.scrollable) {
            classNames.push('o-datatable--scrollable');
        }

        if (props.className) {
            classNames.push(props.className);
        }

        return classNames.join(' ');
    }

    const columns = filterTableColumns(props.children);

    return (
        <div className={createClassName()}>
            <div className="o-datatable__wrapper">
                <TableHeader
                    bodyScrollbarWidth={bodyScrollbarWidth}
                    columns={columns}
                    sortField={props.sortField}
                    sortOrder={props.sortOrder}
                    onSort={handleSort}
                    tableClassName={props.tableClassName}
                />
                <DataTableBody
                    ref={bodyRef}
                    rows={props.rows}
                    columns={columns}
                    scrollable={props.scrollable}
                    scrollHeight={props.scrollHeight}
                    scrollThreshold={props.scrollThreshold}
                    onInfiniteScroll={handleInfiniteScroll}
                    hasMore={canLazyLoad()}
                    tableClassName={props.tableClassName}
                />
            </div>
        </div>
    );
}

DataTable.propTypes = {
    lazy: PropTypes.bool,
    totalRows: PropTypes.number,
    scrollable: PropTypes.bool,
    scrollHeight: PropTypes.string,
    scrollThreshold: PropTypes.number,
    rows: PropTypes.array,
    sortField: PropTypes.string,
    sortOrder: PropTypes.oneOf(['', 'asc', 'desc']),
    onLazyLoad: PropTypes.func,
    onSort: PropTypes.func,
    className: PropTypes.string,
    tableClassName: PropTypes.string
};

DataTable.defaultProps = {
    lazy: false,
    totalRows: 0,
    scrollable: true,
    scrollHeight: '450px',
    scrollThreshold: 100,
    rows: [],
    sortField: '',
    sortOrder: '',
    onLazyLoad: () => { },
    onSort: () => { }
}

export default DataTable;
import {
    PATIENT_CARD_FETCH_PATIENT_DATA_REQUEST,
    PATIENT_CARD_FETCH_PATIENT_DATA_SUCCESS,
    PATIENT_CARD_FETCH_PATIENT_DATA_FAILURE,
    PATIENT_CARD_FETCH_PATIENT_BONUS_REQUEST,
    PATIENT_CARD_FETCH_PATIENT_BONUS_SUCCESS,
    PATIENT_CARD_FETCH_PATIENT_BONUS_FAILURE,
    PATIENT_CARD_UPDATE_STAGE_DATA_REQUEST,
    PATIENT_CARD_UPDATE_STAGE_DATA_SUCCESS,
    PATIENT_CARD_UPDATE_STAGE_DATA_FAILURE,
    PATIENT_CARD_UPDATE_STAGE_DATA_RESET,
    PATIENT_CARD_UPDATE_CONTACT_DATA_REQUEST,
    PATIENT_CARD_UPDATE_CONTACT_DATA_SUCCESS,
    PATIENT_CARD_UPDATE_CONTACT_DATA_FAILURE,
    PATIENT_CARD_UPDATE_CONTACT_DATA_RESET,
    PATIENT_CARD_UPDATE_HOSPITAL_DATA_REQUEST,
    PATIENT_CARD_UPDATE_HOSPITAL_DATA_SUCCESS,
    PATIENT_CARD_UPDATE_HOSPITAL_DATA_FAILURE,
    PATIENT_CARD_UPDATE_HOSPITAL_DATA_RESET,
    PATIENT_CARD_UPDATE_PERSONAL_DATA_REQUEST,
    PATIENT_CARD_UPDATE_PERSONAL_DATA_SUCCESS,
    PATIENT_CARD_UPDATE_PERSONAL_DATA_FAILURE,
    PATIENT_CARD_UPDATE_PERSONAL_DATA_RESET,
    PATIENT_CARD_SET_HIDE,
    PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_REQUEST,
    PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_SUCCESS,
    PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_FAILURE,
    PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_RESET
} from "../actions/types/patient_card_types";

const initialState = {
    patientData: null,
    patientBonus: null,
    patientCardHide: false,
    fetchPatientDataRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    fetchPatientBonusRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updatePersonalDataRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateStageDataRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateContactDataRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateHospitalDataRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateAbilityToWorkDataRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

const patientCardReducer = (state = initialState, action) => {
    switch (action.type) {
        case PATIENT_CARD_FETCH_PATIENT_DATA_REQUEST: {
            const {
                fetchPatientDataRequest
            } = state;

            return {
                ...state,
                fetchPatientDataRequest: {
                    ...fetchPatientDataRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_CARD_FETCH_PATIENT_DATA_SUCCESS: {
            const {
                fetchPatientDataRequest
            } = state;

            return {
                ...state,
                patientData: action.payload,
                fetchPatientDataRequest: {
                    ...fetchPatientDataRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_CARD_FETCH_PATIENT_DATA_FAILURE: {
            const {
                fetchPatientDataRequest
            } = state;

            return {
                ...state,
                events: [],
                patientData: null,
                fetchPatientDataRequest: {
                    ...fetchPatientDataRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_CARD_FETCH_PATIENT_BONUS_REQUEST: {
            const {
                fetchPatientBonusRequest
            } = state;

            return {
                ...state,
                fetchPatientBonusRequest: {
                    ...fetchPatientBonusRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_CARD_FETCH_PATIENT_BONUS_SUCCESS: {
            const {
                fetchPatientBonusRequest
            } = state;

            return {
                ...state,
                patientBonus: action.payload,
                fetchPatientBonusRequest: {
                    ...fetchPatientBonusRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_CARD_FETCH_PATIENT_BONUS_FAILURE: {
            const {
                fetchPatientBonusRequest
            } = state;

            return {
                ...state,
                patientBonus: null,
                fetchPatientBonusRequest: {
                    ...fetchPatientBonusRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_CARD_UPDATE_PERSONAL_DATA_REQUEST: {
            const {
                updatePersonalDataRequest
            } = state;

            return {
                ...state,
                updatePersonalDataRequest: {
                    ...updatePersonalDataRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_PERSONAL_DATA_SUCCESS: {
            const {
                updatePersonalDataRequest
            } = state;

            return {
                ...state,
                updatePersonalDataRequest: {
                    ...updatePersonalDataRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_PERSONAL_DATA_FAILURE: {
            const {
                updatePersonalDataRequest
            } = state;

            return {
                ...state,
                updatePersonalDataRequest: {
                    ...updatePersonalDataRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_CARD_UPDATE_PERSONAL_DATA_RESET: {
            return {
                ...state,
                updatePersonalDataRequest: initialState.updatePersonalDataRequest
            }
        }
        case PATIENT_CARD_UPDATE_STAGE_DATA_REQUEST: {
            const {
                updateStageDataRequest
            } = state;

            return {
                ...state,
                updateStageDataRequest: {
                    ...updateStageDataRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_STAGE_DATA_SUCCESS: {
            const {
                updateStageDataRequest
            } = state;

            return {
                ...state,
                updateStageDataRequest: {
                    ...updateStageDataRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_STAGE_DATA_FAILURE: {
            const {
                updateStageDataRequest
            } = state;

            return {
                ...state,
                updateStageDataRequest: {
                    ...updateStageDataRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_CARD_UPDATE_STAGE_DATA_RESET: {
            return {
                ...state,
                updateStageDataRequest: initialState.updateStageDataRequest
            }
        }
        case PATIENT_CARD_UPDATE_CONTACT_DATA_REQUEST: {
            const {
                updateContactDataRequest
            } = state;

            return {
                ...state,
                updateContactDataRequest: {
                    ...updateContactDataRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_CONTACT_DATA_SUCCESS: {
            const {
                updateContactDataRequest
            } = state;

            return {
                ...state,
                updateContactDataRequest: {
                    ...updateContactDataRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_CONTACT_DATA_FAILURE: {
            const {
                updateContactDataRequest
            } = state;

            return {
                ...state,
                updateContactDataRequest: {
                    ...updateContactDataRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_CARD_UPDATE_CONTACT_DATA_RESET: {
            return {
                ...state,
                updateContactDataRequest: initialState.updateContactDataRequest
            }
        }
        case PATIENT_CARD_UPDATE_HOSPITAL_DATA_REQUEST: {
            const {
                updateHospitalDataRequest
            } = state;

            return {
                ...state,
                updateHospitalDataRequest: {
                    ...updateHospitalDataRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_HOSPITAL_DATA_SUCCESS: {
            const {
                updateHospitalDataRequest
            } = state;

            return {
                ...state,
                updateHospitalDataRequest: {
                    ...updateHospitalDataRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_HOSPITAL_DATA_FAILURE: {
            const {
                updateHospitalDataRequest
            } = state;

            return {
                ...state,
                updateHospitalDataRequest: {
                    ...updateHospitalDataRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_CARD_UPDATE_HOSPITAL_DATA_RESET: {
            return {
                ...state,
                updateHospitalDataRequest: initialState.updateHospitalDataRequest
            }
        }
        case PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_REQUEST: {
            const {
                updateAbilityToWorkDataRequest
            } = state;

            return {
                ...state,
                updateAbilityToWorkDataRequest: {
                    ...updateAbilityToWorkDataRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_SUCCESS: {
            const {
                updateAbilityToWorkDataRequest
            } = state;

            return {
                ...state,
                updateAbilityToWorkDataRequest: {
                    ...updateAbilityToWorkDataRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_FAILURE: {
            const {
                updateAbilityToWorkDataRequest
            } = state;

            return {
                ...state,
                updateAbilityToWorkDataRequest: {
                    ...updateAbilityToWorkDataRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_CARD_UPDATE_ABILITY_TO_WORK_DATA_RESET: {
            return {
                ...state,
                updateAbilityToWorkDataRequest: initialState.updateAbilityToWorkDataRequest
            }
        }
        case PATIENT_CARD_SET_HIDE: {
            return {
                ...state,
                patientCardHide: action.payload
            }
        }
        default:
            return state;
    }
};

export default patientCardReducer;
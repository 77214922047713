import React from 'react';
import InfiniteScroller from 'react-infinite-scroller';
import PropTypes from 'prop-types';

import DataTableBodyRow from './DataTableBodyRow';

const DataTableBody = React.forwardRef((props, ref) => {

    const createTableClassName = () => {
        const classNames = ['o-datatable__table'];

        if (props.tableClassName) {
            classNames.push(props.tableClassName);
        }

        return classNames.join(' ');
    }

    return (
        <div
            className="o-datatable__body"
            style={{ height: props.scrollHeight }}
            ref={ref}
        >
            <InfiniteScroller
                useWindow={false}
                loadMore={props.onInfiniteScroll}
                hasMore={props.hasMore}
                threshold={props.scrollThreshold}
                initialLoad={true}
            >
                <table className={createTableClassName()}>
                    <tbody>
                        {props.rows.map((row, i) => (
                            <DataTableBodyRow
                                key={i}
                                row={row}
                                columns={props.columns}
                            />
                        ))}
                    </tbody>
                </table>
            </InfiniteScroller>
        </div>
    )
});


DataTableBody.propTypes = {
    tableClassName: PropTypes.string,
    scrollHeight: PropTypes.string,
    scrollThreshold: PropTypes.number,
    onInfiniteScroll: PropTypes.func,
    rows: PropTypes.array,
    columns: PropTypes.arrayOf(PropTypes.node),
    hasMore: PropTypes.bool
}

export default DataTableBody;
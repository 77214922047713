import { createHttpClient } from './api-service';

export const getChapters = (params) => {
    const api = createHttpClient();

    const url = '/dashboard/education/list';

    const data = {
        page: params.page,
        per_page: params.perPage,
        q: params.query
    };

    return api.post(url, data);
};


export const getSubchapter = (subchapterId) => {
    const api = createHttpClient();

    const url = `/dashboard/education/get/${subchapterId}`;

    return api.get(url);
};

export const addChapter = (values, parentId, position) => {
    const api = createHttpClient();

    const url = `/dashboard/education/create`;

    const data = {
        name: values.name,
        order: position,
        parent_id: parentId,
        subjects: []
    }

    return api.post(url, data);
};

export const updateChapter = (values, chapterId) => {
    const api = createHttpClient();

    const url = `/dashboard/education/update/${chapterId}`;

    let data = {
        name: values.name,
        subjects: values.subjects
    };

    return api.post(url, data);
}


export const duplicateChapter = (chapterId) => {
    const api = createHttpClient();

    const url = `/dashboard/education/duplicate/${chapterId}`;

    return api.get(url);
}

export const removeChapter = (chapterId) => {
    const api = createHttpClient();

    const url = `/dashboard/education/delete/${chapterId}`;

    return api.get(url);
}

export const createChaptersTreeNodes = (chapters) => {
    let nodes = [];

    chapters.forEach(chapter => {
        const {
            subchapters,
            ...data
        } = chapter;

        nodes.push({
            children: createChaptersTreeNodes(subchapters.data),
            data
        });
    });

    return nodes;
}

export const findChapterInTreeNodes = (id, chapters) => {
    let result = null;

    for (let chapter of chapters) {
        if (chapter.data.id === id) {
            result = chapter;
        } else {
            result = findChapterInTreeNodes(id, chapter.children);
        }

        if (result) {
            break;
        }
    }

    return result;
}

export const replaceChapterDataInTreeNodes = (id, chapters, newChapterData) => {
    const parent = findChapterTreeNodeParent(id, chapters);

    const nodes = parent ? parent.children : chapters;

    const index = nodes.findIndex(node => node.data.id === id);

    nodes[index].data = newChapterData;
}

export const findChapterTreeNodeParent = (id, chapters) => {
    let result = null;

    for (let chapter of chapters) {
        const isParent = chapter.children.find(childChapter => childChapter.data.id === id) ? true : false;

        if (isParent) {
            result = chapter;
        } else {
            result = findChapterTreeNodeParent(id, chapter.children);
        }

        if (result) {
            break;
        }
    }

    return result;
}

export const getSubchapters = (chapters) => {
    let nodes = [];

    chapters.forEach(chapter => {
        const {
            subchapters,
            ...data
        } = chapter;

        subchapters.data.forEach(subchapter => {
            nodes.push({
                ...subchapter,
                parent_chapter: data
            });
        });
    });

    return nodes;
}

export const getLesson = (lessonId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-lesson/get/${lessonId}`;

    return api.get(url);
}

export const addLesson = (values, subchapterId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-lesson/create/${subchapterId}`;

    const data = {
        name: values.name,
        text: values.text
    };

    return api.post(url, data);
}

export const updateLesson = (values, lessonId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-lesson/update/${lessonId}`;

    const data = {
        name: values.name,
        text: values.text
    };

    return api.post(url, data);
}

export const orderLesson = (order, lessonId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-lesson/order/${lessonId}`;

    const data = {
        order: order
    }

    return api.post(url, data);
}

export const removeLesson = (lessonId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-lesson/delete/${lessonId}`;

    return api.get(url)
}

export const getSubjects = () => {
    const api = createHttpClient();

    const url = `/dashboard/education-subjects/list`;

    const data = {
        page: 1,
        per_page: 100000
    };

    return api.post(url, data);
}

export const addSubject = (values) => {
    const api = createHttpClient();

    const url = `/dashboard/education-subjects/create`;

    const data = {
        name: values.name,
    };

    return api.post(url, data);
}

export const updateSubject = (values, subjectId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-subjects/update/${subjectId}`;

    const data = {
        name: values.name,
    };

    return api.post(url, data);
}

export const removeSubject = (subjectId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-subjects/delete/${subjectId}`;

    return api.get(url);
}

export const getMessages = (params) => {
    const api = createHttpClient();

    const url = '/dashboard/education-message/list';

    const data = {
        page: params.page,
        per_page: params.perPage,
        q: params.query
    };

    return api.post(url, data);
};

export const getMessage = (messageId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-message/get/${messageId}`;

    return api.get(url);
}

export const addMessage = (values) => {
    const api = createHttpClient();

    const url = `/dashboard/education-message/create`;

    const data = {
        title: values.title,
        body: values.body,
        type: values.type,
        center_id: values.hospital ? values.hospital.value : null,
        lesson_id: values.lesson_id
    }

    return api.post(url, data);
}

export const updateMessage = (values, messageId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-message/update/${messageId}`;

    const data = {
        title: values.title,
        body: values.body,
        type: values.type,
        center_id: values.hospital ? values.hospital.value : null,
        lesson_id: values.lesson_id
    }

    return api.post(url, data);
}

export const removeMessage = (messageId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-message/delete/${messageId}`;

    return api.get(url);
}

export const sendMessage = (messageId) => {
    const api = createHttpClient();

    const url = `/dashboard/education-message/send/${messageId}`;

    return api.get(url);
}
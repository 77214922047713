import * as types from "../actions/types/hospital_card_types";

const initialState = {
    hospitalData: null,
    fetchHospitalDataRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

const hospitalCardReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.HOSPITAL_CARD_FETCH_HOSPITAL_DATA_REQUEST: {
            const {
                fetchHospitalDataRequest
            } = state;

            return {
                ...state,
                fetchHospitalDataRequest: {
                    ...fetchHospitalDataRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.HOSPITAL_CARD_FETCH_HOSPITAL_DATA_SUCCESS: {
            const {
                fetchHospitalDataRequest
            } = state;

            return {
                ...state,
                hospitalData: action.payload,
                fetchHospitalDataRequest: {
                    ...fetchHospitalDataRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.HOSPITAL_CARD_FETCH_HOSPITAL_DATA_FAILURE: {
            const {
                fetchHospitalDataRequest
            } = state;

            return {
                ...state,
                hospitalData: null,
                fetchHospitalDataRequest: {
                    ...fetchHospitalDataRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.HOSPITAL_CARD_FETCH_HOSPITAL_RESET: {
            return {
                ...state,
                fetchHospitalDataRequest: initialState.fetchHospitalDataRequest
            }
        }
        default:
            return state;
    }
};

export default hospitalCardReducer;
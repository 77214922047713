import React from 'react';
import PropTypes from 'prop-types';

import DataTableBodyCell from './DataTableBodyCell';

const DataTableBodyRow = (props) => {
    return (
        <tr className="o-datatable__row o-datatable__row--body">
            {props.columns.map(col => (
                <DataTableBodyCell 
                    key={col.props.field}
                    col={col} 
                    row={props.row} 
                />
            ))}
        </tr>
    );
};

DataTableBodyRow.propTypes = {
    row: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.node)
}

export default DataTableBodyRow;
export const RECOMMENDATION_TEMPLATE_FETCH_ALL_REQUEST = 'RECOMMENDATION_TEMPLATE_FETCH_ALL_REQUEST';
export const RECOMMENDATION_TEMPLATE_FETCH_ALL_SUCCESS = 'RECOMMENDATION_TEMPLATE_FETCH_ALL_SUCCESS';
export const RECOMMENDATION_TEMPLATE_FETCH_ALL_FAILURE = 'RECOMMENDATION_TEMPLATE_FETCH_ALL_FAILURE';

export const RECOMMENDATION_TEMPLATE_ADD_REQUEST = 'RECOMMENDATION_TEMPLATE_ADD_REQUEST';
export const RECOMMENDATION_TEMPLATE_ADD_SUCCESS = 'RECOMMENDATION_TEMPLATE_ADD_SUCCESS';
export const RECOMMENDATION_TEMPLATE_ADD_FAILURE = 'RECOMMENDATION_TEMPLATE_ADD_FAILURE';
export const RECOMMENDATION_TEMPLATE_ADD_RESET = 'RECOMMENDATION_TEMPLATE_ADD_RESET';

export const RECOMMENDATION_TEMPLATE_UPDATE_REQUEST = 'RECOMMENDATION_TEMPLATE_UPDATE_REQUEST';
export const RECOMMENDATION_TEMPLATE_UPDATE_SUCCESS = 'RECOMMENDATION_TEMPLATE_UPDATE_SUCCESS';
export const RECOMMENDATION_TEMPLATE_UPDATE_FAILURE = 'RECOMMENDATION_TEMPLATE_UPDATE_FAILURE';
export const RECOMMENDATION_TEMPLATE_UPDATE_RESET = 'RECOMMENDATION_TEMPLATE_UPDATE_RESET';

export const RECOMMENDATION_TEMPLATE_REMOVE_REQUEST = 'RECOMMENDATION_TEMPLATE_REMOVE_REQUEST';
export const RECOMMENDATION_TEMPLATE_REMOVE_SUCCESS = 'RECOMMENDATION_TEMPLATE_REMOVE_SUCCESS';
export const RECOMMENDATION_TEMPLATE_REMOVE_FAILURE = 'RECOMMENDATION_TEMPLATE_REMOVE_FAILURE';
export const RECOMMENDATION_TEMPLATE_REMOVE_RESET = 'RECOMMENDATION_TEMPLATE_REMOVE_RESET';

export const RECOMMENDATION_TEMPLATE_SET_IS_EDIT_FORM_VISIBLE = 'RECOMMENDATION_TEMPLATE_SET_IS_EDIT_FORM_VISIBLE';
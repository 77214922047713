import common from './pl/common';
import names from './pl/names';
import modal_remove from './pl/modal_remove';
import events from './pl/events';
import users from './pl/users';
import patient from './pl/patient';
import patient_event from './pl/patient_event';
import patient_medicine from './pl/patient_medicine';
import patient_recommendations from './pl/patient_recommendations';
import survey from './pl/survey';
import diets from './pl/diets';
import hospitals from './pl/hospitals';
import user_list from './pl/user_list';
import education_lessons from './pl/education_lessons';
import education_messages from './pl/education_messages';
import bonus from './pl/bonus';
import app_params_events from './pl/app_params_events';
import app_params_stages from './pl/app_params_stages';
import app_params_center_types from './pl/app_params_center_types';

export default {
    pl: {
        common,
        names,
        modal_remove,
        events,
        users,
        patient,
        patient_event,
        patient_medicine,
        patient_recommendations,
        survey,
        diets,
        hospitals,
        user_list,
        education_lessons,
        education_messages,
        bonus,
        app_params_events,
        app_params_stages,
        app_params_center_types
    }
}
import React from 'react';
import PropTypes from 'prop-types';

const TableSortIcon = (props) => {

    const createClassName = () => {
        const classNames = ['o-datatable__sort-icon pi pi-fw'];

        if (props.isSortedBy) {
            if (props.sortOrder === 'asc')  {
                classNames.push('pi-sort-up')
            } else if (props.sortOrder === 'desc') {
                classNames.push('pi-sort-down');
            } else {
                classNames.push('pi-sort');
            }
        } else {
            classNames.push('pi-sort');
        }

        return classNames.join(' ');
    };

    return (
        <span className={createClassName()}>
        </span>
    )
}

TableSortIcon.propTypes = {
    isSortedBy: PropTypes.bool,
    sortOrder: PropTypes.oneOf(['', 'asc', 'desc'])
}

export default TableSortIcon;
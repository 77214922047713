import * as types from '../actions/types/education_messages_types';

import educationMessagesInitialState from './helpers/education-messages-initial-state';

const initialState = educationMessagesInitialState;

const educationMessagesReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.EDUCATION_MESSAGES_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_MESSAGES_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_MESSAGES_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_MESSAGES_SEND_REQUEST: {
            const {
                sendRequest
            } = state;

            return {
                ...state,
                sendRequest: {
                    ...sendRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_SEND_SUCCESS: {
            const {
                sendRequest
            } = state;

            return {
                ...state,
                sendRequest: {
                    ...sendRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_MESSAGES_SEND_FAILURE: {
            const {
                sendRequest
            } = state;

            return {
                ...state,
                sendRequest: {
                    ...sendRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_MESSAGES_SET_ADD_FORM: {
            return {
                ...state,
                addForm: action.payload
            }
        }
        case types.EDUCATION_MESSAGES_SET_EDIT_FORM: {
            return {
                ...state,
                editForm: action.payload
            }
        }
        default: 
            return state;
    }
}

export default educationMessagesReducer;
import * as types from '../actions/types/interventions_types';
import interventionsInitialState from './helpers/interventions-initial-state';

const initialState = interventionsInitialState;

const interventionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INTERVENTIONS_FETCH_ALL_REQUEST: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_FETCH_ALL_SUCCESS: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                interventions: action.payload.data,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_FETCH_ALL_FAILURE: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                interventions: [],
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.INTERVENTIONS_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.INTERVENTIONS_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.INTERVENTIONS_ORDER_REQUEST: {
            const {
                orderRequest
            } = state;

            return {
                ...state,
                orderRequest: {
                    ...orderRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_ORDER_SUCCESS: {
            const {
                orderRequest
            } = state;

            return {
                ...state,
                orderRequest: {
                    ...orderRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_ORDER_FAILURE: {
            const {
                orderRequest
            } = state;

            return {
                ...state,
                orderRequest: {
                    ...orderRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.INTERVENTIONS_ORDER_RESET: {
            return {
                ...state,
                orderRequest: initialState.orderRequest
            }
        }
        case types.INTERVENTIONS_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    isError: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    isError: false,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.INTERVENTIONS_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    isError: true,
                    data: null,
                    error: action.payload
                }
            }
        }
        default:
            return state;
    }
}

export default interventionsReducer;
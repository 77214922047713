import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useDidMount from '@rooks/use-did-mount';
import usePrevious from '@rooks/use-previous';

import ProgressSpinner from '../common/ProgressSpinner';
import PatientAddFormModalContainer from '../../containers/PatientAddFormModalContainer';
import PatientEditFormModalContainer from '../../containers/PatientEditFormModalContainer';
import ClosestEventsModal from './closest-events/ClosestEventsModal';
import AppRoutes from '../routes/app-routes/AppRoutes';
import { loadSelf } from '../../store/actions/user-actions';
import { fetchUnreadedMessages } from '../../store/actions/chat-actions';
import { clearPatientEventsState } from '../../store/actions/patient-events-actions';
import { clearHospitalEventsState } from '../../store/actions/hospital-events-action';

const App = () => {
	const location = useLocation();
	const prevLocation = usePrevious(location);

	const user = useSelector((store) => store.user);

	const userId = !!user.data && user.data.id;
	const prevUserId = usePrevious(userId);
	const isAuthenticated = user.isAuthenticated;

	const dispatch = useDispatch();

	const menuSection = location.pathname.split('/')[1];
	const prevMenuSection = prevLocation && prevLocation.pathname.split('/')[1];

	useDidMount(() => {
		dispatch(loadSelf());
	});

	useEffect(() => {
		if (userId !== prevUserId && isAuthenticated) {
			dispatch(fetchUnreadedMessages());
		}
		// eslint-disable-next-line
	}, [userId, prevUserId]);

	useEffect(() => {
		if (prevMenuSection === 'patient' && menuSection !== prevMenuSection) {
			dispatch(clearPatientEventsState());
		}
		if (prevMenuSection === 'hospital' && menuSection !== prevMenuSection) {
			dispatch(clearHospitalEventsState());
		}
		// eslint-disable-next-line
	}, [menuSection, prevMenuSection]);

	if (user.isFetchSelfRequestActive) {
		return <ProgressSpinner forViewport />;
	}

	return (
		<div id="app" className="app">
			<React.Suspense fallback={<ProgressSpinner forViewport />}>
				<PatientAddFormModalContainer />
				<PatientEditFormModalContainer />
				<ClosestEventsModal location={location} />
				<AppRoutes />
			</React.Suspense>
		</div>
	);
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';

import { gridBreakpoints } from '../../config';


export default class Grid extends React.Component {

    createClassName() {
        let classNames = ['o-grid'];

        gridBreakpoints.forEach(bp => {
            const gapPropName = bp ? `gap-${bp}` : 'gap';
            const gapXPropName = bp ? `gap-x-${bp}` : 'gap-x';
            const gapYPropName = bp ? `gap-y-${bp}` : 'gap-y';

            const gapPropValue = this.props[gapPropName];
            const gapXPropValue = this.props[gapXPropName];
            const gapYPropValue = this.props[gapYPropName];

            if (gapPropValue) {
                classNames.push(
                    `o-grid--gap-${gapPropValue}${bp ? `@${bp}` : ''}`
                );
            }

            if (gapXPropValue) {
                classNames.push(
                    `o-grid--gap-x-${gapXPropValue}${bp ? `@${bp}` : ''}`
                );
            }

            if (gapYPropValue) {
                classNames.push(
                    `o-grid--gap-y-${gapYPropValue}${bp ? `@${bp}` : ''}`
                );
            }
        });
        

        if (this.props.className) {
            classNames = [
                ...classNames, 
                ...this.props.className.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    createRowClassName() {
        let classNames = ['o-grid__row'];

        const flags = [
            'justify-start',
            'justify-end',
            'justify-center',
            'justify-between',
            'align-start',
            'align-end',
            'align-center',
            'no-wrap'
        ];
        
        flags.forEach(flag => {
            gridBreakpoints.forEach(bp => {
                const propName = bp ? `${flag}-${bp}` : flag;
                const propValue = this.props[propName];

                if (propValue) {
                    classNames.push(
                        `o-grid__row--${flag}${bp ? `@${bp}` : ''}`
                    );
                }
            });
        });

        if (this.props.rowClassName) {
            classNames = [
                ...classNames, 
                ...this.props.rowClassName.split(' ')
            ];
        }

        return classNames.join(' ');
    }

    render() {
        return (
            <div className={this.createClassName()}>
                <div className={this.createRowClassName()}>{this.props.children}</div>
            </div>
        );
    }
}

const stringOrNumber = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
]);

Grid.propTypes = {
    'gap': stringOrNumber,
    'gap-xs': stringOrNumber,
    'gap-sm': stringOrNumber,
    'gap-md': stringOrNumber,
    'gap-lg': stringOrNumber,
    'gap-xlg': stringOrNumber,
    'gap-x': stringOrNumber,
    'gap-x-xs': stringOrNumber,
    'gap-x-sm': stringOrNumber,
    'gap-x-md': stringOrNumber,
    'gap-x-lg': stringOrNumber,
    'gap-x-xlg': stringOrNumber,
    'gap-y': stringOrNumber,
    'gap-y-xs': stringOrNumber,
    'gap-y-sm': stringOrNumber,
    'gap-y-md': stringOrNumber,
    'gap-y-lg': stringOrNumber,
    'gap-y-xlg': stringOrNumber,
    'justify-start': PropTypes.bool,
    'justify-start-xs': PropTypes.bool,
    'justify-start-sm': PropTypes.bool,
    'justify-start-md': PropTypes.bool,
    'justify-start-lg': PropTypes.bool,
    'justify-start-xlg': PropTypes.bool,
    'justify-end': PropTypes.bool,
    'justify-end-xs': PropTypes.bool,
    'justify-end-sm': PropTypes.bool,
    'justify-end-md': PropTypes.bool,
    'justify-end-lg': PropTypes.bool,
    'justify-end-xlg': PropTypes.bool,
    'justify-center': PropTypes.bool,
    'justify-center-xs': PropTypes.bool,
    'justify-center-sm': PropTypes.bool,
    'justify-center-md': PropTypes.bool,
    'justify-center-lg': PropTypes.bool,
    'justify-center-xlg': PropTypes.bool,
    'justify-between': PropTypes.bool,
    'justify-between-xs': PropTypes.bool,
    'justify-between-sm': PropTypes.bool,
    'justify-between-md': PropTypes.bool,
    'justify-between-lg': PropTypes.bool,
    'justify-between-xlg': PropTypes.bool,
    'align-start': PropTypes.bool,
    'align-start-xs': PropTypes.bool,
    'align-start-sm': PropTypes.bool,
    'align-start-md': PropTypes.bool,
    'align-start-lg': PropTypes.bool,
    'align-start-xlg': PropTypes.bool,
    'align-end': PropTypes.bool,
    'align-end-xs': PropTypes.bool,
    'align-end-sm': PropTypes.bool,
    'align-end-md': PropTypes.bool,
    'align-end-lg': PropTypes.bool,
    'align-end-xlg': PropTypes.bool,
    'align-center': PropTypes.bool,
    'align-center-xs': PropTypes.bool,
    'align-center-sm': PropTypes.bool,
    'align-center-md': PropTypes.bool,
    'align-center-lg': PropTypes.bool,
    'align-center-xlg': PropTypes.bool,
    'no-wrap': PropTypes.bool,
    'no-wrap-xs': PropTypes.bool,
    'no-wrap-sm': PropTypes.bool,
    'no-wrap-md': PropTypes.bool,
    'no-wrap-lg': PropTypes.bool,
    'no-wrap-xlg': PropTypes.bool,
    'className': PropTypes.string,
    'rowClassName': PropTypes.string,
    'children': PropTypes.node
}
import { 
    EDUCATION_ADD_CHAPTER_REQUEST, 
    EDUCATION_ADD_CHAPTER_SUCCESS, 
    EDUCATION_ADD_CHAPTER_FAILURE,
    EDUCATION_UPDATE_CHAPTER_REQUEST, 
    EDUCATION_UPDATE_CHAPTER_SUCCESS, 
    EDUCATION_UPDATE_CHAPTER_FAILURE,
    EDUCATION_REMOVE_CHAPTER_REQUEST, 
    EDUCATION_REMOVE_CHAPTER_SUCCESS, 
    EDUCATION_REMOVE_CHAPTER_FAILURE,
    EDUCATION_DUPLICATE_CHAPTER_REQUEST, 
    EDUCATION_DUPLICATE_CHAPTER_SUCCESS, 
    EDUCATION_DUPLICATE_CHAPTER_FAILURE    
} from "../actions/types/education_types";

const initialState = {
    addChapterRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateChapterRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeChapterRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    duplicateChapterRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

const educationReducer = (state = initialState, action) => {
    switch(action.type) {
        case EDUCATION_ADD_CHAPTER_REQUEST: {
            const {
                addChapterRequest
            } = state;

            return {
                ...state,
                addChapterRequest: {
                    ...addChapterRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case EDUCATION_ADD_CHAPTER_SUCCESS: {
            const {
                addChapterRequest
            } = state;

            return {
                ...state,
                addChapterRequest: {
                    ...addChapterRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case EDUCATION_ADD_CHAPTER_FAILURE: {
            const {
                addChapterRequest
            } = state;

            return {
                ...state,
                addChapterRequest: {
                    ...addChapterRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case EDUCATION_UPDATE_CHAPTER_REQUEST: {
            const {
                updateChapterRequest
            } = state;

            return {
                ...state,
                updateChapterRequest: {
                    ...updateChapterRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case EDUCATION_UPDATE_CHAPTER_SUCCESS: {
            const {
                updateChapterRequest
            } = state;

            return {
                ...state,
                updateChapterRequest: {
                    ...updateChapterRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case EDUCATION_UPDATE_CHAPTER_FAILURE: {
            const {
                updateChapterRequest
            } = state;

            return {
                ...state,
                updateChapterRequest: {
                    ...updateChapterRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case EDUCATION_REMOVE_CHAPTER_REQUEST: {
            const {
                removeChapterRequest
            } = state;

            return {
                ...state,
                removeChapterRequest: {
                    ...removeChapterRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case EDUCATION_REMOVE_CHAPTER_SUCCESS: {
            const {
                removeChapterRequest
            } = state;

            return {
                ...state,
                removeChapterRequest: {
                    ...removeChapterRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case EDUCATION_REMOVE_CHAPTER_FAILURE: {
            const {
                removeChapterRequest
            } = state;

            return {
                ...state,
                removeChapterRequest: {
                    ...removeChapterRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case EDUCATION_DUPLICATE_CHAPTER_REQUEST: {
            const {
                duplicateChapterRequest
            } = state;

            return {
                ...state,
                duplicateChapterRequest: {
                    ...duplicateChapterRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case EDUCATION_DUPLICATE_CHAPTER_SUCCESS: {
            const {
                duplicateChapterRequest
            } = state;

            return {
                ...state,
                duplicateChapterRequest: {
                    ...duplicateChapterRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case EDUCATION_DUPLICATE_CHAPTER_FAILURE: {
            const {
                duplicateChapterRequest
            } = state;

            return {
                ...state,
                duplicateChapterRequest: {
                    ...duplicateChapterRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        default: 
            return state;
    }
}

export default educationReducer;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import HttpStatus from 'http-status-codes';
import toastr from 'toastr';
import useDidMount from '@rooks/use-did-mount';
import useWillUnmount from '@rooks/use-will-unmount';
import usePrevious from '@rooks/use-previous';

import validate from './validation';

import Suspense from '../common/Suspense';
import ProgressSpinner from '../common/ProgressSpinner';
import Button from '../common/Button';
import Grid from '../common/Grid';
import GridItem from '../common/GridItem';
import PatientFormEducationalIssuesFieldset from './PatientForm/PatientFormEducationalIssuesFieldset';
import PatientFormPersonalDataFieldset from './PatientForm/PatientFormPersonalDataFieldset';
import PatientFormTreatmentProgramFieldset from './PatientForm/PatientFormTreatmentProgramFieldset';
import PatientFormStageFieldset from './PatientForm/PatientFormStageFieldset';

import patientFormInitialValues from './PatientForm/helpers/patient-form-initial-values';
import { updatePatient, resetUpdatePatientRequest } from '../../store/actions/patient-actions';
import * as PatientService from '../../services/patient-service';
import PatientFormHospitalFieldset from './PatientForm/PatientFormHospitalFieldset';
import { fetchSubjects } from '../../store/actions/education-subjects-actions';
import { fetchTreatmentProgram, fetchStages } from '../../store/actions/app-params-actions';


const PatientEditForm = (props) => {

    const [backendErrors, setBackendErrors] = useState({});

    const [isLoadingAllOptions, setIsLoadingAllOptions] = useState(false);

    const [isLoadingInitialValues, setIsLoadingInitialValues] = useState(true);

    const [initialValues, setInitialValues] = useState({
        ...patientFormInitialValues
    });

    const prevPatientIdProp = usePrevious(props.patientId);

    const patient = useSelector(store => store.patient);

    const dispatch = useDispatch();

    const error = patient.updateRequest.error;

    const isSuccess = patient.updateRequest.isSuccess;

    const prevIsSuccess = usePrevious(isSuccess);

    const {
        subjects
    } = useSelector(store => store.educationSubjects)

    const getAllOptions = async () => {
        try {
            setIsLoadingAllOptions(true);

            await Promise.all([
                dispatch(
                    fetchSubjects()
                ),

                dispatch(
                    fetchTreatmentProgram()
                ),

                dispatch(
                    fetchStages()
                )
            ]);

            setIsLoadingAllOptions(false);
        } catch (e) {
            toastr.error('Nie udało się pobrać wszystkich informacji');

            props.onRequestClose();
        }
    }

    const getInitialValues = async (patientId) => {
        const response = await PatientService.getPatient(patientId);

        const patient = response.data;

        return PatientService.createInitialPatientFormValues(patient);
    };

    const handleSubmit = async (values, actions) => {
        dispatch(
            updatePatient(values, props.patientId, actions, props.onUpdate)
        );
    }

    useEffect(() => {

        const loadInitialValues = async () => {
            setIsLoadingInitialValues(true);

            try {
                const initialValues = await getInitialValues(props.patientId);

                setInitialValues(initialValues);

                setIsLoadingInitialValues(false);
            } catch (e) {
                toastr.error('Wystąpił błąd.');

                props.onRequestClose();
            }
        }

        if (
            props.patientId !== prevPatientIdProp
        ) {
            loadInitialValues();
        }

    }, [
        props,
        prevPatientIdProp
    ]);

    useWillUnmount(() => {
        dispatch(
            resetUpdatePatientRequest()
        )
    });

    useEffect(() => {
        if (isSuccess && !prevIsSuccess) {
            toastr.success('Pacjent został zaktualizowany');
            props.onRequestClose();
        }
    }, [isSuccess, prevIsSuccess, props]);

    useEffect(() => {
        if (!error) {
            setBackendErrors({});

            return;
        }

        const {
            status,
            data: {
                errors,
                message
            }
        } = error;

        if (status === HttpStatus.UNPROCESSABLE_ENTITY) {
            setBackendErrors(errors);
            toastr.error('Wprowadź poprawne dane')
        } else if (message) {
            setBackendErrors({});
            toastr.error(message);
        } else {
            setBackendErrors({});
        }
    }, [error]);

    useDidMount(() => {
        getAllOptions();
    });

    const createInitialValues = () => {
        const _initialValues = { ...initialValues };

        _initialValues.emailConfirmation = _initialValues.email;

        subjects.forEach(subject => {
            _initialValues.education[subject.id] = initialValues.education[subject.id] ? true : false;
        });

        return _initialValues;
    };


    return (
        <Suspense
            isLoading={isLoadingInitialValues && isLoadingAllOptions}
            fallback={<ProgressSpinner />}
        >
            <Formik
                enableReinitialize={true}
                initialValues={createInitialValues()}
                onSubmit={handleSubmit}
                validate={validate}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit
                    }) => (

                            <form
                                className="o-modal c-patient-add-modal"
                                onSubmit={handleSubmit}
                            >
                                <div className="o-modal__header">
                                    {props.header || 'Edycja pacjenta'}
                                </div>
                                <div className="o-modal__body">
                                    <PatientFormPersonalDataFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        errors={backendErrors}
                                        frontErrors={errors}
                                        touched={touched}
                                        disabled={props.onlyPreview}
                                    />

                                    <PatientFormHospitalFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                        disabled={props.onlyPreview}
                                    />

                                    <PatientFormTreatmentProgramFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                        setFieldValue={setFieldValue}
                                        disabled={props.onlyPreview}
                                    />

                                    <PatientFormStageFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                        disabled={props.onlyPreview}
                                    />

                                    <PatientFormEducationalIssuesFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                        disabled={props.onlyPreview}
                                    />
                                </div>
                                <div className="o-modal__footer c-patient-add-modal__footer">
                                    <Grid justify-end gap="8">
                                        <GridItem col="auto">
                                            <Button
                                                variant="light"
                                                title="Anuluj"
                                                className="c-patient-add-modal__btn"
                                                onClick={props.onRequestClose}
                                            />
                                        </GridItem>
                                        <GridItem col="auto">
                                            {!props.onlyPreview &&
                                                <Button
                                                    type="submit"
                                                    variant="success"
                                                    title="Zapisz"
                                                    className="c-patient-add-modal__btn"
                                                />
                                            }
                                        </GridItem>
                                    </Grid>
                                </div>
                            </form>
                        )
                }
            </Formik>
        </Suspense>

    )
};

PatientEditForm.propTypes = {
    onlyPreview: PropTypes.bool,
    header: PropTypes.string,
    patientId: PropTypes.number,
    onRequestClose: PropTypes.func,
    onUpdate: PropTypes.func
};

export default PatientEditForm;
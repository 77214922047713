import moment from 'moment';

import { createHttpClient } from './api-service';
import { apiDateFormat } from '../config';

export const getClosestEvents = (filters) => {
    const api = createHttpClient();

    const url = `/dashboard/event/list`;

    const date = moment(filters.from).format(apiDateFormat); 

    const data = {
        ...filters,
        "from": date,
        "to": date
    };

    return api.post(url, data);
};
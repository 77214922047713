import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { createAppParamsOptions } from 'helpers/events';

import Fieldset from '../../common/Fieldset';
import FormGroup from '../../common/FormGroup';
import Label from '../../common/Label';
import Select from '../../common/Select';
import ControlErrors from '../../common/ControlErrors';

const PatientFormStageFieldset = ({ values, handleChange, errors, disabled }) => {
    const {
        stages: {
            data: stagesData
        }
    } = useSelector((store) => store.appParams);

    const stageOptions = createAppParamsOptions(stagesData);

    return (
        <Fieldset
            legend="Etap procesu pacjenta"
            bodyClassName="c-patient-add-modal__stage"
        >
            <FormGroup inline>
                <Label left className="c-patient-add-modal__label-col">Nazwa</Label>
                <div className="c-patient-add-modal__input-col">
                    <Select
                        options={stageOptions}
                        value={values.stageId}
                        onChange={handleChange}
                        name="stageId"
                        disabled={disabled}
                    />
                    <ControlErrors errors={errors['stage_id']} />
                </div>
            </FormGroup>
        </Fieldset>
    )
}

PatientFormStageFieldset.propTypes = {
    values: PropTypes.shape({
        stageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    handleChange: PropTypes.func,
    errors: PropTypes.object,
    disabled: PropTypes.bool
}

export default PatientFormStageFieldset
import * as types from '../actions/types/recommendation_template_types';
import recommendationTemplateInitialState from './helpers/recommendation-template-initial-state';

const initialState = recommendationTemplateInitialState;

const recommendationTemplatesReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.RECOMMENDATION_TEMPLATE_FETCH_ALL_REQUEST: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_FETCH_ALL_SUCCESS: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                recommendationTemplates: action.payload.data,
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_FETCH_ALL_FAILURE: {
            const {
                fetchAllRequest
            } = state;

            return {
                ...state,
                recommendationTemplates: [],
                fetchAllRequest: {
                    ...fetchAllRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_ADD_RESET: {
            return {
                ...state,
                addRequest: initialState.addRequest
            }
        }
        case types.RECOMMENDATION_TEMPLATE_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_UPDATE_RESET: {
            return {
                ...state,
                updateRequest: initialState.updateRequest
            }
        }
        case types.RECOMMENDATION_TEMPLATE_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.RECOMMENDATION_TEMPLATE_REMOVE_RESET: {
            return {
                ...state,
                removeRequest: initialState.removeRequest
            }
        }
        case types.RECOMMENDATION_TEMPLATE_SET_IS_EDIT_FORM_VISIBLE: {
            return {
                ...state,
                isEditFormVisible: action.payload.isVisible
            }
        }
        default: 
            return state;
    }
}

export default recommendationTemplatesReducer;
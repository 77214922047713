import { createHttpClient } from './api-service';
import { uploadMedia, downloadImage, convertImage } from './media-service';
import patientEventTypes from '../constants/patient-event-types';

const createEventData = async (event) => {
    const data = {
        type_id: event.type,
        name: event.name,
        regular_price: event.regularPrice,
        duration: event.duration
    };

    const ico = getEventType(event.eventType);

    if (ico) {
        const eventIcon = ico.icon;

        let iconBlobResponse = await downloadImage(eventIcon);

        let iconBlob = iconBlobResponse.data;

        iconBlob = await convertImage(iconBlob, 'image/png');

        const iconFile = new File([iconBlob], `${ico.key}.png`);

        const mediaResponse = await uploadMedia(iconFile);

        // data.event_type = ico.key;
        data.ico_number = ico.key;
        data.image_url = mediaResponse.data.full_url;
    }

    return data;
}

export const getEvents = (params) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/event/list`;

    const data = {
        page: params.page,
        per_page: params.perPage,
        q: params.searchQuery
    };

    return api.post(url, data);
}

export const getEventType = (key) => {
    return patientEventTypes.find(type => type.key === key);
}

export const addEvent = async (event) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/event/create`;

    const data = await createEventData(event);

    return api.post(url, data);
}

export const updateEvent = async (event, eventId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/event/update/${eventId}`;

    const data = await createEventData(event);

    return api.post(url, data);
}

export const removeEvent = (eventId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/event/delete/${eventId}`;

    return api.get(url);
}

export const groupEventsByType = (events) => {
	const eventsByType = [];

	events.forEach((event) => {
		// Find group if it already exists
		let group = eventsByType.find((group) => {
			return (group.type_id === event.type_id);
		});

		if (group) {
			// Append event to existing group
			group.events.push(event);
		} else {
			// Create new group
			// Append event to new group
			const newGroup = {
				type_id: event.type_id,
				type_name: event.type_name,
				events: [event],
			};
			// Append group to result array
			eventsByType.push(newGroup);
		}
	});

	return eventsByType;
};

export const getTreatmentProgram = () => {
    const api = createHttpClient();

    const url = `/dashboard/settings/treatment-program`;

    return api.get(url);
}

export const updateTreatmentProgram = (program) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/update-treatment-program`;

    const data = {
        name: program.name,
        days: program.days,
        states: program.states.map(({ name }) => ({ name }))
    };

    return api.post(url, data);
}

export const createStageData = (stage) => {
    const data = {
        name: stage.name,
        max: stage.max
    }

    return data;
}

export const getStages = (params) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/stage/list`;

    const data = {
        page: params.page,
        per_page: params.perPage,
        q: params.searchQuery
    };

    return api.post(url, data);
}

export const getStage = (stageId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/stage/get/${stageId}`;

    return api.get(url);
}

export const addStage = (stage) => {
	const api = createHttpClient();

	const url = '/dashboard/settings/stage/create';

	const data = createStageData(stage);

	return api.post(url, data);
};

export const updateStage = (stage, stageId) => {
	const api = createHttpClient();

	const url = `/dashboard/settings/stage/update/${stageId}`;

	const data = createStageData(stage);

	return api.post(url, data);
};

export const createCenterTypeData = (centerType) => {
    const data = {
        name: centerType.name
    }

    return data;
}

export const getCenterTypes = (params) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/center-type/list`;

    const data = {
        page: params.page,
        per_page: params.perPage,
        q: params.searchQuery
    };

    return api.post(url, data);
}

export const getCenterType = (centerTypeId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/center-type/get/${centerTypeId}`;

    return api.get(url);
}

export const addCenterType = (centerType) => {
	const api = createHttpClient();

	const url = '/dashboard/settings/center-type/create';

	const data = createCenterTypeData(centerType);

	return api.post(url, data);
};

export const updateCenterType = (centerType, centerTypeId) => {
	const api = createHttpClient();

	const url = `/dashboard/settings/center-type/update/${centerTypeId}`;

	const data = createCenterTypeData(centerType);

	return api.post(url, data);
};

export const getInterventions = () => {
    const api = createHttpClient();

    const data = {
        q: "",
        per_page: 10000,
        page: 1
    }

    const url = `/dashboard/settings/intervention/list`;

    return api.post(url, data);
}

export const addIntervention = async (intervention) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/intervention/create`;

    return api.post(url, intervention);
}

export const updateIntervention = async (intervention, interventionId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/intervention/update/${interventionId}`;

    return api.post(url, intervention);
}

export const removeIntervention = (interventionId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/intervention/delete/${interventionId}`;

    return api.get(url);
}

export const getTypes = () => {
    const api = createHttpClient();

    const data = {
        q: "",
        per_page: 10000,
        page: 1
    }

    const url = `/dashboard/settings/event/type/list`;

    return api.post(url, data);
}

export const addType = async (type) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/event/type/create`;

    return api.post(url, type);
}

export const updateType = async (type, typeId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/event/type/update/${typeId}`;

    return api.post(url, type);
}

export const removeType = (typeId) => {
    const api = createHttpClient();

    const url = `/dashboard/settings/event/type/delete/${typeId}`;

    return api.get(url);
}
export const SURVEY_ADD_REQUEST = 'SURVEY_ADD_REQUEST';
export const SURVEY_ADD_SUCCESS = 'SURVEY_ADD_SUCCESS';
export const SURVEY_ADD_FAILURE = 'SURVEY_ADD_FAILURE';
export const SURVEY_ADD_RESET = 'SURVEY_ADD_RESET';

export const SURVEY_UPDATE_REQUEST = 'SURVEY_UPDATE_REQUEST';
export const SURVEY_UPDATE_SUCCESS = 'SURVEY_UPDATE_SUCCESS';
export const SURVEY_UPDATE_FAILURE = 'SURVEY_UPDATE_FAILURE';
export const SURVEY_UPDATE_RESET = 'SURVEY_UPDATE_RESET';

export const SURVEY_REMOVE_REQUEST = 'SURVEY_REMOVE_REQUEST';
export const SURVEY_REMOVE_SUCCESS = 'SURVEY_REMOVE_SUCCESS';
export const SURVEY_REMOVE_FAILURE = 'SURVEY_REMOVE_FAILURE';
export const SURVEY_REMOVE_RESET = 'SURVEY_REMOVE_RESET';
import { createHttpClient } from './api-service';
import moment from 'moment';
import { apiDateFormat, apiDateTimeFormat } from '../config';
import genders from '../constants/genders';
import Axios from 'axios';

// Import helpers
import { getMonth, getYear } from 'helpers';

export const createPatientData = (patient) => {
	const birthDate = moment(patient.birthDate);
	const kosStartDate = moment(patient.kosStartDate);
	const kosEndDate = moment(patient.kosEndDate);

	const { education } = patient;

	const educationArray = Object.keys(education).filter((key) => education[key]);

	const data = {
		email: patient.email,
		email_confirmation: patient.emailConfirmation,
		first_name: patient.firstName,
		last_name: patient.lastName,
		sex: patient.gender,
		pesel: patient.pesel,
		birthdate: birthDate.isValid() ? birthDate.format(apiDateFormat) : '',
		phone: patient.phoneNumber,
		phone_prefix: patient.phoneNumberCode,
		kos_state: patient.status,
		kos_start_date:
			patient.status && kosStartDate.isValid()
				? kosStartDate.format(apiDateFormat)
				: '',
		kos_end_date:
			patient.status && kosEndDate.isValid()
				? kosEndDate.format(apiDateFormat)
				: '',
		education: educationArray,
		center_id: patient.hospital ? patient.hospital.value : '',
		stage_id: patient.stageId
	};

	return data;
};

export const addPatient = (patient) => {
	const api = createHttpClient();

	const url = '/dashboard/patient/register';

	const data = createPatientData(patient);

	return api.post(url, data);
};

export const updatePatient = (patient, patientId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/update/${patientId}`;

	const data = createPatientData(patient);

	return api.post(url, data);
};

export const createInitialPatientFormValues = (patientData) => {
	const birthDate = moment(patientData.birthdate);

	const kosStartDate = moment(patientData.kos_start_date);

	const kosEndDate = moment(patientData.kos_end_date);

	let education = {};

	patientData.education.forEach((subject) => {
		education[subject] = true;
	});

	const values = {
		firstName: patientData.first_name || '',
		lastName: patientData.last_name || '',
		gender: patientData.sex || '',
		pesel: patientData.pesel || '',
		birthDate: birthDate.isValid() ? birthDate.toDate() : null,
		email: patientData.email || '',
		emailConfirmation: patientData.email || '',
		phoneNumberCode: patientData.phone_prefix || '',
		phoneNumber: patientData.phone || '',
		status: patientData.kos_state || '',
		kosStartDate: kosStartDate.isValid() ? kosStartDate.toDate() : null,
		kosEndDate: kosEndDate.isValid() ? kosEndDate.toDate() : null,
		hospital: patientData.center_id
			? {
				label: patientData.center,
				value: patientData.center_id,
			}
			: null,
		stageId: patientData.stage_id || null,
		stageName: patientData.stage_name || '',
		education,
	};

	return values;
};

export const updatePatientPartially = (patient, patientData) => {
	const defaultValues = createInitialPatientFormValues(patientData);

	const values = {
		...defaultValues,
		...patient,
	};

	return updatePatient(values, patientData.id);
};

export const updateAbilityToWork = (abilityToWork, patientId) => {
	const api = createHttpClient();

	const ability_to_work_date = moment(abilityToWork.abilityToWorkDate);
	const real_return_to_work_date = moment(abilityToWork.realReturnToWorkDate);

	const url = `/dashboard/patient/work/${patientId}`;

	const data = {
		"ability_to_work_status": abilityToWork.abilityToWorkStatus,
		"real_return_to_work_status": abilityToWork.realReturnToWorkStatus,
		"ability_to_work_date": ability_to_work_date.isValid() ? ability_to_work_date.format(apiDateFormat) : null,
		"real_return_to_work_date": real_return_to_work_date.isValid() ? real_return_to_work_date.format(apiDateFormat) : null
	}

	return api.post(url, data);
}

export const getPatient = (patientId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/get/${patientId}`;

	return api.get(url);
};

export const removePatient = (patientId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/delete/${patientId}`;

	return api.get(url);
};

export const getPatients = (params) => {
	const api = createHttpClient();

	const url = '/dashboard/patient/list';

	let data = {
		page: params.page,
		per_page: params.perPage,
		order_by: params.sortField,
		order_dir: params.sortOrder,
		center_id: params.hospitalId,
	};

	if (params.filters) {
		const { query, gender, kosStatus, includedDays, age } = params.filters;

		data = {
			...data,
			q: query || '',
			sex: gender || '',
			kos_state: kosStatus || '',
			kos: includedDays ? parseInt(includedDays.value) : '',
			kos_param: includedDays ? includedDays.operator : '',
		};

		if (age) {
			data = {
				...data,
				age: parseInt(age.value) || '',
				age_param: age.operator || '',
			};
		}
	}

	return api.post(url, data);
};

export const getPatientSuggestions = async ({ perPage, query }) => {
	const response = await getPatients({
        page: 1,
		perPage,
		sortField: '',
		sortOrder: '',
		filters: {
			query
		}
	});
	
	let patients = response.data.data;

	return patients.map((patient) => ({
        label: `${patient.first_name} ${patient.last_name}`,
        value: patient.id,
        data: patient
    }));
}

export const getPatientBonus = (patientId, params) => {
	const api = createHttpClient();

	const url = `/dashboard/bonus/patient/${patientId}`;

	const data = {
		per_page: params.perPage,
		page: params.page
	}

	return api.post(url, data);
};

export const getEvents = (patientId, month = '', year = '') => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/list/${patientId}`;

	const data = {};
	if (!!month) {
		data['month'] = month;
	}
	if (!!year) {
		data['year'] = year;
	}

	return api.post(url, data);
};

export const isSameEventDate = (date1, date2) => {
	return (
		moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD')
	);
};

export const isSameEventDateHour = (date1, date2) => {
	return (
		moment(date1).format('YYYY-MM-DD HH:mm') ===
		moment(date2).format('YYYY-MM-DD HH:mm')
	);
};

export const getDayEvents = async (patientId, date) => {
	const response = await getEvents(patientId, getMonth(date), getYear(date));

	response.data.data = response.data.data.filter((event) => {
		return isSameEventDate(event.start_date, date);
	});

	return response;
};

export const getMonthEvents = async (patientId, date) => {
	const response = await getEvents(patientId, getMonth(date), getYear(date));

	response.data.data = response.data.data.sort((a, b) =>
		moment(a.start_date).format('YYYY-MM-DD hh:mm') <
			moment(b.start_date).format('YYYY-MM-DD hh:mm')
			? -1
			: 1
	);

	return response;
};

export const getEvent = (eventId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/get/${eventId}`;

	return api.get(url);
};

const createEventData = (event, patientId) => {
	const startDate = moment(event.date);

	const startDateHours = event.time.getHours();
	const startDateMinutes = event.time.getMinutes();

	startDate.set('hours', parseInt(startDateHours, 10));
	startDate.set('minutes', parseInt(startDateMinutes, 10));

	const endDate = moment(event.recurrenceTo);

	endDate.set('hours', 23);
	endDate.set('minutes', 59);

	const startDateFormatted = startDate.isValid()
		? startDate.format(apiDateTimeFormat)
		: '';

	const endDateFormatted = endDate.isValid()
		? endDate.format(apiDateTimeFormat)
		: '';

	const data = {
		patient_id: patientId,
		type: event.event,
		title: '',
		center_id: event.hospital.data?.id || event.hospital.value || null,
		start_date: startDateFormatted,
		place_name: event.hospital.label,
		place_address: '',
		who: event.who.label,
		doctor_id: event.who.value,
		recurrence: event.recurrence || 'none',
		monday: event.weeklyRecurrenceDays.monday,
		tuesday: event.weeklyRecurrenceDays.tuesday,
		wednesday: event.weeklyRecurrenceDays.wednesday,
		thursday: event.weeklyRecurrenceDays.thursday,
		friday: event.weeklyRecurrenceDays.friday,
		saturday: event.weeklyRecurrenceDays.saturday,
		sunday: event.weeklyRecurrenceDays.sunday,
		all_day: 0,
		end_date:
			event.recurrence !== 'none' ? endDateFormatted : startDateFormatted,
		send_reminder: event.sendReminder ? 1 : 0,
		reminder_status: event.reminderStatus ? 1 : 0,
		reminder: event.reminder || 0,
		note: event.note,
	};

	return data;
};

export const addEvent = (event, patientId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/create`;

	const data = createEventData(event, patientId);

	return api.post(url, data);
};

export const removeEvent = (eventId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/delete/${eventId}`;

	return api.get(url);
};

export const updateEvent = (event, eventId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/update/${eventId}`;

	const data = createEventData(event);

	return api.post(url, data);
};

export const getEventTypeBonuses = (params, typeId) => {
	const api = createHttpClient();

	const url = `/dashboard/bonus/event-type/${typeId}`;

	const data = {
        page: params.page,
		per_page: params.perPage,
		patient_id: params.patientId
    };

	return api.post(url, data);
}

export const getEventBonuses = (params, eventId) => {
	const api = createHttpClient();

	const url = `/dashboard/bonus/event/${eventId}`;

	const data = {
        page: params.page,
		per_page: params.perPage,
		patient_id: params.patientId
    };

	return api.post(url, data);
}

export const rejectPresenceEvent = (reminder_id) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/reject/${reminder_id}`

	return api.get(url);
}

export const confirmPresenceEvent = (reminder_id) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/acknowledge/${reminder_id}`

	return api.get(url);
}

export const groupEventsByDate = (events) => {
	const eventsByDate = [];

	events.forEach((event) => {
		// Find group if it already exists
		let group = eventsByDate.find((group) => {
			return (
				moment(group.start).format('YYYY-MM-DD') ===
				moment(event.start).format('YYYY-MM-DD')
			);
		});

		if (group) {
			// Append event to existing group
			group.events.push(event);
		} else {
			// Create new group
			// Append event to new group
			const newGroup = {
				start: event.start,
				events: [event],
			};
			// Append group to result array
			eventsByDate.push(newGroup);
		}
	});

	return eventsByDate;
};

export const getKosDays = (startDate, endDate) => {
	const start = moment(startDate);
	const end = moment(endDate);

	return end.diff(start, 'days');
};

export const isEventIncoming = (event) => {
	const now = moment();

	const eventStartDate = moment(event.data.start_date);

	const diff = eventStartDate.diff(now);

	return diff > 0;
};

export const filterIncomingEvents = (events) => {
	return events.filter((event) => {
		return isEventIncoming(event);
	});
};

export const findClosestIncomingEvent = (events) => {
	const incomingEvents = filterIncomingEvents(events);

	let closest;

	incomingEvents.forEach((event) => {
		if (closest) {
			const closestStartDate = moment(closest.data.start_date);
			const currentStartDate = moment(event.data.start_date);

			const diff = closestStartDate.diff(currentStartDate);

			if (diff > 0) {
				closest = event;
			}
		} else {
			closest = event;
		}
	});

	return closest;
};

export const isEventPast = (event) => {
	const now = moment();

	const eventStartDate = moment(event.data.start_date);

	const diff = eventStartDate.diff(now);

	return diff <= 0;
};

export const filterPastEvents = (events) => {
	return events.filter((event) => {
		return isEventPast(event);
	});
};

export const findClosestPastEvent = (events) => {
	const pastEvents = filterPastEvents(events);

	let closest;

	pastEvents.forEach((event) => {
		if (closest) {
			const closestStartDate = moment(closest.data.start_date);
			const currentStartDate = moment(event.data.start_date);

			const diff = closestStartDate.diff(currentStartDate);

			if (diff <= 0) {
				closest = event;
			}
		} else {
			closest = event;
		}
	});

	return closest;
};

export const isEventRejected = (event) => {
	const status = event.data.reminder_data && event.data.reminder_data.status ? event.data.reminder_data.status : null;

	return status === 'rejected';
};

export const filterRejectedEvents = (events) => {
	return events.filter((event) => {
		return isEventRejected(event);
	});
};

export const addEventObservation = (observation, eventId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/create-observation/${eventId}`;

	const data = {
		observation: observation.content,
	};

	return api.post(url, data);
};

export const getAllObservations = (patientId, month, year) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/event/observation/list`;

	const data = {
		patient_id: patientId,
		year,
		month,
	};

	return api.post(url, data);
};

export const getPatientGender = (key) => {
	return genders.find((gender) => gender.value === key);
};

export const getMeasurementsByType = (patientId, type, incominData) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/measurement/list/${patientId}`;

	const data = {
		per_page: 100000000,
		page: 1,
		order_by: 'id',
		order_dir: 'desc',
		type: type,
		...incominData,
	};

	return api.post(url, data);
};

export const getMeasurements = (patientId) => {
	const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
	const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');

	const workoutData = {
		from: startOfWeek,
		to: endOfWeek,
	};

	return Axios.all([
		getMeasurementsByType(patientId, 'workout_steps', workoutData),
		getMeasurementsByType(patientId, 'workout_moderate', workoutData),
		getMeasurementsByType(patientId, 'workout_intensive', workoutData),
		getMeasurementsByType(patientId, 'blood_pressure'),
		getMeasurementsByType(patientId, 'heart_rate'),
		getMeasurementsByType(patientId, 'body_mass'),
		getMeasurementsByType(patientId, 'waist_length'),
		getMeasurementsByType(patientId, 'fat_percent'),
		getMeasurementsByType(patientId, 'saturation'),
		getMeasurementsByType(patientId, 'insulin_got'),
		getMeasurementsByType(patientId, 'glycemic_measurement'),
		getMeasurementsByType(patientId, 'fevi'),
		getMeasurementsByType(patientId, 'fvc'),
		getMeasurementsByType(patientId, 'pef'),
	]).then((responses) => {
		const measurements = [];

		responses.forEach((response) => {
			measurements.push(...response.data.data);
		});

		return measurements;
	});
};

export const groupMeasurementsByDate = (measurements) => {
	const measurementsByDate = [];

	measurements.forEach((measurement) => {
		const measureTime = moment(measurement.measure_time);

		// Find group if it already exists
		let group = measurementsByDate.find((group) => {
			return (
				moment(group.date).format('YYYY-MM-DD') ===
				measureTime.format('YYYY-MM-DD')
			);
		});

		if (group) {
			// Append measurement to existing group
			group.measurements.push(measurement);
		} else {
			// Create new group
			// Append measurement to new group
			const newGroup = {
				date: moment(measurement.measure_time).format('YYYY-MM-DD'),
				measurements: [measurement],
			};
			// Append group to result array
			measurementsByDate.push(newGroup);
		}
	});

	return measurementsByDate;
};

export const groupMeasurementsByWeek = (measurements) => {
	const measurementsByWeek = [];

	measurements.forEach((measurement) => {
		const measureTime = moment(measurement.measure_time);
		// Find group if it already exists
		let group = measurementsByWeek.find((group) => {
			return (
				moment(group.date).format('YYYY-MM-W') ===
				measureTime.format('YYYY-MM-W')
			);
		});

		if (group) {
			// Append measurement to existing group
			group.measurements.push(measurement);
		} else {
			// Create new group
			// Append measurement to new group
			const newGroup = {
				date: measureTime.format('YYYY-MM-DD'),
				measurements: [measurement],
			};
			// Append group to result array
			measurementsByWeek.push(newGroup);
		}
	});

	return measurementsByWeek;
};

export const groupMeasurementsByMonth = (measurements) => {
	const measurementsByMonth = [];

	measurements.forEach((measurement) => {
		const measureTime = moment(measurement.measure_time);

		// Find group if it already exists
		let group = measurementsByMonth.find((group) => {
			return (
				moment(group.date).format('YYYY-MM') === measureTime.format('YYYY-MM')
			);
		});

		if (group) {
			// Append measurement to existing group
			group.measurements.push(measurement);
		} else {
			// Create new group
			// Append measurement to new group
			const newGroup = {
				date: measureTime.format('YYYY-MM'),
				measurements: [measurement],
			};
			// Append group to result array
			measurementsByMonth.push(newGroup);
		}
	});

	return measurementsByMonth;
};

export const sortMeasurements = (measurements) => {
	return [...measurements].sort((a, b) =>
		moment(b.measure_time).diff(moment(a.measure_time))
	);
};

export const sumMeasurementsValues = (measurements) => {
	return measurements.reduce(
		(sums, measurement) => {
			return {
				value1: sums.value1 + measurement.value1,
				value2: sums.value2 + measurement.value2,
			};
		},
		{
			value1: 0,
			value2: 0,
		}
	);
};

export const getMeasurementsFromEachDay = (measurements) => {
	const byDay = {};

	measurements.forEach((item) => {
		const day = moment(item.measure_time).format('YYYY-MM-DD');

		if (byDay[day]) {
			byDay[day].push(item);
		} else {
			byDay[day] = [item];
		}
	});

	const maxValueFromEachDay = [];

	Object.keys(byDay).forEach((key) => {
		let maxValue = moment(byDay[key][0].value1);
		let maxItem = byDay[key][0];

		for (let i = 1; i < byDay[key].length; i++) {
			if (moment(byDay[key][i].value1) > maxValue) {
				maxValue = moment(byDay[key][i].value1);
				maxItem = byDay[key][i];
			}
		}

		maxValueFromEachDay.push(maxItem);
	});

	return maxValueFromEachDay;
};

export const filtersMeasurementsInDateRange = (
	measurements,
	dateFrom,
	dateTo
) => {
	const from = moment(dateFrom);
	const to = moment(dateTo);

	return measurements.filter((measurement) => {
		const measureTime = moment(measurement.measure_time);

		return measureTime.isAfter(from) && measureTime.isBefore(to);
	});
};

export const filtersMeasurementsByWeekNumber = (measurements, weekNum) => {
	return measurements.filter((measurement) => {
		const measureWeekNum = moment(measurement.measure_time).week();

		return measureWeekNum === weekNum;
	});
};

export const updateSettings = (settings, patientId) => {
	const api = createHttpClient();

	const url = `/dashboard/patient/settings/${patientId}`;

	const data = {
		...settings,
	};

	return api.post(url, data);
};

export const getSurveys = (patientId, params) => {
    const api = createHttpClient();

    const url = `/dashboard/patient/poll/${patientId}`;

    const data = {
        per_page: params.perPage,
		page: params.page,
		order_by: params.orderBy,
    	order_dir: params.orderDir
    }

    return api.post(url, data);
}

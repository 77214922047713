import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

const defaultStyles = {
    overlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '32px 20px',
        maxWidth: '100%',
        background: 'rgba(0, 0, 0, 0.4)',
    },
    content: {
        position: 'static',
        transform: 'none',
        padding: '0',
        background: 'transparent',
        border: 'none',
        borderRadio: '0',
        overflow: 'auto',
        margin: 'auto',
        maxWidth: '100%'
    }
};

export default class Modal extends React.Component {
    render() {
        return (
            <ReactModal 
                style={{
                    ...defaultStyles, 
                    ...this.props.customStyles
                }} 
                closeTimeoutMS={200}
                shouldCloseOnOverlayClick={true}
                {...this.props} 
            >
                {this.props.children}
            </ReactModal>
        );
    }
}

Modal.propTypes = {
    customStyles: PropTypes.object,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onAfterOpen: PropTypes.func,
    shouldCloseOnOverlayClick: PropTypes.bool,
    children: PropTypes.node
}
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Fieldset from '../../common/Fieldset';
import FormGroup from '../../common/FormGroup';
import Label from '../../common/Label';
import DateInput from '../../common/DateInput';
import Grid from '../../common/Grid';
import GridItem from '../../common/GridItem';
import ControlErrors from '../../common/ControlErrors';
import Button from '../../common/Button';

const PatientFormTreatmentProgramFieldset = ({
    values,
    handleChange,
    setFieldValue,
    errors,
    disabled
}) => {

    const {
        treatmentProgram
    } = useSelector(store => store.appParams);

    if (!treatmentProgram) {
        return null;
    }

    const handleKosStartDateChange = (ev) => {
        if (ev.target.value instanceof Date) {
            const endDate = moment(ev.target.value).add(treatmentProgram, 'days').toDate();
            setFieldValue('kosEndDate', endDate);
        }

        handleChange(ev);
    }

    return (
        <Fieldset
            legend="Program leczenia (KOS)"
        >
            <div className="c-patient-add-modal__treatment-program">
                <FormGroup inline>
                    <Label left className="c-patient-add-modal__label-col">Status</Label>
                    <div className="c-patient-add-modal__input-col">
                        <Grid gap="8">
                            <GridItem col="auto">
                                <Button
                                    variant={values.status === 'none' ? 'success' : 'light'}
                                    title={'Brak'}
                                    style={{ padding: '0 10px' }}
                                    onClick={() => setFieldValue('status', 'none')}
                                    disabled={disabled}
                                />
                            </GridItem>
                            {treatmentProgram.states.data.map((state, i) => (
                                <GridItem col="auto" key={i}>
                                    <Button
                                        variant={values.status === state.name ? 'success' : 'light'}
                                        title={state.name}
                                        style={{ padding: '0 10px' }}
                                        onClick={() => setFieldValue('status', state.name)}
                                        disabled={disabled}
                                    />
                                </GridItem>
                            ))}
                        </Grid>
                        <ControlErrors errors={errors['kos_state']} />
                    </div>
                </FormGroup>

                {values.status !== 'none' &&
                    <>
                        <FormGroup inline>
                            <Label left className="c-patient-add-modal__label-col">Data włączenia*</Label>
                            <div className="c-patient-add-modal__input-col">
                                <DateInput
                                    value={values.kosStartDate}
                                    onChange={handleKosStartDateChange}
                                    setFieldValue={setFieldValue}
                                    name="kosStartDate"
                                    disabled={disabled}
                                />
                                <ControlErrors errors={errors['kos_start_date']} />
                            </div>
                        </FormGroup>

                        <FormGroup inline>
                            <Label left className="c-patient-add-modal__label-col">Data zakończenia</Label>
                            <div className="c-patient-add-modal__input-col">
                                <DateInput
                                    value={values.kosEndDate}
                                    onChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    name="kosEndDate"
                                    disabled={disabled}
                                />
                                <ControlErrors errors={errors['kos_end_date']} />
                            </div>
                        </FormGroup>
                    </>
                }

            </div>


            <div className="c-patient-add-modal__info">
                *Data pełnej rewaskularyzacji
            </div>
        </Fieldset>
    )
}

PatientFormTreatmentProgramFieldset.propTypes = {
    disabled: PropTypes.bool,
    values: PropTypes.shape({
        status: PropTypes.string,
        kosStartDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }),
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    errors: PropTypes.object
};

export default PatientFormTreatmentProgramFieldset;
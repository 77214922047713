import * as types from './types/hospital_events_types';

import * as HospitalService from '../../services/hospital-service';

export const clearHospitalEventsState = () => ({
    type: types.CLEAR_HOSPITAL_EVENTS_STATE,
    payload: null
});

export const fetchEvents = (hospitalId, startDate, endDate) => async dispatch => {
    dispatch({
        type: types.HOSPITAL_EVENTS_FETCH_EVENTS_REQUEST
    })

    try {
        const response = await HospitalService.getEvents(hospitalId, startDate, endDate);

        dispatch({
            type: types.HOSPITAL_EVENTS_FETCH_EVENTS_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: types.HOSPITAL_EVENTS_FETCH_EVENTS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const setCalendarDate = (startWeek, endWeek) => {
    return {
        type: types.HOSPITAL_EVENTS_SET_CALENDAR_DATE,
        payload: { startWeek, endWeek }
    }
}

export const setSelectDateHour = (date) => async dispatch => {
    dispatch({
        type: types.HOSPITAL_EVENTS_SET_SELECTED_DATE_HOUR,
        payload: date
    })
};

export const setClosestEvent = (event) => ({
    type: types.HOSPITAL_EVENTS_SET_CLOSEST_EVENT,
    payload: event
})
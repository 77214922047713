import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Fieldset from '../../common/Fieldset';
import Checkbox from '../../common/Checkbox';
import Label from '../../common/Label';
import Grid from '../../common/Grid';
import GridItem from '../../common/GridItem';

const PatientFormEductionalIssuesFieldset = ({
    values,
    handleChange,
    disabled
}) => {
    const {
        subjects
    } = useSelector(store => store.educationSubjects);

    return (
        <Fieldset
            legend="Zagadnienia edukacyjne"
        >
            <div className="c-patient-add-modal__educational-issues">
                <Grid justify-start gap="12">
                    {subjects.map((subject) => {
                        return (
                            <GridItem
                                col="12"
                                key={subject.id}
                            >
                                <div className="u-d-flex">
                                    <div style={{paddingTop: 1}}>
                                        <Checkbox
                                            variant="success"
                                            checked={values.education[subject.id]}
                                            onChange={handleChange}
                                            disabled={disabled}
                                            name={`education.${subject.id}`}
                                        />
                                    </div>
                                    <Label className="o-checkbox-list__item-label">{subject.name}</Label>
                                </div>
                            </GridItem>
                        )
                    })}
                </Grid>
            </div>
            <div className="c-patient-add-modal__info c-patient-add-modal__info--educational-issues">
                *Aby w aplikacji pacjenta wyświetlane były treści edukacyjne, wymagane jest dodanie chorób współistniejących.
            </div>
        </Fieldset>
    )
}


PatientFormEductionalIssuesFieldset.propTypes = {
    disabled: PropTypes.bool,
    values: PropTypes.shape({
        education: PropTypes.object
    }),
    handleChange: PropTypes.func
}

export default PatientFormEductionalIssuesFieldset;
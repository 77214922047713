import {
    PATIENT_ADD_MODAL_HIDE,
    PATIENT_ADD_MODAL_SHOW,
    PATIENT_EDIT_MODAL_SHOW,
    PATIENT_EDIT_MODAL_HIDE,
    PATIENT_ADD_REQUEST,
    PATIENT_ADD_SUCCESS,
    PATIENT_ADD_FAILURE,
    PATIENT_ADD_RESET,
    PATIENT_UPDATE_REQUEST,
    PATIENT_UPDATE_SUCCESS,
    PATIENT_UPDATE_FAILURE,
    PATIENT_UPDATE_RESET,
    PATIENT_REMOVE_REQUEST,
    PATIENT_REMOVE_SUCCESS,
    PATIENT_REMOVE_FAILURE,
    PATIENT_REMOVE_RESET,
    PATIENT_UPDATE_SETTINGS_REQUEST,
    PATIENT_UPDATE_SETTINGS_SUCCESS,
    PATIENT_UPDATE_SETTINGS_FAILURE
} from "../actions/types";

const initialState = {
    isPatientAddModalOpen: false,
    isPatientEditModalOpen: false,
    addRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateSettingsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
};

const patientReducer = (state = initialState, action) => {
    switch (action.type) {
        case PATIENT_ADD_MODAL_SHOW:
            return {
                ...state,
                isPatientAddModalOpen: true
            }
        case PATIENT_ADD_MODAL_HIDE:
            return {
                ...state,
                isPatientAddModalOpen: false
            }
        case PATIENT_EDIT_MODAL_SHOW:
            return {
                ...state,
                isPatientEditModalOpen: true
            }
        case PATIENT_EDIT_MODAL_HIDE:
            return {
                ...state,
                isPatientEditModalOpen: false
            }
        case PATIENT_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_ADD_RESET: {
            return {
                ...state,
                addRequest: initialState.addRequest
            }
        }
        case PATIENT_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_UPDATE_RESET: {
            return {
                ...state,
                updateRequest: initialState.updateRequest
            }
        }
        case PATIENT_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_REMOVE_RESET: {
            return {
                ...state,
                removeRequest: initialState.removeRequest
            }
        }
        case PATIENT_UPDATE_SETTINGS_REQUEST: {
            const {
                updateSettingsRequest
            } = state;

            return {
                ...state,
                updateSettingsRequest: {
                    ...updateSettingsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_UPDATE_SETTINGS_SUCCESS: {
            const {
                updateSettingsRequest
            } = state;

            return {
                ...state,
                updateSettingsRequest: {
                    ...updateSettingsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_UPDATE_SETTINGS_FAILURE: {
            const {
                updateSettingsRequest
            } = state;

            return {
                ...state,
                updateSettingsRequest: {
                    ...updateSettingsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        default:
            return state;
    }
}

export default patientReducer;
import React from "react";
import moment from 'moment';

// @utc - if date in utc forma
export const Date = ({ date, format, utc }) => {

    const handleFormatDate = () => {
        let localDate;
        if (utc) {
            localDate = moment.utc(date).local();
        } else {
            localDate = moment(date);
        }

        return localDate.format(format);
    }

    return (
        <React.Fragment>
            {date && handleFormatDate()}
        </React.Fragment>
    )
}

Date.defaultProps = {
    format: 'DD.MM.YYYY'
}

export default Date;
import React, { useState, useEffect } from 'react';

import { AutoComplete as PrimeAutoComplete } from 'primereact/autocomplete';

/**
 * Prime AutoComplete doesn't provide option to force selection from dropdown. 
 * This component forces selection from dropdown
 */
const AutoComplete = (props) => {

    const [value, setValue] = useState(null);

    const [isValueSelectedFromDropdown, setIsValueSelectedFromDropdown] = useState(false);

    const createClassName = () => {
        const classNames = [];

        if (props.size) {
            classNames.push(`p-autocomplete--size-${props.size}`);
        }

        if (props.variant) {
            classNames.push(`p-autocomplete-variant-${props.variant}`)
        }

        return classNames.join(' ');
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (ev) => {
        ev.originalEvent.persist();

        setValue(ev.target.value);

        // Force selection from dropdown
        // Check if change event is caused by selection from dropdown
        if (ev.originalEvent.type !== 'change') {
            setIsValueSelectedFromDropdown(true);

            // Change event is caused by selection from dropdown, so onChange event can be emitted
            if (ev.target.value !== props.value) {
                props.onChange && props.onChange(ev);
            }
        } else {
            setIsValueSelectedFromDropdown(false);
        }
    }

    const handleBlur = (ev) => {
        // Reset to previous value if change wasn't caused by selection from dropdown 
        if (!isValueSelectedFromDropdown) {
            setValue(props.value);
        }

        props.onBlur && props.onBlur(ev);
    }

    return (
        <PrimeAutoComplete
            name={props.name}
            disabled={props.disabled}
            field={props.field}
            suggestions={props.suggestions}
            completeMethod={props.completeMethod}
            className={createClassName()}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            delay={600}
            dropdown={props.dropdown}
        />
    );
};


export default AutoComplete;
import { 
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE, 
    USER_LOGOUT,
    USER_FETCH_SELF_REQUEST,
    USER_FETCH_SELF_SUCCESS,
    USER_FETCH_SELF_FAILURE
} from './types';

import * as AuthService from '../../services/auth-service';

export const login = ({ email, password }) => async (dispatch) => {
    dispatch({
        type: USER_LOGIN_REQUEST
    })

    try {
        const response = await AuthService.login({ email, password });
        
        const user = response.data;

        AuthService.saveToken(user.access_token);

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: {
                user
            }
        });

        return response;
    } catch (err) {
        
        const {
            status,
            data
        } = err.response;

        dispatch({
            type: USER_LOGIN_FAILURE,
            payload: {
                status,
                data
            }
        })

        AuthService.removeToken();

        return err;
    }
}

export const logout = () => {
    AuthService.removeToken();
    
    return {
        type: USER_LOGOUT
    }
}

export const loadSelf = () => async (dispatch) => {
    dispatch({
        type: USER_FETCH_SELF_REQUEST
    })

    try {
        const response = await AuthService.getSelf();
        
        const user = response.data;

        AuthService.saveToken(user.access_token);

        dispatch({
            type: USER_FETCH_SELF_SUCCESS,
            payload: {
                user
            }
        });

        return response;
    } catch (err) {
        dispatch({
            type: USER_FETCH_SELF_FAILURE
        })

        AuthService.removeToken();

        return err;
    }
}
import React from 'react';
import PropTypes from 'prop-types';

import TableSortIcon from './TableSortIcon';

const TableHeaderCell = (props) => {

    const createClassName = () => {
        const classNames = ['o-datatable__cell o-datatable__cell--header'];

        if (props.sortable) {
            classNames.push('o-datatable__cell--sortable');
        }

        return classNames.join(' ');
    }

    const handleClick = () => {
        if (props.sortable) {
            props.onSort({
                sortField: props.field
            })
        }
    }

    return (
        <th 
            className={createClassName()}
            onClick={handleClick}
            style={props.style}
        >
            <span>{props.header}</span>
            {props.sortable && 
                <TableSortIcon 
                    isSortedBy={props.isSortedBy}
                    sortOrder={props.sortOrder}
                />
            }
        </th>
    )
};

TableHeaderCell.propTypes = {
    sortable: PropTypes.bool,
    field: PropTypes.string,
    style: PropTypes.object,
    header: PropTypes.string,
    isSortedBy: PropTypes.bool,
    sortOrder: PropTypes.oneOf(['', 'asc', 'desc'])
};

export default TableHeaderCell;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import HttpStatus from 'http-status-codes';
import toastr from 'toastr';
import useDidMount from '@rooks/use-did-mount';
import useWillUnmount from '@rooks/use-will-unmount';
import usePrevious from '@rooks/use-previous';

import ProgressSpinner from '../common/ProgressSpinner';
import Suspense from '../common/Suspense';
import Button from '../common/Button';
import Grid from '../common/Grid';
import GridItem from '../common/GridItem';
import PatientFormEducationalIssuesFieldset from './PatientForm/PatientFormEducationalIssuesFieldset';
import PatientFormPersonalDataFieldset from './PatientForm/PatientFormPersonalDataFieldset';
import PatientFormTreatmentProgramFieldset from './PatientForm/PatientFormTreatmentProgramFieldset';
import PatientFormStageFieldset from './PatientForm/PatientFormStageFieldset';

import patientFormInitialValues from './PatientForm/helpers/patient-form-initial-values';
import { addPatient, resetAddPatientRequest } from '../../store/actions/patient-actions';
import PatientFormHospitalFieldset from './PatientForm/PatientFormHospitalFieldset';
import { fetchSubjects } from '../../store/actions/education-subjects-actions';
import { fetchTreatmentProgram, fetchStages } from '../../store/actions/app-params-actions';

import validate from './validation';

const PatientAddForm = (props) => {

    const [backendErrors, setBackendErrors] = useState({});

    const [isLoadingAllOptions, setIsLoadingAllOptions] = useState(false);

    const patient = useSelector(store => store.patient);

    const dispatch = useDispatch();

    const error = patient.addRequest.error;

    const isSuccess = patient.addRequest.isSuccess;

    const prevIsSuccess = usePrevious(isSuccess);

    const {
        subjects
    } = useSelector(store => store.educationSubjects)

    const getAllOptions = async () => {
        try {
            setIsLoadingAllOptions(true);

            await Promise.all([
                dispatch(
                    fetchSubjects()
                ),

                dispatch(
                    fetchTreatmentProgram()
                ),

                dispatch(
                    fetchStages()
                )
            ]);

            setIsLoadingAllOptions(false);
        } catch (e) {
            toastr.error('Nie udało się pobrać wszystkich informacji');

            props.onRequestClose();
        }
    }

    const handleSubmit = async (values, actions) => {
        dispatch(
            addPatient(values, actions)
        );
    }

    useWillUnmount(() => {
        dispatch(
            resetAddPatientRequest()
        )
    });

    useEffect(() => {
        if (isSuccess && !prevIsSuccess) {
            toastr.success('Pacjent został dodany');
            props.onRequestClose();
        }
    }, [isSuccess, prevIsSuccess, props]);

    useEffect(() => {
        if (!error) {
            setBackendErrors({});

            return;
        }

        const {
            status,
            data: {
                errors,
                message
            }
        } = error;

        if (status === HttpStatus.UNPROCESSABLE_ENTITY) {
            setBackendErrors(errors);
            toastr.error('Wprowadź poprawne dane')
        } else if (message) {
            setBackendErrors({});
            toastr.error(message);
        } else {
            setBackendErrors({});
        }
    }, [error]);

    useDidMount(() => {
        getAllOptions();
    });

    const createInitialValues = () => {
        const initialValues = { ...patientFormInitialValues };

        subjects.forEach(subject => {
            initialValues.education[subject.id] = false;
        })

        return initialValues;
    };


    return (
        <Suspense
            isLoading={isLoadingAllOptions}
            fallback={<ProgressSpinner />}
        >
            <Formik
                enableReinitialize={true}
                initialValues={createInitialValues()}
                onSubmit={handleSubmit}
                validate={validate}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit
                    }) => (

                            <form
                                className="o-modal c-patient-add-modal"
                                onSubmit={handleSubmit}
                            >
                                <div className="o-modal__header">Nowy pacjent</div>
                                <div className="o-modal__body">
                                    <PatientFormPersonalDataFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        errors={backendErrors}
                                        frontErrors={errors}
                                        touched={touched}
                                    />

                                    <PatientFormHospitalFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                    />

                                    <PatientFormTreatmentProgramFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                        setFieldValue={setFieldValue}
                                    />

                                    <PatientFormStageFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                    />

                                    <PatientFormEducationalIssuesFieldset
                                        values={values}
                                        handleChange={handleChange}
                                        errors={backendErrors}
                                    />
                                </div>
                                <div className="o-modal__footer c-patient-add-modal__footer">
                                    <Grid justify-end gap="8">
                                        <GridItem col="auto">
                                            <Button
                                                variant="light"
                                                title="Anuluj"
                                                className="c-patient-add-modal__btn"
                                                onClick={props.onRequestClose}
                                            />
                                        </GridItem>
                                        <GridItem col="auto">
                                            <Button
                                                type="submit"
                                                variant="success"
                                                title="Dodaj"
                                                className="c-patient-add-modal__btn"
                                            />
                                        </GridItem>
                                    </Grid>
                                </div>
                            </form>
                        )
                }
            </Formik>
        </Suspense>
    )
};

PatientAddForm.propTypes = {
    onRequestClose: PropTypes.func
};

export default PatientAddForm;
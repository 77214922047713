import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Grid from '../common/Grid';
import GridItem from '../common/GridItem';
import Badge from '../common/Badge';
import Date from '../common/Date';

import * as ClosestEventsService from '../../services/closest-events-service';


const useClosestEventsTableCore = (props) => {

	const [events, setEvents] = useState([]);

	const getClosestEvents = async (filters) => {
		const response = await ClosestEventsService.getClosestEvents(filters);

		return response.data.data;
	};

    const getData = async () => {
        const events = await getClosestEvents(props.filters);

        setEvents(events);
    }


    const renderTime = (rowData) => {
        return moment(rowData.start_date).format('HH:mm')
    }

    const renderLastName = (rowData, isLink) => {
        return <span className="text-block-ellipsis" title={rowData.user_last_name}>
            {isLink ? (
                    <Link to={`/patient/${rowData.user_id}/events`} className="u-link-datatable text-ellipsis">
                        {rowData.user_last_name}
                    </Link>
                )
            : rowData.user_last_name}
        </span>
    }

    const renderFirstName = (rowData, isLink) => {
        return <span className="text-block-ellipsis" title={rowData.user_first_name}>
            {isLink ? (
                <Link to={`/patient/${rowData.user_id}/events`} className="u-link-datatable">
                    {rowData.user_first_name}
                </Link>
                )
            : rowData.user_first_name}
        </span>
    }

    const renderGender = (rowData) => {
        if (rowData.sex === 'm') {
            return 'Mężczyzna';
        } else if (rowData.sex === 'f') {
            return 'Kobieta';
        }

        return '';
    }

    const renderTreatmentProgram = (rowData) => {

        return (
            <div className="c-patient-table__treatment-program">
                <Grid align-center gap-x="40">
                    <GridItem col="14">
                        {rowData.kos_state === 'none' ? 'Brak' : rowData.kos_state}
                    </GridItem>
                    <GridItem col="10">
                        {!!rowData.kos_day_left &&
                            <Badge variant="info" size="md" text={rowData.kos_day_left} />
                        }
                        {!rowData.kos_day_left &&
                            <Badge variant="secondary" size="md" text="--" />
                        }
                    </GridItem>
                </Grid>
            </div>
        )
    }

    const renderBirthDate = (rowData) => {
        return (
            <>
                {rowData.birthdate &&
                    <span>
                        <span><Date date={rowData.birthdate} /></span>
                        <span className="u-color-info"> ({rowData.age} l.)</span>
                    </span>
                }
            </>
        )
    }

    const renderStatus = (rowData) => {
        const reminderData = rowData.reminder_data;
        const status = reminderData && reminderData.status ? reminderData.status : null;
    
        const statusText = status === 'confirmed' ? 'Potwierdzona' : (
            status === 'rejected' ? <span style={{color: '#FF0000'}}>Odwołana</span> : 'Niepotwierdzona'
        );

        return (
            <>
                {reminderData && statusText}
            </>
        )
    }


	useEffect(() => {
        getData();

        // eslint-disable-next-line
	}, [props.filters]);

	return {
        events,
        renderFunctions: {
            "start_date": renderTime,
            "last_name": renderLastName,
            "first_name": renderFirstName,
            "gender": renderGender,
            "treatment_program": renderTreatmentProgram,
            "birthdate": renderBirthDate,
            "status": renderStatus
        }
	};
};

export default useClosestEventsTableCore;

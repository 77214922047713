import moment from 'moment';

import { createHttpClient } from './api-service';

import { apiDateTimeFormat } from 'config';


const createHospitalData = (hospital) => {
    const { events } = hospital;

    const eventsArray = [];

    Object.keys(events).forEach((typeKey) => {
        if (events[typeKey][typeKey]) {
            Object.keys(events[typeKey].events).forEach((key) => {
                if (events[typeKey].events[key]) {
                    eventsArray.push(parseInt(key, 10))
                }
            })
        }
    });

    const data = {
        name: hospital.name,
        street: hospital.streetName,
        city: hospital.city,
        voivodeship: hospital.voivodeship,
        type_id: hospital.typeId,
        chat: hospital.chat,
        zip_code: hospital.postalCode,
        street_number: hospital.streetNumber,
        parent_id: hospital.parent ? hospital.parent.value : '',
        monday: hospital.workingDays.monday.active,
        monday_from: hospital.workingDays.monday.from,
        monday_to: hospital.workingDays.monday.to,
        tuesday: hospital.workingDays.tuesday.active,
        tuesday_from: hospital.workingDays.tuesday.from,
        tuesday_to: hospital.workingDays.tuesday.to,
        wednesday: hospital.workingDays.wednesday.active,
        wednesday_from: hospital.workingDays.wednesday.from,
        wednesday_to: hospital.workingDays.wednesday.to,
        thursday: hospital.workingDays.thursday.active,
        thursday_from: hospital.workingDays.thursday.from,
        thursday_to: hospital.workingDays.thursday.to,
        friday: hospital.workingDays.friday.active,
        friday_from: hospital.workingDays.friday.from,
        friday_to: hospital.workingDays.friday.to,
        saturday: hospital.workingDays.saturday.active,
        saturday_from: hospital.workingDays.saturday.from,
        saturday_to: hospital.workingDays.saturday.to,
        sunday: hospital.workingDays.sunday.active,
        sunday_from: hospital.workingDays.sunday.from,
        sunday_to: hospital.workingDays.sunday.to,
        emails: hospital.emails.map(({ name, address }) => ({
            name,
            email: address
        })),
        phones: hospital.phones.map(({ name, code, number }) => ({
            name,
            phone: `${number}`,
            phone_prefix: `${code}`
        })),
        visits: eventsArray
    };

    return data;
}

export const addHospital = (hospital) => {
    const api = createHttpClient();

    const url = '/dashboard/centers/create';

    const data = createHospitalData(hospital);

    return api.post(url, data);
}

export const updateHospital = (hospital, hospitalId) => {
    const api = createHttpClient();

    const url = `/dashboard/centers/update/${hospitalId}`;

    const data = createHospitalData(hospital);

    return api.post(url, data);
}

export const getHospitals = (params) => {
    const api = createHttpClient();

    const url = '/dashboard/centers/list';

    const data = {
        page: params.page,
        per_page: params.perPage,
        q: params.query
    };

    return api.post(url, data);
};

export const getFlatHospitals = (params) => {
    const api = createHttpClient();

    const url = `/dashboard/centers/list-simple`;

    const data = {
        page: params.page,
        per_page: params.perPage,
        q: params.query
    };

    return api.post(url, data);

}

export const getHospital = (hospitalId) => {
    const api = createHttpClient();

    const url = `/dashboard/centers/get/${hospitalId}`;

    return api.get(url);
}

export const getHospitalDoctors = (params) => {
    const api = createHttpClient();

    const url = '/dashboard/user/list';

    const data = {
        page: 1,
        per_page: 999999,
        q: params.query,
        "center_id": params.centerId || null,
        "role": "doctor",
        "search_fields": [
            "first_name",
            "last_name"
        ]
    };

    return api.post(url, data);
};


export const createHospitalsTreeNodes = (hospitals) => {
    let nodes = [];

    hospitals.forEach(hospital => {
        const {
            children,
            ...data
        } = hospital;

        nodes.push({
            children: createHospitalsTreeNodes(children.data),
            data
        });
    });

    return nodes;
}

export const removeHospital = (hospitalId) => {
    const api = createHttpClient();

    const url = `/dashboard/centers/delete/${hospitalId}`;

    return api.get(url);
}


export const getHospitalSuggestions = async ({ perPage, query }) => {
    let page = 1;

    const response = await getFlatHospitals({
        page,
        perPage,
        query
    });

    let hospitals = response.data.data;

    let hasMorePages = response.data.meta.has_more_pages;

    // load hospitals while list not end
    while (hasMorePages) {
        page++;

        const responseMore = await getFlatHospitals({
            page,
            perPage,
            query
        });

        hospitals = [...hospitals, ...responseMore.data.data];

        hasMorePages = responseMore.data.meta.has_more_pages;
    }

    return hospitals.map((hospital) => ({
        label: hospital.name,
        value: hospital.id,
        parent: hospital.parent,
        data: hospital
    }));
}

export const getHospitalDoctorSuggestions = async ({ centerId, query }) => {

    const { data: { data: doctors } } = await getHospitalDoctors({
        centerId,
        query
    });

    return doctors.map((doctor) => ({
        label: `${doctor.first_name} ${doctor.last_name}`,
        value: doctor.id,
        data: doctor
    }));
}

export const getEvents = (hospitalId, startDate, endDate) => {
	const api = createHttpClient();

	const url = `/dashboard/event/center-list/${hospitalId}`;

	const data = {
        q: ''
    };
	if (!!startDate) {
		data.from = moment(startDate).format(apiDateTimeFormat);
	}
	if (!!endDate) {
		data.to = moment(endDate).format(apiDateTimeFormat);
	}

	return api.post(url, data);
};
import React from 'react';
import PropTypes from 'prop-types';

import TableHeaderCell from './TableHeaderCell';

const TableHeader = (props) => {
    const createTableClassName = () => {
        const classNames = ['o-datatable__table'];

        if (props.tableClassName) {
            classNames.push(props.tableClassName);
        }

        return classNames.join(' ');
    }
    
    return (
        <div 
            className="o-datatable__header" 
            style={{marginRight: props.bodyScrollbarWidth}}
        >
            <table className={createTableClassName()}>
                <thead>
                    <tr className="o-datatable__row">
                        {props.columns.map(col => (
                            <TableHeaderCell 
                                key={col.props.field} 
                                field={col.props.field} 
                                sortable={col.props.sortable}
                                isSortedBy={props.sortField === col.props.field}
                                sortOrder={props.sortOrder}
                                onSort={props.onSort}
                                header={col.props.header}      
                                style={col.props.style}
                            />  
                        ))}
                    </tr>
                </thead>
            </table>
        </div>
    );
}

TableHeader.propTypes = {
    tableClassName: PropTypes.string,
    bodyScrollbarWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    columns: PropTypes.arrayOf(PropTypes.node)
};

export default TableHeader
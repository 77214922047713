import {
    CLEAR_PATIENT_EVENTS_STATE,
    PATIENT_EVENTS_FETCH_EVENTS_REQUEST,
    PATIENT_EVENTS_FETCH_EVENTS_SUCCESS,
    PATIENT_EVENTS_FETCH_EVENTS_FAILURE,
    PATIENT_EVENTS_SET_SELECTED_DATE,
    PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_REQUEST,
    PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_SUCCESS,
    PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_FAILURE,
    PATIENT_EVENTS_SET_SELECTED_EVENT,
    PATIENT_EVENTS_SET_MANAGEMENT_MODE,
    PATIENT_EVENTS_ADD_EVENT_REQUEST,
    PATIENT_EVENTS_ADD_EVENT_FAILURE,
    PATIENT_EVENTS_ADD_EVENT_SUCCESS,
    PATIENT_EVENTS_ADD_EVENT_RESET,
    PATIENT_EVENTS_REMOVE_EVENT_REQUEST,
    PATIENT_EVENTS_REMOVE_EVENT_FAILURE,
    PATIENT_EVENTS_REMOVE_EVENT_SUCCESS,
    PATIENT_EVENTS_REMOVE_EVENT_RESET,
    PATIENT_EVENTS_UPDATE_EVENT_REQUEST,
    PATIENT_EVENTS_UPDATE_EVENT_SUCCESS,
    PATIENT_EVENTS_UPDATE_EVENT_FAILURE,
    PATIENT_EVENTS_UPDATE_EVENT_RESET,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_REQUEST,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_SUCCESS,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_FAILURE,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_RESET,
    EVENT_REMOVE_MODAL_SHOW,
    EVENT_REMOVE_MODAL_HIDE,
    PATIENT_EVENTS_SET_CALENDAR_DATE,
    PATIENT_EVENTS_SET_EVENT_CREATE_DATE,
    PATIENT_EVENTS_SET_CALENDAR_MODE,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_REQUEST,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_SUCCESS,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_FAILURE,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_RESET,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_REQUEST,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_SUCCESS,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_FAILURE,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_RESET
} from "./types/patient_events_types";

import * as PatientService from '../../services/patient-service';

export const clearPatientEventsState = () => ({
    type: CLEAR_PATIENT_EVENTS_STATE,
    payload: null
});

export const fetchEvents = (patientId, month = '', year = '') => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_FETCH_EVENTS_REQUEST
    })

    try {
        const response = await PatientService.getEvents(patientId, month, year);

        dispatch({
            type: PATIENT_EVENTS_FETCH_EVENTS_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: PATIENT_EVENTS_FETCH_EVENTS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const fetchSelectedDateEvents = (patientId, date) => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_REQUEST
    })

    try {
        const response = await PatientService.getMonthEvents(patientId, date);

        dispatch({
            type: PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_SUCCESS,
            payload: response.data
        })

        if (!response.data.data.length) {
            dispatch(
                setManagementMode('add_event')
            );

            dispatch(
                selectEvent(null)
            );

            dispatch(
                selectDate('', null)
            );
        }
    } catch (err) {
        dispatch({
            type: PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
};

export const selectDate = (patientId, date) => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_SET_SELECTED_DATE,
        payload: date
    })

    if (date) {
        dispatch(
            fetchSelectedDateEvents(patientId, date)
        )
    }
};

export const selectDateOnly = (date) => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_SET_SELECTED_DATE,
        payload: date
    })
};

export const selectEvent = (event) => {
    return {
        type: PATIENT_EVENTS_SET_SELECTED_EVENT,
        payload: event
    }
}


export const setManagementMode = (mode) => {
    return {
        type: PATIENT_EVENTS_SET_MANAGEMENT_MODE,
        payload: mode
    }
}

export const setCalendarMode = (mode) => {
    return {
        type: PATIENT_EVENTS_SET_CALENDAR_MODE,
        payload: mode
    }
}

export const setCalendarDate = (date) => {
    return {
        type: PATIENT_EVENTS_SET_CALENDAR_DATE,
        payload: date
    }
}

export const setEventCreateDate = (date) => {
    return {
        type: PATIENT_EVENTS_SET_EVENT_CREATE_DATE,
        payload: date
    }
}

export const addEvent = (values, patientId) => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_ADD_EVENT_REQUEST
    })

    try {
        const response = await PatientService.addEvent(values, patientId);



        dispatch({
            type: PATIENT_EVENTS_ADD_EVENT_SUCCESS,
            payload: response.data
        })

    } catch (err) {

        dispatch({
            type: PATIENT_EVENTS_ADD_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetAddEventRequest = () => {
    return {
        type: PATIENT_EVENTS_ADD_EVENT_RESET
    }
}

export const showEventRemoveModal = () => {
    return {
        type: EVENT_REMOVE_MODAL_SHOW
    }
}

export const hideEventRemoveModal = () => {
    return {
        type: EVENT_REMOVE_MODAL_HIDE
    }
}

export const removeEvent = (eventId) => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_REMOVE_EVENT_REQUEST
    })

    try {
        const response = await PatientService.removeEvent(eventId);

        dispatch({
            type: PATIENT_EVENTS_REMOVE_EVENT_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: PATIENT_EVENTS_REMOVE_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetRemoveEventRequest = () => {
    return {
        type: PATIENT_EVENTS_REMOVE_EVENT_RESET
    }
}


export const updateEvent = (values, eventId) => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_UPDATE_EVENT_REQUEST
    })

    try {

        const response = await PatientService.updateEvent(values, eventId);

        dispatch({
            type: PATIENT_EVENTS_UPDATE_EVENT_SUCCESS,
            payload: response.data
        })
    } catch (err) {

        dispatch({
            type: PATIENT_EVENTS_UPDATE_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetUpdateEventRequest = () => {
    return {
        type: PATIENT_EVENTS_UPDATE_EVENT_RESET
    }
}

export const addEventObservation = (eventId, values, formActions) => async dispatch => {
    dispatch({
        type: PATIENT_EVENTS_ADD_EVENT_OBSERVATION_REQUEST
    })

    try {
        const response = await PatientService.addEventObservation(values, eventId);

        dispatch({
            type: PATIENT_EVENTS_ADD_EVENT_OBSERVATION_SUCCESS,
            payload: response.data
        })

        formActions.resetForm();
    } catch (err) {

        dispatch({
            type: PATIENT_EVENTS_ADD_EVENT_OBSERVATION_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetAddEventObservationRequest = () => {
    return {
        type: PATIENT_EVENTS_ADD_EVENT_OBSERVATION_RESET
    }
}

export const rejectPresenceEvent = (reminderId) => async (dispatch) => {
    dispatch({
        type: PATIENT_EVENTS_REJECT_PRESENCE_EVENT_REQUEST
    })

    try {
        const response = await PatientService.rejectPresenceEvent(reminderId);

        dispatch({
            type: PATIENT_EVENTS_REJECT_PRESENCE_EVENT_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: PATIENT_EVENTS_REJECT_PRESENCE_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetRejectPresenceEventRequest = () => {
    return {
        type: PATIENT_EVENTS_REJECT_PRESENCE_EVENT_RESET
    }
}

export const confirmPresenceEvent = (reminderId) => async (dispatch) => {
    dispatch({
        type: PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_REQUEST
    })

    try {
        const response = await PatientService.confirmPresenceEvent(reminderId);

        dispatch({
            type: PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        dispatch({
            type: PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_FAILURE,
            payload: {
                status: err.response.status,
                data: err.response.data
            }
        })
    }
}

export const resetConfirmPresenceEventRequest = () => {
    return {
        type: PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_RESET
    }
}
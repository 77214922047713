export default {
    sendMessageRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    contacts: [],
    selectedContactId: null,
    fetchContactsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    availableHospitals: [],
    selectedHospitalId: null,
    fetchAvailableHospitalsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    conversation: null,
    conversationData: [],
    fetchConversationRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    fetchConversationNextPageRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    observations: [],
    fetchObservationsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    unreadedMessages: 0,
    fetchUnreadedMessagesRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    fetchUnreadedMessagesOfPatientRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

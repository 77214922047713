import React from 'react';

import TableColumn from '../TableColumn';

export const filterTableColumns = (children) => {
    return React.Children.toArray(children).filter(child => {
        return child.type === TableColumn;
    });
}

export const flattenTree = (nodes, level = 1) => {
    let rows = [];

    nodes.forEach(node => {
        rows.push({
            data: node.data,
            level
        });

        rows = [
            ...rows,
            ...flattenTree(node.children, level + 1)
        ]
    });

    return rows;
};
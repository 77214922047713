export default {
    subchapter: null,
    fetchSubchapterRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    addLessonRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateLessonRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    orderLessonRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeLessonRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    selectedLessonId: null
}
export const EDUCATION_SUBCHAPTER_FETCH_REQUEST = 'EDUCATION_SUBCHAPTER_FETCH_REQUEST';
export const EDUCATION_SUBCHAPTER_FETCH_SUCCESS = 'EDUCATION_SUBCHAPTER_FETCH_SUCCESS';
export const EDUCATION_SUBCHAPTER_FETCH_FAILURE = 'EDUCATION_SUBCHAPTER_FETCH_FAILURE';

export const EDUCATION_SUBCHAPTER_UPDATE_REQUEST = 'EDUCATION_SUBCHAPTER_UPDATE_REQUEST';
export const EDUCATION_SUBCHAPTER_UPDATE_SUCCESS = 'EDUCATION_SUBCHAPTER_UPDATE_SUCCESS';
export const EDUCATION_SUBCHAPTER_UPDATE_FAILURE = 'EDUCATION_SUBCHAPTER_UPDATE_FAILURE';

export const EDUCATION_SUBCHAPTER_SELECT_LESSON = 'EDUCATION_SUBCHAPTER_SELECT_LESSON';

export const EDUCATION_SUBCHAPTER_ADD_LESSON_REQUEST = 'EDUCATION_SUBCHAPTER_ADD_LESSON_REQUEST';
export const EDUCATION_SUBCHAPTER_ADD_LESSON_SUCCESS = 'EDUCATION_SUBCHAPTER_ADD_LESSON_SUCCESS';
export const EDUCATION_SUBCHAPTER_ADD_LESSON_FAILURE = 'EDUCATION_SUBCHAPTER_ADD_LESSON_FAILURE';

export const EDUCATION_SUBCHAPTER_UPDATE_LESSON_REQUEST = 'EDUCATION_SUBCHAPTER_UPDATE_LESSON_REQUEST';
export const EDUCATION_SUBCHAPTER_UPDATE_LESSON_SUCCESS = 'EDUCATION_SUBCHAPTER_UPDATE_LESSON_SUCCESS';
export const EDUCATION_SUBCHAPTER_UPDATE_LESSON_FAILURE = 'EDUCATION_SUBCHAPTER_UPDATE_LESSON_FAILURE';

export const EDUCATION_SUBCHAPTER_ORDER_LESSON_REQUEST = 'EDUCATION_SUBCHAPTER_ORDER_LESSON_REQUEST';
export const EDUCATION_SUBCHAPTER_ORDER_LESSON_SUCCESS = 'EDUCATION_SUBCHAPTER_ORDER_LESSON_SUCCESS';
export const EDUCATION_SUBCHAPTER_ORDER_LESSON_FAILURE = 'EDUCATION_SUBCHAPTER_ORDER_LESSON_FAILURE';
export const EDUCATION_SUBCHAPTER_ORDER_LESSON_RESET = 'EDUCATION_SUBCHAPTER_ORDER_LESSON_RESET';

export const EDUCATION_SUBCHAPTER_REMOVE_LESSON_REQUEST = 'EDUCATION_SUBCHAPTER_REMOVE_LESSON_REQUEST';
export const EDUCATION_SUBCHAPTER_REMOVE_LESSON_SUCCESS = 'EDUCATION_SUBCHAPTER_REMOVE_LESSON_SUCCESS';
export const EDUCATION_SUBCHAPTER_REMOVE_LESSON_FAILURE = 'EDUCATION_SUBCHAPTER_REMOVE_LESSON_FAILURE';
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../common/Modal';
import PatientAddForm from './PatientAddForm';

const PatientAddFormModal = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
        >
            <div className="o-modal c-patient-add-modal">
                <PatientAddForm 
                    onRequestClose={props.onRequestClose}
                />
            </div>
        </Modal>
    );
};

PatientAddFormModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func
}

export default PatientAddFormModal;
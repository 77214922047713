import {
    USER_REMOVE_REQUEST,
    USER_REMOVE_SUCCESS,
    USER_REMOVE_FAILURE,
    USER_REMOVE_RESET
} from "../actions/types/user_list_types";

const initialState = {
    removeRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

const userListReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case USER_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case USER_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case USER_REMOVE_RESET: {
            return {
                ...state,
                removeRequest: initialState.removeRequest
            }
        }
        default:
            return state;
    }
}

export default userListReducer;
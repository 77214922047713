import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PatientAddFormModal from '../components/patient/PatientAddFormModal';
import { hidePatientAddModal } from '../store/actions/patient-actions';

const PatientAddFormModalContainer = () => {
    const patient = useSelector(store => store.patient);
    
    const dispatch = useDispatch();

    return (
        <PatientAddFormModal 
            isOpen={patient.isPatientAddModalOpen}
            onRequestClose={() => { 
                dispatch(hidePatientAddModal())
            }}
        />
    )
};

export default PatientAddFormModalContainer;
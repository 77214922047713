import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PatientEditFormModal from '../components/patient/PatientEditFormModal';
import { hidePatientEditModal } from '../store/actions/patient-actions';

const PatientEditFormModalContainer = (props) => {
    const dispatch = useDispatch();

    const patient = useSelector(store => store.patient);
    const { patientData } = useSelector(store => store.patientCard);

    const patientId = !!patientData ? patientData.id : null;

    return (
        <PatientEditFormModal
            isOpen={patient.isPatientEditModalOpen}
            patientId={patientId}
            onRequestClose={() => {
                dispatch(hidePatientEditModal())
            }}
            onUpdate={props.onUpdate}
        />
    )
};

PatientEditFormModalContainer.defaultProps = {
    onUpdate: () => { }
}

export default PatientEditFormModalContainer;
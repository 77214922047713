import React from 'react';

export default class ControlErrors extends React.Component {
    render() {
        const { errors } = this.props;

        return (
            <React.Fragment>
                {errors && errors.length > 0 &&
                    <ul className="o-errors">
                        {this.props.errors.map((error, i) => (
                            <li className="o-errors__error" key={i}>{error}</li>
                        ))}
                    </ul>
                }
            </React.Fragment>

        )
    }
}
import React from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';

import ProtectedRoute from '../../utils/ProtectedRoute';

const Dashboard = React.lazy(() => import('../../views/dashboard/Dashboard'));
const Patients = React.lazy(() => import('../../views/patients/Patients'));
const Surveys = React.lazy(() => import('../../views/surveys/Surveys'));
const ClosestEvents = React.lazy(() => import('../../views/closest-events/ClosestEvents'));
const Users = React.lazy(() => import('../../views/users/Users'));
const Login = React.lazy(() => import('../../views/login/Login'));
const PasswordReset = React.lazy(() => import('../../views/password-reset/PasswordReset'));
const Hospitals = React.lazy(() => import('../../views/hospitals/Hospitals'));
const HospitalEvents = React.lazy(() => import('../../views/hospital/HospitalEvents'));
const AdminPanel = React.lazy(() => import('../../views/admin-panel/AdminPanel'));
const Patient = React.lazy(() => import('../../views/patient/Patient'));
const PatientEvents = React.lazy(() => import('../../views/patient/PatientEvents'));
const PatientMeasurements = React.lazy(() => import('../../views/patient/PatientMeasurements'));
const PatientRecommendationsAndMedicines = React.lazy(() => import('../../views/patient/PatientRecommendationsAndMedicines'));
const PatientDiseasesAndInterventions = React.lazy(() => import('../../views/patient/PatientDiseasesAndInterventions'));
const ReportStatistics = React.lazy(() => import('../../views/report/ReportStatistics'));
const ReportBonuses = React.lazy(() => import('../../views/report/ReportBonuses'));
const PatientSurveys = React.lazy(() => import('../../views/patient/PatientSurveys'));
const Chat = React.lazy(() => import('../../views/chat/Chat'));
const Education = React.lazy(() => import('../../views/education/Education'));
const EducationMessages = React.lazy(() => import('../../views/education/EducationMessages'));
const EducationSubchapter = React.lazy(() => import('../../views/education/EducationSubchapter'));
const Diets = React.lazy(() => import('../../views/diets/Diets'));
const AppParams = React.lazy(() => import('../../views/app-params/AppParams'));
const Bonuses = React.lazy(() => import('../../views/bonuses/Bonuses'));

const AppRoutes = () => {
    return (
        <Switch>
            <Route path="/login">
                <Login />
            </Route>

            <Route path="/password-reset/:userToken/:resetToken">
                <PasswordReset />
            </Route>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator', 'physiotherapist']}
                path="/patient-list"
            >
                <Patients />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator', 'physiotherapist']}
                path="/patient/:patientId/events"
            >
                <PatientEvents />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator', 'physiotherapist']}
                path="/patient/:patientId/measurements"
            >
                <PatientMeasurements />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator', 'physiotherapist']}
                path="/patient/:patientId/recommendations-and-medicines"
            >
                <PatientRecommendationsAndMedicines />
            </ProtectedRoute>


            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator', 'physiotherapist']}
                path="/patient/:patientId/diseases-and-interventions"
            >
                <PatientDiseasesAndInterventions />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator', 'physiotherapist']}
                path="/patient/:patientId/surveys"
            >
                <PatientSurveys />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator', 'physiotherapist']}
                path="/patient/:patientId"
            >
                <Patient />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'gestor']}
                path="/report/statistics"
            >
                <ReportStatistics />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'gestor']}
                path="/report/bonuses"
            >
                <ReportBonuses />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'doctor', 'coordinator']}
                path="/survey-list"
            >
                <Surveys />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['doctor', 'physiotherapist']}
                path="/closest-event-list"
            >
                <ClosestEvents />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'coordinator']}
                path="/user-list"
            >
                <Users />
            </ProtectedRoute>

            <ProtectedRoute allowedRoles={['admin']} path="/hospital-list">
                <Hospitals />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin']}
                path="/hospital/:hospitalId/events"
            >
                <HospitalEvents />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'dietitian']}
                path="/admin-panel"
            >
                <AdminPanel />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['coordinator', 'doctor', 'gestor', 'physiotherapist']}
                path="/chat/:patientId"
            >
                <Chat />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['coordinator', 'doctor', 'gestor', 'physiotherapist']}
                path="/chat"
            >
                <Chat />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'dietitian']}
                path="/education/messages"
            >
                <EducationMessages />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'dietitian']}
                path="/education/subchapter/:subchapterId"
            >
                <EducationSubchapter />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'dietitian']}
                path="/education"
            >
                <Education />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'dietitian']}
                path="/diet-list"
            >
                <Diets />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin']}
                path="/app-params"
            >
                <AppParams />
            </ProtectedRoute>

            <ProtectedRoute
                allowedRoles={['admin', 'coordinator']}
                path="/bonus-list"
            >
                <Bonuses />
            </ProtectedRoute>


            <ProtectedRoute path="/">
                <Dashboard />
            </ProtectedRoute>
        </Switch >
    )
};

export default AppRoutes;
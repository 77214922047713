import * as types from '../actions/types/education_subjects_types';
import educationSubjectsInitialState from './helpers/education-subjects-initial-state';

const initialState = educationSubjectsInitialState

const educationSubjectsReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.EDUCATION_SUBJECTS_FETCH_SUBJECTS_REQUEST: {
            const {
                fetchSubjectsRequest
            } = state;

            return {
                ...state,
                fetchSubjectsRequest: {
                    ...fetchSubjectsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_FETCH_SUBJECTS_SUCCESS: {
            const {
                fetchSubjectsRequest
            } = state;

            return {
                ...state,
                subjects: action.payload.data,
                fetchSubjectsRequestt: {
                    ...fetchSubjectsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_FETCH_SUBJECTS_FAILURE: {
            const {
                fetchSubjectsRequest
            } = state;

            return {
                ...state,
                subjects: [],
                fetchSubjectsRequest: {
                    ...fetchSubjectsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBJECTS_ADD_REQUEST: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_ADD_SUCCESS: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_ADD_FAILURE: {
            const {
                addRequest
            } = state;

            return {
                ...state,
                addRequest: {
                    ...addRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBJECTS_UPDATE_REQUEST: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_UPDATE_SUCCESS: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_UPDATE_FAILURE: {
            const {
                updateRequest
            } = state;

            return {
                ...state,
                updateRequest: {
                    ...updateRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case types.EDUCATION_SUBJECTS_REMOVE_REQUEST: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_REMOVE_SUCCESS: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case types.EDUCATION_SUBJECTS_REMOVE_FAILURE: {
            const {
                removeRequest
            } = state;

            return {
                ...state,
                removeRequest: {
                    ...removeRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        default: 
            return state;
    }
}

export default educationSubjectsReducer;
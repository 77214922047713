export const CLEAR_HOSPITAL_EVENTS_STATE = 'CLEAR_HOSPITAL_EVENTS_STATE';

export const HOSPITAL_EVENTS_FETCH_EVENTS_REQUEST = 'HOSPITAL_EVENTS_FETCH_EVENTS_REQUEST';
export const HOSPITAL_EVENTS_FETCH_EVENTS_SUCCESS = 'HOSPITAL_EVENTS_FETCH_EVENTS_SUCCESS';
export const HOSPITAL_EVENTS_FETCH_EVENTS_FAILURE = 'HOSPITAL_EVENTS_FETCH_EVENTS_FAILURE';

export const HOSPITAL_EVENTS_SET_CALENDAR_DATE = 'HOSPITAL_EVENTS_SET_CALENDAR_DATE';

export const HOSPITAL_EVENTS_SET_SELECTED_DATE_HOUR = 'HOSPITAL_EVENTS_SET_SELECTED_DATE_HOUR';

export const HOSPITAL_EVENTS_SET_CLOSEST_EVENT = 'HOSPITAL_EVENTS_SET_CLOSEST_EVENT';
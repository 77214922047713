import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer from './reducers/user-reducer';
import patientReducer from './reducers/patient-reducer';
import userListReducer from './reducers/user-list-reducer';
import patientEventsReducer from './reducers/patient-events-reducer';
import patientCardReducer from './reducers/patient-card-reducer';
import patientMeasurementsReducer from './reducers/patient-measurements-reducer';
import medicineTakingReducer from './reducers/medicine-taking-reducer';
import medicineReducer from './reducers/medicine-reducer';
import recommendationReducer from './reducers/recommendation-reducer';
import recommendationsAndMedicinesReducer from './reducers/recommendations-and-medicines-reducer';
import recommendationTemplatesReducer from './reducers/recommendation-template-reducer';
import patientSurveysReducer from './reducers/patient-surveys-reducer';
import chatReducer from './reducers/chat-reducer';
import surveyReducer from './reducers/survey-reducer';
import hospitalReducer from './reducers/hospital-reducer';
import hospitalEventsReducer from './reducers/hospital-events-reducer';
import hospitalCardReducer from './reducers/hospital-card-reducer';
import educationReducer from './reducers/education-reducer';
import educationSubchapterReducer from './reducers/education-subchapter-reducer';
import educationSubjectsReducer from './reducers/education-subjects-reducer';
import educationMessagesReducer from './reducers/education-messages-reducer';
import diseasesReducer from './reducers/diseases-reducer';
import interventionsReducer from './reducers/interventions-reducer';
import dietReducer from './reducers/diet-reducer';
import bonusReducer from './reducers/bonus-reducer';
import appParamsReducer from './reducers/app-params-reducer';

const rootReducer = combineReducers({
    user: userReducer,
    patient: patientReducer,
    user_list: userListReducer,
    patientEvents: patientEventsReducer,
    patientCard: patientCardReducer,
    patientMeasurements: patientMeasurementsReducer,
    medicine: medicineReducer,
    medicineTaking: medicineTakingReducer,
    recommendation: recommendationReducer,
    recommendationTemplate: recommendationTemplatesReducer,
    recommendationsAndMedicines: recommendationsAndMedicinesReducer,
    patientSurveys: patientSurveysReducer,
    chat: chatReducer,
    survey: surveyReducer,
    hospital: hospitalReducer,
    hospitalEvents: hospitalEventsReducer,
    hospitalCard: hospitalCardReducer,
    education: educationReducer,
    educationSubchapter: educationSubchapterReducer,
    educationSubjects: educationSubjectsReducer,
    educationMessages: educationMessagesReducer,
    diseases: diseasesReducer,
    interventions: interventionsReducer,
    diet: dietReducer,
    bonus: bonusReducer,
    appParams: appParamsReducer
});

let store;

if (process.env.NODE_ENV === 'production') {
    // Create store
    store = createStore(rootReducer, applyMiddleware(ReduxThunk))
} else {
    // Create store
    store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)))
}

export default store;
export default {
    events: [],
    eventsSearchQuery: '',
    treatmentProgram: null,
    eventForm: null,
    eventMultipleOf: 10,
    centerTypes: {
        data: [],
        fetchRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
        addRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
        updateRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
    },
    stages: {
        data: [],
        fetchRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
        addRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
        updateRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
    },
    types: {
        data: [],
        fetchRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
        addRequest: {
            isActive: false,
            isSuccess: false,
            isError: false,
            data: null,
            error: null
        },
        updateRequest: {
            isActive: false,
            isSuccess: false,
            isError: false,
            data: null,
            error: null
        },
        removeRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
    },
    interventions: {
        data: [],
        fetchRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
        addRequest: {
            isActive: false,
            isSuccess: false,
            isError: false,
            data: null,
            error: null
        },
        updateRequest: {
            isActive: false,
            isSuccess: false,
            isError: false,
            data: null,
            error: null
        },
        removeRequest: {
            isActive: false,
            isSuccess: false,
            data: null,
            error: null
        },
    },
    fetchAllEventsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    addEventRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
    updateEventRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
    removeEventRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    fetchTreatmentProgramRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateTreatmentProgramRequest: {
        isActive: false,
        isSuccess: false,
        isError: false,
        data: null,
        error: null
    },
}
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../common/Modal';
import PatientEditForm from './PatientEditForm';

const PatientEditFormModal = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
        >
            <div className="o-modal c-patient-add-modal">
                <PatientEditForm
                    patientId={props.patientId}
                    onRequestClose={props.onRequestClose}
                    onUpdate={props.onUpdate}
                />
            </div>
        </Modal>
    );
};

PatientEditFormModal.propTypes = {
    patientId: PropTypes.number,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onUpdate: PropTypes.func,
}

export default PatientEditFormModal;
import moment from 'moment';

export const getDay = (date) => {
    return moment(date).format('DD');
}

export const getMonth = (date) => {
    return moment(date).format('MM');
}

export const getYear = (date) => {
    return moment(date).format('YYYY');
}

export const getDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
}

export const getDateFromPesel = (pesel) => {
    let rok = parseInt(pesel.substring(0, 2), 10);
    let miesiac = parseInt(pesel.substring(2, 4), 10) - 1;
    let dzien = parseInt(pesel.substring(4, 6), 10);

    if (miesiac > 80) {
        rok = rok + 1800;
        miesiac = miesiac - 80;
    }
    else if (miesiac > 60) {
        rok = rok + 2200;
        miesiac = miesiac - 60;
    }
    else if (miesiac > 40) {
        rok = rok + 2100;
        miesiac = miesiac - 40;
    }
    else if (miesiac > 20) {
        rok = rok + 2000;
        miesiac = miesiac - 20;
    }
    else {
        rok += 1900;
    }

    let date = new Date();
    date.setFullYear(rok, miesiac, dzien);

    return date;
}

export const isValidPesel = (pesel) => {
    if (pesel.length !== 11) {
        return false;
    }

    const digits = pesel.split("");

    let checksum = (1 * parseInt(digits[0]) + 3 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 9 * parseInt(digits[3]) + 1 * parseInt(digits[4]) + 3 * parseInt(digits[5]) + 7 * parseInt(digits[6]) + 9 * parseInt(digits[7]) + 1 * parseInt(digits[8]) + 3 * parseInt(digits[9])) % 10;

    if (checksum === 0) checksum = 10;
    checksum = 10 - checksum;

    if (parseInt(digits[10]) !== checksum) {
        return false;
    }

    return true;
}

export const range = (start, end) => {
    if (start > end) {
        return new Array(start - end + 1).fill(0).map((number, index) => end + number + index);
    }

    return new Array(end - start + 1).fill(0).map((number, index) => start + number + index);
};

// Get Week Start And End Date Based On A Specific Date
export const getStartEndOfWeek = (date) => {
    const startWeek = moment(date).startOf('week').toDate();
    const endWeek = moment(date).endOf('week').toDate();

    return { startWeek, endWeek };
}

import {
    CLEAR_PATIENT_EVENTS_STATE,
    PATIENT_EVENTS_FETCH_EVENTS_REQUEST,
    PATIENT_EVENTS_FETCH_EVENTS_SUCCESS,
    PATIENT_EVENTS_FETCH_EVENTS_FAILURE,
    PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_FAILURE,
    PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_REQUEST,
    PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_SUCCESS,
    PATIENT_EVENTS_SET_SELECTED_DATE,
    PATIENT_EVENTS_SET_SELECTED_EVENT,
    PATIENT_EVENTS_SET_MANAGEMENT_MODE,
    PATIENT_EVENTS_ADD_EVENT_REQUEST,
    PATIENT_EVENTS_ADD_EVENT_SUCCESS,
    PATIENT_EVENTS_ADD_EVENT_FAILURE,
    PATIENT_EVENTS_ADD_EVENT_RESET,
    PATIENT_EVENTS_REMOVE_EVENT_REQUEST,
    PATIENT_EVENTS_REMOVE_EVENT_SUCCESS,
    PATIENT_EVENTS_REMOVE_EVENT_FAILURE,
    PATIENT_EVENTS_REMOVE_EVENT_RESET,
    PATIENT_EVENTS_UPDATE_EVENT_REQUEST,
    PATIENT_EVENTS_UPDATE_EVENT_SUCCESS,
    PATIENT_EVENTS_UPDATE_EVENT_FAILURE,
    PATIENT_EVENTS_UPDATE_EVENT_RESET,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_REQUEST,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_SUCCESS,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_FAILURE,
    PATIENT_EVENTS_ADD_EVENT_OBSERVATION_RESET,
    EVENT_REMOVE_MODAL_SHOW,
    EVENT_REMOVE_MODAL_HIDE,
    PATIENT_EVENTS_SET_CALENDAR_DATE,
    PATIENT_EVENTS_SET_EVENT_CREATE_DATE,
    PATIENT_EVENTS_SET_CALENDAR_MODE,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_REQUEST,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_SUCCESS,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_FAILURE,
    PATIENT_EVENTS_REJECT_PRESENCE_EVENT_RESET,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_REQUEST,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_SUCCESS,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_FAILURE,
    PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_RESET
} from "../actions/types/patient_events_types";

const initialState = {
    events: [],
    calendarDate: new Date(),
    selectedDate: new Date(),
    eventCreateDate: null,
    selectedDateEvents: [],
    selectedEventId: null,
    selectedEvent: null,
    isEventRemoveModalOpen: false,
    managementMode: 'selected_date_events_preview',
    calendarMode: 'month',
    fetchEventsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    fetchSelectedDateEventsRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    addEventRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    removeEventRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    updateEventRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    addEventObservationRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    rejectPresenceEventRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    },
    confirmPresenceEventRequest: {
        isActive: false,
        isSuccess: false,
        data: null,
        error: null
    }
};

const patientEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_PATIENT_EVENTS_STATE: {
            return { ...initialState }
        }
        case PATIENT_EVENTS_FETCH_EVENTS_REQUEST: {
            const {
                fetchEventsRequest
            } = state;

            return {
                ...state,
                fetchEventsRequest: {
                    ...fetchEventsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_FETCH_EVENTS_SUCCESS: {
            const {
                fetchEventsRequest
            } = state;

            return {
                ...state,
                events: action.payload.data.map(event => ({
                    start: event.start_date,
                    data: event
                })),
                fetchEventsRequest: {
                    ...fetchEventsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_FETCH_EVENTS_FAILURE: {
            const {
                fetchEventsRequest
            } = state;

            return {
                ...state,
                events: [],
                fetchEventsRequest: {
                    ...fetchEventsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_SET_SELECTED_DATE: {
            return {
                ...state,
                selectedDate: action.payload
            }
        }
        case PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_REQUEST: {
            const {
                fetchSelectedDateEventsRequest
            } = state;

            return {
                ...state,
                fetchSelectedDateEventsRequest: {
                    ...fetchSelectedDateEventsRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_SUCCESS: {
            const {
                fetchSelectedDateEventsRequest
            } = state;

            return {
                ...state,
                selectedDateEvents: action.payload.data.map(event => ({
                    start: event.start_date,
                    data: event
                })),
                fetchSelectedDateEventsRequest: {
                    ...fetchSelectedDateEventsRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_FETCH_SELECTED_DATE_EVENTS_FAILURE: {
            const {
                fetchSelectedDateEventsRequest
            } = state;

            return {
                ...state,
                selectedDateEvents: [],
                fetchSelectedDateEventsRequest: {
                    ...fetchSelectedDateEventsRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_SET_SELECTED_EVENT: {
            return {
                ...state,
                selectedEventId: action.payload ? action.payload.data.event_id : null,
                selectedEvent: action.payload
            }
        }
        case PATIENT_EVENTS_SET_MANAGEMENT_MODE: {
            return {
                ...state,
                managementMode: action.payload
            }
        }
        case PATIENT_EVENTS_SET_CALENDAR_MODE: {
            return {
                ...state,
                calendarMode: action.payload
            }
        }
        case PATIENT_EVENTS_SET_CALENDAR_DATE: {
            return {
                ...state,
                calendarDate: action.payload
            }
        }
        case PATIENT_EVENTS_SET_EVENT_CREATE_DATE: {
            return {
                ...state,
                eventCreateDate: action.payload
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_REQUEST: {
            const {
                addEventRequest
            } = state;

            return {
                ...state,
                addEventRequest: {
                    ...addEventRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_SUCCESS: {
            const {
                addEventRequest
            } = state;

            return {
                ...state,
                addEventRequest: {
                    ...addEventRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_FAILURE: {
            const {
                addEventRequest
            } = state;

            return {
                ...state,
                addEventRequest: {
                    ...addEventRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_RESET: {
            return {
                ...state,
                addEventRequest: initialState.addEventRequest
            }
        }
        case EVENT_REMOVE_MODAL_SHOW: {
            return {
                ...state,
                isEventRemoveModalOpen: true
            }
        }
        case EVENT_REMOVE_MODAL_HIDE: {
            return {
                ...state,
                isEventRemoveModalOpen: false
            }
        }
        case PATIENT_EVENTS_REMOVE_EVENT_REQUEST: {
            const {
                removeEventRequest
            } = state;

            return {
                ...state,
                removeEventRequest: {
                    ...removeEventRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_REMOVE_EVENT_SUCCESS: {
            const {
                removeEventRequest
            } = state;

            return {
                ...state,
                removeEventRequest: {
                    ...removeEventRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_REMOVE_EVENT_FAILURE: {
            const {
                removeEventRequest
            } = state;

            return {
                ...state,
                removeEventRequest: {
                    ...removeEventRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_REMOVE_EVENT_RESET: {
            return {
                ...state,
                removeEventRequest: initialState.removeEventRequest
            }
        }
        case PATIENT_EVENTS_UPDATE_EVENT_REQUEST: {
            const {
                updateEventRequest
            } = state;

            return {
                ...state,
                updateEventRequest: {
                    ...updateEventRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_UPDATE_EVENT_SUCCESS: {
            const {
                updateEventRequest
            } = state;

            return {
                ...state,
                updateEventRequest: {
                    ...updateEventRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_UPDATE_EVENT_FAILURE: {
            const {
                updateEventRequest
            } = state;

            return {
                ...state,
                updateEventRequest: {
                    ...updateEventRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_UPDATE_EVENT_RESET: {
            return {
                ...state,
                updateEventRequest: initialState.updateEventRequest
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_OBSERVATION_REQUEST: {
            const {
                addEventObservationRequest
            } = state;

            return {
                ...state,
                addEventObservationRequest: {
                    ...addEventObservationRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_OBSERVATION_SUCCESS: {
            const {
                addEventObservationRequest
            } = state;

            return {
                ...state,
                addEventObservationRequest: {
                    ...addEventObservationRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_OBSERVATION_FAILURE: {
            const {
                addEventObservationRequest
            } = state;

            return {
                ...state,
                addEventObservationRequest: {
                    ...addEventObservationRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_ADD_EVENT_OBSERVATION_RESET: {
            return {
                ...state,
                addEventObservationRequest: initialState.addEventObservationRequest
            }
        }
        case PATIENT_EVENTS_REJECT_PRESENCE_EVENT_REQUEST: {
            const {
                rejectPresenceEventRequest
            } = state;

            return {
                ...state,
                rejectPresenceEventRequest: {
                    ...rejectPresenceEventRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_REJECT_PRESENCE_EVENT_SUCCESS: {
            const {
                rejectPresenceEventRequest
            } = state;

            return {
                ...state,
                rejectPresenceEventRequest: {
                    ...rejectPresenceEventRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_REJECT_PRESENCE_EVENT_FAILURE: {
            const {
                rejectPresenceEventRequest
            } = state;

            return {
                ...state,
                rejectPresenceEventRequest: {
                    ...rejectPresenceEventRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_REJECT_PRESENCE_EVENT_RESET: {
            return {
                ...state,
                rejectPresenceEventRequest: initialState.rejectPresenceEventRequest
            }
        }
        case PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_REQUEST: {
            const {
                confirmPresenceEventRequest
            } = state;

            return {
                ...state,
                confirmPresenceEventRequest: {
                    ...confirmPresenceEventRequest,
                    isActive: true,
                    isSuccess: false,
                    data: null,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_SUCCESS: {
            const {
                confirmPresenceEventRequest
            } = state;

            return {
                ...state,
                confirmPresenceEventRequest: {
                    ...confirmPresenceEventRequest,
                    isActive: false,
                    isSuccess: true,
                    data: action.payload,
                    error: null
                }
            }
        }
        case PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_FAILURE: {
            const {
                confirmPresenceEventRequest
            } = state;

            return {
                ...state,
                confirmPresenceEventRequest: {
                    ...confirmPresenceEventRequest,
                    isActive: false,
                    isSuccess: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case PATIENT_EVENTS_CONFIRM_PRESENCE_EVENT_RESET: {
            return {
                ...state,
                confirmPresenceEventRequest: initialState.confirmPresenceEventRequest
            }
        }
        default:
            return state;
    }
}

export default patientEventsReducer;